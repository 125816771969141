var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "chat-ps" },
        [
          _c(
            "div",
            {
              ref: "serviceChat",
              staticClass: "flex chat-children-ps",
              style: _vm.styleResize1,
              attrs: { id: "service-chat" }
            },
            [
              _c(
                "div",
                {
                  ref: "chatServiceBox",
                  staticClass: "chat-service-box",
                  style: {
                    width: _vm.openBroadside ? "76%" : "100%",
                    maxWidth: _vm.openBroadside ? "1162px" : "1450px"
                  }
                },
                [
                  _c("div", { staticClass: "chat-top" }, [
                    _c(
                      "div",
                      { staticClass: "chat-top-search" },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 16 } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入内容",
                                    "prefix-icon": "el-icon-search",
                                    clearable: ""
                                  },
                                  model: {
                                    value: _vm.search,
                                    callback: function($$v) {
                                      _vm.search = $$v
                                    },
                                    expression: "search"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _vm.onlineState == 3
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "#f00",
                                          "font-size": "13px",
                                          cursor: "pointer"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.$message.error(
                                              "忙碌时不能修改状态"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("忙碌")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.onlineState == 1 || _vm.onlineState == 2
                                  ? _c(
                                      "el-select",
                                      {
                                        staticClass: "sel",
                                        attrs: { placeholder: "请选择" },
                                        on: { change: _vm.onlineStateChange },
                                        model: {
                                          value: _vm.onlineState,
                                          callback: function($$v) {
                                            _vm.onlineState = $$v
                                          },
                                          expression: "onlineState"
                                        }
                                      },
                                      _vm._l(_vm.onlineStateOptions, function(
                                        item
                                      ) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "chat-top-tool" }, [
                      _vm.filterConversationList.length == 0
                        ? _c("div", {
                            staticClass: "chat-head-img-box flex",
                            staticStyle: { width: "312%" }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.filterConversationList.length > 0
                        ? _c(
                            "div",
                            {
                              staticClass: "chat-head-img-box flex",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.linkVipDetial(
                                    _vm.targetUserInfo.user.userId
                                  )
                                }
                              }
                            },
                            [
                              _c("el-avatar", {
                                staticStyle: { width: "35px" },
                                attrs: {
                                  size: 30,
                                  src:
                                    (_vm.targetUserInfo.user &&
                                      _vm.targetUserInfo.user.img &&
                                      _vm.targetUserInfo.user.img +
                                        "?x-oss-process=image/resize,m_fixed,h_50,w_50") ||
                                    _vm.circleUrl
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "chat-head-username one-row-hide"
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        (_vm.targetUserInfo.user &&
                                          _vm.targetUserInfo.user.nickName) ||
                                          _vm.targetId
                                      ) +
                                      "\n                  "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "flex top-tool-btns",
                          class: {
                            topToolBtnsOne:
                              _vm.filterConversationList.length == 0 ||
                              !_vm.currentSessionStatus
                          }
                        },
                        [
                          _vm.isCurrentSessionChat
                            ? _c(
                                "div",
                                {
                                  staticClass: "end flex",
                                  staticStyle: { "margin-right": "0" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.changSession($event)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    staticStyle: { width: "13px" },
                                    attrs: {
                                      alt: "",
                                      src:
                                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAIAAAACtmMCAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB6ElEQVRIx62Vv8uqUBzGv3ojChSUQNAp4WRDm3PSJDa06O6/IE7N/QnRLg3utZ5oanSpiCAIhxxyy6UmCXsH743qFe97rz6bHPmc74+H58DjRRhj27Yvl8ujgEj4o8VigTGmaZqiKCgg8gM3HA6r1WpR4iuuYIEAQMzn89VqVa/XB4NBrVbL/5vn+Uajkd8EYdv2v1ZB03Sr1ep2u6IoZhAxxhhjANA0jeO4fFYQBL7vh2GYfsqyrOv6x6CIx+ORzhEALMvKvPZDSZIEQTCdTq/XKwCYpinL8vP012g0QggRBOH7vud57XabZdm/zJ4gWJbt9XoURR0Oh91ux3Ecz/Nv7tE0rd/vA8BkMrndbj9yCUkqimJZFgC4rhtF0RvxFRrH8c+3JIqiYRgAMB6PkyT5PUcoLNd11+u1YRiKopBQhnRdB4D9fv/WdRGlBjoej6URU28CQBRFpRE7nQ4AnE6n0ohBEABAs9ksjej7PgAwDFMOMY7jMAwFQSBJshyi53kAgBAqZ9dRFM1mMwBQVbUEYpIkjuOkCZS6slKwOsdxwjCUJOkZaJX/XoXneWmzkiSZpvk8qmQ2st1uM0H3+/18Pm82mzRrv8dtNnG5XKaRniNBEBBCqqp+fzsz0iy/RoQQwzA5tvsClhAWhHK1bR8AAAAASUVORK5CYII="
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "end-text",
                                      staticStyle: { width: "35px" }
                                    },
                                    [_vm._v("转接")]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isCurrentSessionChat
                            ? _c(
                                "div",
                                {
                                  staticClass: "end flex",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.endSession($event)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-switch-button"
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "end-text" }, [
                                    _vm._v("结束对话")
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "set-show-chatbox",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.closeChatBox($event)
                                }
                              }
                            },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "隐藏对话窗口",
                                    placement: "top-start"
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../assets/service/hide_service_btn.png"),
                                      alt: "",
                                      width: "20",
                                      height: "20"
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.filterConversationList.length > 0
                            ? _c("div", { staticClass: "open" }, [
                                _c("i", {
                                  staticClass: "el-icon-d-arrow-right",
                                  staticStyle: { "font-size": "25px" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.openBroadsideBox()
                                    }
                                  }
                                })
                              ])
                            : _vm._e()
                        ]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "chatChildrenBox",
                      staticClass: "flex chat-children-box"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "chat-left", style: _vm.styleResize4 },
                        [
                          _c(
                            "el-collapse",
                            {
                              model: {
                                value: _vm.activeNames,
                                callback: function($$v) {
                                  _vm.activeNames = $$v
                                },
                                expression: "activeNames"
                              }
                            },
                            [
                              _c(
                                "el-collapse-item",
                                { attrs: { name: "2" } },
                                [
                                  _c("template", { slot: "title" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "el-collapse-chat-item",
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.$refs.queueListPad.open()
                                          }
                                        }
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: {
                                            position: "relative",
                                            top: "2px"
                                          },
                                          attrs: {
                                            width: "14",
                                            src: require("./queueUp.png")
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            "排队列表 ▪\n                          " +
                                              _vm._s(_vm.queueCount)
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("div", {
                                          staticClass:
                                            "el-collapse-chat-item-mask"
                                        })
                                      ]
                                    )
                                  ])
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "el-collapse-item",
                                { attrs: { name: "1" } },
                                [
                                  _c("template", { slot: "title" }, [
                                    _c(
                                      "div",
                                      { staticClass: "el-collapse-chat-item" },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-chat-dot-round"
                                        }),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            "当前会话 ▪\n                          " +
                                              _vm._s(
                                                _vm.filterConversationList
                                                  .length
                                              )
                                          )
                                        ])
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  !_vm.isSearch
                                    ? [
                                        _c(
                                          "div",
                                          { staticClass: "group-container" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "group-user",
                                                style: _vm.styleResize3
                                              },
                                              _vm._l(
                                                _vm.filterConversationList,
                                                function(item, index) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: index,
                                                      staticClass:
                                                        "user-container",
                                                      class: {
                                                        on:
                                                          item.targetId ==
                                                            _vm.targetId &&
                                                          _vm.clickIndex ==
                                                            index
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.selectCurrentSession(
                                                            item,
                                                            index
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("el-avatar", {
                                                        attrs: {
                                                          size: 35,
                                                          src:
                                                            (item.user &&
                                                              item.user.img &&
                                                              item.user.img +
                                                                "?x-oss-process=image/resize,m_fixed,h_50,w_50") ||
                                                            _vm.circleUrl
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "new-reply-box"
                                                        },
                                                        [
                                                          _c("el-badge", {
                                                            staticClass: "item",
                                                            attrs: {
                                                              value:
                                                                item.unreadMessageCount,
                                                              max: 99,
                                                              hidden:
                                                                item.unreadMessageCount ==
                                                                0
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "name"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                " +
                                                                  _vm._s(
                                                                    (item.user &&
                                                                      item.user
                                                                        .nickName) ||
                                                                      item.targetId
                                                                  ) +
                                                                  "\n                              "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          item.latestMessage
                                                            .content
                                                            ? [
                                                                _c("div", {
                                                                  staticClass:
                                                                    "new-reply",
                                                                  domProps: {
                                                                    innerHTML: _vm._s(
                                                                      _vm.TranslationMsgContentLatestMessage(
                                                                        item
                                                                      )
                                                                    )
                                                                  }
                                                                })
                                                              ]
                                                            : _vm._e()
                                                        ],
                                                        2
                                                      )
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          ]
                                        )
                                      ]
                                    : void 0
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "chat-right", style: _vm.styleResize4 },
                        [
                          _vm.filterConversationList.length > 0
                            ? _c(
                                "div",
                                {
                                  ref: "groupContainer",
                                  staticClass: "chat-msg-box",
                                  style: _vm.styleResize2
                                },
                                [
                                  _vm._l(_vm.messageByTargetList, function(
                                    item,
                                    idx
                                  ) {
                                    return [
                                      _c("div", { key: idx }, [
                                        idx == 0 &&
                                        _vm.messageByTargetList_hasMore
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "message tag4 systemmsg",
                                                staticStyle: {
                                                  "margin-top": "10px"
                                                }
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: !_vm.messageByTargetList_loading,
                                                        expression:
                                                          "!messageByTargetList_loading"
                                                      }
                                                    ],
                                                    staticStyle: {
                                                      color: "#00f",
                                                      cursor: "pointer",
                                                      "font-size": "12px"
                                                    },
                                                    attrs: { href: "#" },
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        return _vm.showmoreRecord(
                                                          $event
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("更多聊天记录")]
                                                ),
                                                _vm._v(" "),
                                                _c("i", {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.messageByTargetList_loading,
                                                      expression:
                                                        "messageByTargetList_loading"
                                                    }
                                                  ],
                                                  staticClass: "el-icon-loading"
                                                })
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item.messageDirection == 1
                                          ? _c(
                                              "div",
                                              { staticClass: "my-messages" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "my-flex",
                                                    staticStyle: { flex: "1" }
                                                  },
                                                  [
                                                    item.messageType !=
                                                    "RC:RcCmd"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass: "time"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                            " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "timeTranHM"
                                                                  )(
                                                                    item.sentTime
                                                                  )
                                                                ) +
                                                                "\n                          "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _vm.myUserInfo.nickName ==
                                                      null &&
                                                    item.messageType !=
                                                      "RC:RcCmd"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "me-name"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                            " +
                                                                _vm._s(
                                                                  _vm.myUserInfo
                                                                    .serviceName !=
                                                                    null
                                                                    ? _vm
                                                                        .myUserInfo
                                                                        .serviceName
                                                                    : item.senderUserId
                                                                ) +
                                                                "\n                          "
                                                            )
                                                          ]
                                                        )
                                                      : _vm.myUserInfo
                                                          .nickName != null &&
                                                        item.messageType !=
                                                          "RC:RcCmd"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "me-name"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                            " +
                                                                _vm._s(
                                                                  _vm.myUserInfo
                                                                    .serviceName !=
                                                                    null
                                                                    ? _vm
                                                                        .myUserInfo
                                                                        .serviceName
                                                                    : _vm
                                                                        .myUserInfo
                                                                        .nickName
                                                                ) +
                                                                "\n                          "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    item.messageType ==
                                                    "RC:RcCmd"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "message tag4 systemmsg",
                                                            staticStyle: {
                                                              margin: "0 auto",
                                                              "padding-right":
                                                                "0",
                                                              left:
                                                                "0 !important",
                                                              background: "#fff"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.timeTranHM(
                                                                  item.sentTime
                                                                )
                                                              ) +
                                                                "\n                            "
                                                            ),
                                                            _c("br"),
                                                            _vm._v(
                                                              "\n\n                            你撤回了一条消息\n\n                          "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    item.messageType ==
                                                    "RC:ImgMsg"
                                                      ? _c("el-image", {
                                                          ref: "MyPreview",
                                                          refInFor: true,
                                                          staticClass:
                                                            "chat-img",
                                                          attrs: {
                                                            src:
                                                              item.content
                                                                .imageUri,
                                                            "preview-src-list": [
                                                              item.content
                                                                .imageUri
                                                            ]
                                                          },
                                                          on: {
                                                            contextmenu: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.openMenu(
                                                                item,
                                                                idx
                                                              )
                                                            }
                                                          }
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    item.messageType ==
                                                    "RC:GIFMsg"
                                                      ? _c("el-image", {
                                                          ref: "MyPreview",
                                                          refInFor: true,
                                                          staticClass:
                                                            "chat-img",
                                                          attrs: {
                                                            src:
                                                              item.content
                                                                .remoteUrl,
                                                            "preview-src-list": [
                                                              item.content
                                                                .remoteUrl
                                                            ]
                                                          },
                                                          on: {
                                                            contextmenu: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.openMenu(
                                                                item,
                                                                idx
                                                              )
                                                            }
                                                          }
                                                        })
                                                      : item.messageType ==
                                                        "RC:SightMsg"
                                                      ? _c("videoitem", {
                                                          attrs: { item: item },
                                                          on: {
                                                            clickme: function(
                                                              $event
                                                            ) {
                                                              return _vm.playVideo(
                                                                item.content
                                                                  .sightUrl
                                                              )
                                                            },
                                                            contextmenu: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.openMenu(
                                                                item,
                                                                idx
                                                              )
                                                            }
                                                          }
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    item.content &&
                                                    !item.content.imageUri &&
                                                    !item.content.remoteUrl &&
                                                    item.messageType !=
                                                      "RC:SightMsg" &&
                                                    item.messageType !=
                                                      "RC:RcCmd"
                                                      ? _c("div", {
                                                          staticClass:
                                                            "message",
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              _vm.TranslationMsgContent(
                                                                item
                                                              )
                                                            )
                                                          },
                                                          on: {
                                                            contextmenu: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.openMenu(
                                                                item,
                                                                idx
                                                              )
                                                            }
                                                          }
                                                        })
                                                      : _vm._e()
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                item.messageType != "RC:RcCmd"
                                                  ? _c("el-avatar", {
                                                      staticStyle: {
                                                        margin: "0 10px",
                                                        position: "relative",
                                                        bottom: "13px"
                                                      },
                                                      attrs: {
                                                        size: 30,
                                                        src:
                                                          (_vm.myUserInfo &&
                                                            _vm.myUserInfo
                                                              .img &&
                                                            _vm.myUserInfo.img +
                                                              "?x-oss-process=image/resize,m_fixed,h_50,w_50") ||
                                                          _vm.circleUrl
                                                      }
                                                    })
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item.messageDirection == 2 &&
                                        item.messageType != "RC:ReadNtf" &&
                                        item.messageType != "RC:TypSts"
                                          ? _c(
                                              "div",
                                              { staticClass: "you-messages" },
                                              [
                                                _vm.isSystem(item) &&
                                                item.messageType != "RC:RcCmd"
                                                  ? _c(
                                                      "div",
                                                      { staticClass: "time" },
                                                      [
                                                        _vm._v(
                                                          "\n                          " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "timeTranHM"
                                                              )(item.sentTime)
                                                            ) +
                                                            "\n                        "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.isSystem(item) &&
                                                item.messageType != "RC:RcCmd"
                                                  ? _c("el-avatar", {
                                                      staticClass:
                                                        "you-messages-usr-icon",
                                                      staticStyle: {
                                                        margin: "0 10px",
                                                        position: "relative",
                                                        bottom: "13px"
                                                      },
                                                      attrs: {
                                                        size: 30,
                                                        src:
                                                          (_vm.targetUserInfo
                                                            .user &&
                                                            _vm.targetUserInfo
                                                              .user.img &&
                                                            _vm.targetUserInfo
                                                              .user.img +
                                                              "?x-oss-process=image/resize,m_fixed,h_50,w_50") ||
                                                          _vm.circleUrl
                                                      }
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: { flex: "1" }
                                                  },
                                                  [
                                                    _vm.isSystem(item) &&
                                                    item.messageType !=
                                                      "RC:RcCmd"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "you-name"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\n                            " +
                                                                _vm._s(
                                                                  (_vm
                                                                    .targetUserInfo
                                                                    .user &&
                                                                    _vm
                                                                      .targetUserInfo
                                                                      .user
                                                                      .nickName) ||
                                                                    item.senderUserId
                                                                ) +
                                                                "\n                          "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    item.content &&
                                                    item.content.content &&
                                                    item.messageType ==
                                                      "App:SimpleMsg"
                                                      ? _c("div", {
                                                          staticClass:
                                                            "message tag1",
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              item.content
                                                                .content
                                                                .content ||
                                                                item.content
                                                                  .content ||
                                                                "获取失败"
                                                            )
                                                          }
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    item.messageType ==
                                                    "RC:RcCmd"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "message tag4 systemmsg",
                                                            staticStyle: {
                                                              background: "#fff"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.timeTranHM(
                                                                  item.sentTime
                                                                )
                                                              ) +
                                                                "\n                            "
                                                            ),
                                                            _c("br"),
                                                            _vm._v(
                                                              "\n                            " +
                                                                _vm._s(
                                                                  (_vm
                                                                    .targetUserInfo
                                                                    .user &&
                                                                    _vm
                                                                      .targetUserInfo
                                                                      .user
                                                                      .nickName) ||
                                                                    item.senderUserId
                                                                ) +
                                                                "\n                            撤回了一条消息\n\n                          "
                                                            )
                                                          ]
                                                        )
                                                      : item.messageType ==
                                                          "RC:TxtMsg" ||
                                                        item.messageType ==
                                                          "Web:EndMsg"
                                                      ? _c("div", {
                                                          staticClass:
                                                            "message tag2",
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              _vm.TranslationMsgContent(
                                                                item
                                                              )
                                                            )
                                                          }
                                                        })
                                                      : item.content &&
                                                        item.content.message &&
                                                        (item.messageType ==
                                                          "RC:InfoNtf" ||
                                                          item.messageType ==
                                                            "RC:ReadNtf")
                                                      ? _c("div", {
                                                          staticClass:
                                                            "message tag2 systemmsg",
                                                          staticStyle: {
                                                            background: "#fff"
                                                          },
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              _vm.timeTranHM(
                                                                item.sentTime
                                                              ) +
                                                                "<br/>" +
                                                                (item.content
                                                                  .message
                                                                  ? item.content
                                                                      .message
                                                                  : "")
                                                            )
                                                          }
                                                        })
                                                      : item.content &&
                                                        item.messageType ==
                                                          "WEB:TRANSFER"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "message tag4 systemmsg",
                                                            staticStyle: {
                                                              background: "#fff"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                            " +
                                                                _vm._s(
                                                                  _vm.timeTranHM(
                                                                    item.sentTime
                                                                  )
                                                                )
                                                            ),
                                                            _c("br"),
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.TranslationMsgContent_TRANSFER(
                                                                  item
                                                                )[0]
                                                              )
                                                            ),
                                                            _vm.TranslationMsgContent_TRANSFER(
                                                              item
                                                            )[1]
                                                              ? _c(
                                                                  "a",
                                                                  {
                                                                    staticStyle: {
                                                                      color:
                                                                        "#00f",
                                                                      cursor:
                                                                        "pointer"
                                                                    },
                                                                    attrs: {
                                                                      href: "#"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        $event.preventDefault()
                                                                        _vm.chatRecordPadOpen(
                                                                          _vm.TranslationMsgContent_TRANSFER(
                                                                            item
                                                                          )[1]
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "聊天记录"
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c("span", {
                                                              domProps: {
                                                                innerHTML: _vm._s(
                                                                  _vm.TranslationMsgContent_TRANSFER(
                                                                    item
                                                                  )[2]
                                                                )
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      : item.content &&
                                                        item.messageType ==
                                                          "WEB:LineOrOutLine" &&
                                                        item.content.extra
                                                          .type == 2
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "message tag4 systemmsg",
                                                            staticStyle: {
                                                              background: "#fff"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.timeTranHM(
                                                                  item.sentTime
                                                                )
                                                              )
                                                            ),
                                                            _c("br"),
                                                            _vm._v(
                                                              "用户排队中发的消息 "
                                                            ),
                                                            _c(
                                                              "a",
                                                              {
                                                                staticStyle: {
                                                                  color: "#00f",
                                                                  cursor:
                                                                    "pointer"
                                                                },
                                                                attrs: {
                                                                  href: "#"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    $event.preventDefault()
                                                                    _vm.chatRecordPadOpenLineOrOutLine(
                                                                      _vm.TranslationMsgContent_LineOrOutLine(
                                                                        item
                                                                      )
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "聊天记录"
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      : item.content &&
                                                        item.messageType ==
                                                          "WEB:LineOrOutLine" &&
                                                        item.content.extra
                                                          .type == 1
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "message tag4 systemmsg",
                                                            staticStyle: {
                                                              background: "#fff"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.timeTranHM(
                                                                  item.sentTime
                                                                )
                                                              )
                                                            ),
                                                            _c("br"),
                                                            _vm._v(
                                                              "客服离线时,用户发的消息 "
                                                            ),
                                                            _c(
                                                              "a",
                                                              {
                                                                staticStyle: {
                                                                  color: "#00f",
                                                                  cursor:
                                                                    "pointer"
                                                                },
                                                                attrs: {
                                                                  href: "#"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    $event.preventDefault()
                                                                    _vm.chatRecordPadOpenLineOrOutLine(
                                                                      _vm.TranslationMsgContent_LineOrOutLine(
                                                                        item
                                                                      )
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "聊天记录"
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      : item.content &&
                                                        item.content
                                                          .remoteUrl &&
                                                        item.messageType ==
                                                          "RC:HQVCMsg"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "message tag5",
                                                            attrs: {
                                                              controls: ""
                                                            }
                                                          },
                                                          [
                                                            _c("m-audio", {
                                                              attrs: {
                                                                src:
                                                                  item.content
                                                                    .remoteUrl
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      : item.content &&
                                                        item.messageType ==
                                                          "RC:VcMsg"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "message tag5",
                                                            attrs: {
                                                              controls: ""
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                            [不支持的语信息类型(RC:VcMsg)，请升级APP版本]\n                          "
                                                            )
                                                          ]
                                                        )
                                                      : item.content &&
                                                        (item.messageType ==
                                                          "App:YXGoodsMsg" ||
                                                          item.messageType ==
                                                            "App:NearbyGoodsMsg")
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "message goods-msg-box tag6",
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.goPage(
                                                                  item.messageType,
                                                                  item.content,
                                                                  3,
                                                                  "goodsMsgDetail"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "el-image",
                                                              {
                                                                staticClass:
                                                                  "goods-img",
                                                                attrs: {
                                                                  src:
                                                                    item.content
                                                                      .goodsCoverUrl ||
                                                                    item.content
                                                                      .img
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "image-slot",
                                                                    attrs: {
                                                                      slot:
                                                                        "placeholder"
                                                                    },
                                                                    slot:
                                                                      "placeholder"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                加载中"
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "dot"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "..."
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "goods-text-box"
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "goods-price-min"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                ¥"
                                                                    ),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item
                                                                            .content
                                                                            .goodsPriceMin ||
                                                                            item
                                                                              .content
                                                                              .price
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "goods-name two-row-hide"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                " +
                                                                        _vm._s(
                                                                          item
                                                                            .content
                                                                            .goodsName ||
                                                                            item
                                                                              .content
                                                                              .title
                                                                        ) +
                                                                        "\n                              "
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "type-tag-box flex"
                                                                  },
                                                                  _vm._l(
                                                                    item.content
                                                                      .goodsTag,
                                                                    function(
                                                                      it,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "span",
                                                                        {
                                                                          key: index
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              it
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                                _vm._v(" "),
                                                                item.messageType ==
                                                                "App:YXGoodsMsg"
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "flex shop-logo-box"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "img",
                                                                          {
                                                                            staticClass:
                                                                              "shop-logo",
                                                                            attrs: {
                                                                              src:
                                                                                item
                                                                                  .content
                                                                                  .shopLogo,
                                                                              alt:
                                                                                ""
                                                                            }
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "shop-name"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                  " +
                                                                                _vm._s(
                                                                                  item
                                                                                    .content
                                                                                    .shopName
                                                                                ) +
                                                                                "\n                                "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e()
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : item.content &&
                                                        item.content.content &&
                                                        item.messageType ==
                                                          "App:GoodsOrderMsg"
                                                      ? _c("div", {
                                                          staticClass:
                                                            "message tag7",
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              item.content
                                                                .content ||
                                                                "获取失败"
                                                            )
                                                          }
                                                        })
                                                      : (item.content &&
                                                          item.messageType ==
                                                            "RC:ImgMsg") ||
                                                        item.messageType ==
                                                          "RC:GIFMsg"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "message tag8"
                                                          },
                                                          [
                                                            _c(
                                                              "el-image",
                                                              {
                                                                ref:
                                                                  "YourPreview",
                                                                refInFor: true,
                                                                staticClass:
                                                                  "chat-img",
                                                                attrs: {
                                                                  src:
                                                                    item.content
                                                                      .remoteUrl ||
                                                                    item.content
                                                                      .imageUri,
                                                                  "preview-src-list": [
                                                                    item.content
                                                                      .remoteUrl
                                                                      ? item
                                                                          .content
                                                                          .remoteUrl
                                                                      : item
                                                                          .content
                                                                          .imageUri
                                                                  ]
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "image-slot",
                                                                    attrs: {
                                                                      slot:
                                                                        "error"
                                                                    },
                                                                    slot:
                                                                      "error"
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "el-icon-picture-outline",
                                                                      staticStyle: {
                                                                        "font-size":
                                                                          "110px"
                                                                      }
                                                                    })
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : item.messageType ==
                                                        "RC:SightMsg"
                                                      ? _c("videoitem", {
                                                          attrs: { item: item },
                                                          on: {
                                                            clickme: function(
                                                              $event
                                                            ) {
                                                              return _vm.playVideo(
                                                                item.content
                                                                  .sightUrl
                                                              )
                                                            }
                                                          }
                                                        })
                                                      : _vm.msg_TRANSFER(
                                                          item.content.content
                                                        ) != ""
                                                      ? _c("div", {
                                                          staticClass:
                                                            "message",
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              _vm.msg_TRANSFER(
                                                                item.content
                                                                  .content
                                                              )
                                                            )
                                                          }
                                                        })
                                                      : item.messageType ==
                                                        "RC:ReferenceMsg"
                                                      ? _c("div")
                                                      : item.messageType ==
                                                        "App:GoodsOrderMsg"
                                                      ? _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ServiceChat-msg-order"
                                                              },
                                                              [
                                                                _c("h2", [
                                                                  _vm._v(
                                                                    "正在咨询的订单"
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "goods"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "img"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            style: {
                                                                              "background-image":
                                                                                "url(" +
                                                                                item
                                                                                  .content
                                                                                  .goodsCover +
                                                                                ")"
                                                                            }
                                                                          }
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "info"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "t1"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item
                                                                                  .content
                                                                                  .goodsName
                                                                              )
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "moreinfo"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "l1"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "共" +
                                                                                    _vm._s(
                                                                                      item
                                                                                        .content
                                                                                        .goodsNum
                                                                                    ) +
                                                                                    "件商品"
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "r1"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "合计：¥" +
                                                                                    _vm._s(
                                                                                      item
                                                                                        .content
                                                                                        .payAmount
                                                                                    )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "row-1"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "订单号：" +
                                                                        _vm._s(
                                                                          item
                                                                            .content
                                                                            .orderNo
                                                                        )
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "row-1"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "创建时间：" +
                                                                        _vm._s(
                                                                          item
                                                                            .content
                                                                            .createdTime
                                                                        )
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "row-2"
                                                                  },
                                                                  [
                                                                    _vm.isShopUser
                                                                      ? _c(
                                                                          "el-button",
                                                                          {
                                                                            attrs: {
                                                                              round:
                                                                                "",
                                                                              size:
                                                                                "small"
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.$router.push(
                                                                                  {
                                                                                    path:
                                                                                      "/order-detail",
                                                                                    query: {
                                                                                      orderSn:
                                                                                        item
                                                                                          .content
                                                                                          .orderNo
                                                                                    }
                                                                                  }
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                  查看"
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _c(
                                                                          "el-button",
                                                                          {
                                                                            attrs: {
                                                                              round:
                                                                                "",
                                                                              size:
                                                                                "small"
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.$router.push(
                                                                                  {
                                                                                    path:
                                                                                      "/orderPlat/order-detail",
                                                                                    query: {
                                                                                      orderSn:
                                                                                        item
                                                                                          .content
                                                                                          .orderNo
                                                                                    }
                                                                                  }
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                  查看"
                                                                            )
                                                                          ]
                                                                        )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _vm.showMsgTooltip
                                                              ? _c(
                                                                  "el-tooltip",
                                                                  {
                                                                    staticClass:
                                                                      "item",
                                                                    attrs: {
                                                                      effect:
                                                                        "dark",
                                                                      content: JSON.stringify(
                                                                        item
                                                                      ),
                                                                      placement:
                                                                        "top-start"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "el-icon-info"
                                                                    })
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        )
                                                      : item.messageType ==
                                                        "App:NearbyGoodsOrderMsg"
                                                      ? _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ServiceChat-msg-order"
                                                              },
                                                              [
                                                                _c("h2", [
                                                                  _vm._v(
                                                                    "正在咨询的订单"
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "goods"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "img"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            style: {
                                                                              "background-image":
                                                                                "url(" +
                                                                                item
                                                                                  .content
                                                                                  .goodsCover +
                                                                                ")"
                                                                            }
                                                                          }
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "info"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "t1"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item
                                                                                  .content
                                                                                  .goodsName
                                                                              )
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "moreinfo"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "l1"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "共" +
                                                                                    _vm._s(
                                                                                      item
                                                                                        .content
                                                                                        .goodsNum
                                                                                    ) +
                                                                                    "件商品"
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "r1"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "合计：¥" +
                                                                                    _vm._s(
                                                                                      item
                                                                                        .content
                                                                                        .payAmount
                                                                                    )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "row-1"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "订单号：" +
                                                                        _vm._s(
                                                                          item
                                                                            .content
                                                                            .orderNo
                                                                        )
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "row-1"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "创建时间：" +
                                                                        _vm._s(
                                                                          item
                                                                            .content
                                                                            .createdTime
                                                                        )
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "row-2"
                                                                  },
                                                                  [
                                                                    item.content
                                                                      .serviceMode ==
                                                                    1
                                                                      ? _c(
                                                                          "div",
                                                                          [
                                                                            _vm.isStoreUser
                                                                              ? _c(
                                                                                  "el-button",
                                                                                  {
                                                                                    attrs: {
                                                                                      round:
                                                                                        "",
                                                                                      size:
                                                                                        "small"
                                                                                    },
                                                                                    on: {
                                                                                      click: function(
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$router.push(
                                                                                          {
                                                                                            path:
                                                                                              "/storeManagement/storeOrderDetail",
                                                                                            query: {
                                                                                              id:
                                                                                                item
                                                                                                  .content
                                                                                                  .orderId
                                                                                            }
                                                                                          }
                                                                                        )
                                                                                      }
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                    查看"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              : _c(
                                                                                  "el-button",
                                                                                  {
                                                                                    attrs: {
                                                                                      round:
                                                                                        "",
                                                                                      size:
                                                                                        "small"
                                                                                    },
                                                                                    on: {
                                                                                      click: function(
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$router.push(
                                                                                          {
                                                                                            path:
                                                                                              "/storeManagementPlat/storeOrderDetailPlat",
                                                                                            query: {
                                                                                              id:
                                                                                                item
                                                                                                  .content
                                                                                                  .orderId
                                                                                            }
                                                                                          }
                                                                                        )
                                                                                      }
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                    查看"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _c(
                                                                          "div",
                                                                          [
                                                                            _vm.isStoreUser
                                                                              ? _c(
                                                                                  "el-button",
                                                                                  {
                                                                                    attrs: {
                                                                                      round:
                                                                                        "",
                                                                                      size:
                                                                                        "small"
                                                                                    },
                                                                                    on: {
                                                                                      click: function(
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$router.push(
                                                                                          {
                                                                                            path:
                                                                                              "/storeManagement/deliveyrDetail",
                                                                                            query: {
                                                                                              id:
                                                                                                item
                                                                                                  .content
                                                                                                  .orderId,
                                                                                              orderSn:
                                                                                                item
                                                                                                  .content
                                                                                                  .orderSn,
                                                                                              pageType: 2
                                                                                            }
                                                                                          }
                                                                                        )
                                                                                      }
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                    查看"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              : _c(
                                                                                  "el-button",
                                                                                  {
                                                                                    attrs: {
                                                                                      round:
                                                                                        "",
                                                                                      size:
                                                                                        "small"
                                                                                    },
                                                                                    on: {
                                                                                      click: function(
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$router.push(
                                                                                          {
                                                                                            path:
                                                                                              "/storeManagementPlat/storeDeliveryOrderDetailPlat",
                                                                                            query: {
                                                                                              id:
                                                                                                item
                                                                                                  .content
                                                                                                  .orderId,
                                                                                              orderSn:
                                                                                                item
                                                                                                  .content
                                                                                                  .orderSn,
                                                                                              pageType: 1
                                                                                            }
                                                                                          }
                                                                                        )
                                                                                      }
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                    查看"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                          ],
                                                                          1
                                                                        )
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _vm.showMsgTooltip
                                                              ? _c(
                                                                  "el-tooltip",
                                                                  {
                                                                    staticClass:
                                                                      "item",
                                                                    attrs: {
                                                                      effect:
                                                                        "dark",
                                                                      content: JSON.stringify(
                                                                        item
                                                                      ),
                                                                      placement:
                                                                        "top-start"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "el-icon-info"
                                                                    })
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        )
                                                      : item.messageType ==
                                                        "App:PhoneChargeOrderMsg"
                                                      ? _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ServiceChat-msg-order"
                                                              },
                                                              [
                                                                _c("h2", [
                                                                  _vm._v(
                                                                    "正在咨询的订单"
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "pad-info"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "r1 r3"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                  " +
                                                                            _vm._s(
                                                                              item
                                                                                .content
                                                                                .rechargeChannel
                                                                            ) +
                                                                            " " +
                                                                            _vm._s(
                                                                              item
                                                                                .content
                                                                                .phoneNumber
                                                                            ) +
                                                                            "\n                                "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "r2"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm._v(
                                                                              "面额：" +
                                                                                _vm._s(
                                                                                  item
                                                                                    .content
                                                                                    .rechargeMoney
                                                                                ) +
                                                                                "元"
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm._v(
                                                                              "合计：" +
                                                                                _vm._s(
                                                                                  item
                                                                                    .content
                                                                                    .payAmount
                                                                                )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "row-1"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "订单号：" +
                                                                        _vm._s(
                                                                          item
                                                                            .content
                                                                            .orderSn
                                                                        )
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "row-1"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "创建时间：" +
                                                                        _vm._s(
                                                                          item
                                                                            .content
                                                                            .createdTime
                                                                        )
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "row-2"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        attrs: {
                                                                          round:
                                                                            "",
                                                                          size:
                                                                            "small"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.$router.push(
                                                                              {
                                                                                path:
                                                                                  "/orderPlat/phoneDetail",
                                                                                query: {
                                                                                  id:
                                                                                    item
                                                                                      .content
                                                                                      .orderId
                                                                                }
                                                                              }
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                  查看"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _vm.showMsgTooltip
                                                              ? _c(
                                                                  "el-tooltip",
                                                                  {
                                                                    staticClass:
                                                                      "item",
                                                                    attrs: {
                                                                      effect:
                                                                        "dark",
                                                                      content: JSON.stringify(
                                                                        item
                                                                      ),
                                                                      placement:
                                                                        "top-start"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "el-icon-info"
                                                                    })
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        )
                                                      : item.messageType ==
                                                        "App:AirplaneOrderMsg"
                                                      ? _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ServiceChat-msg-order"
                                                              },
                                                              [
                                                                _c("h2", [
                                                                  _vm._v(
                                                                    "正在咨询的订单"
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "pad-info"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "r1"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "img",
                                                                          {
                                                                            staticStyle: {
                                                                              position:
                                                                                "relative",
                                                                              top:
                                                                                "-1px"
                                                                            },
                                                                            attrs: {
                                                                              src: require("./fj.png")
                                                                            }
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item
                                                                              .content
                                                                              .startStation
                                                                          ) +
                                                                            "—" +
                                                                            _vm._s(
                                                                              item
                                                                                .content
                                                                                .endStation
                                                                            ) +
                                                                            "\n                                "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "r2"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                  " +
                                                                            _vm._s(
                                                                              item
                                                                                .content
                                                                                .startTime
                                                                            ) +
                                                                            " " +
                                                                            _vm._s(
                                                                              item
                                                                                .content
                                                                                .carrierName
                                                                            ) +
                                                                            _vm._s(
                                                                              item
                                                                                .content
                                                                                .flightNo
                                                                            ) +
                                                                            "\n                                "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "row-1"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "订单号：" +
                                                                        _vm._s(
                                                                          item
                                                                            .content
                                                                            .orderSn
                                                                        )
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "row-1"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "创建时间：" +
                                                                        _vm._s(
                                                                          item
                                                                            .content
                                                                            .createdTime
                                                                        )
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "row-2"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        attrs: {
                                                                          round:
                                                                            "",
                                                                          size:
                                                                            "small"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.$router.push(
                                                                              {
                                                                                path:
                                                                                  "/planeDetail",
                                                                                query: {
                                                                                  orderSn:
                                                                                    item
                                                                                      .content
                                                                                      .orderSn
                                                                                }
                                                                              }
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                  查看"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _vm.showMsgTooltip
                                                              ? _c(
                                                                  "el-tooltip",
                                                                  {
                                                                    staticClass:
                                                                      "item",
                                                                    attrs: {
                                                                      effect:
                                                                        "dark",
                                                                      content: JSON.stringify(
                                                                        item
                                                                      ),
                                                                      placement:
                                                                        "top-start"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "el-icon-info"
                                                                    })
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        )
                                                      : item.messageType ==
                                                        "App:TrainOrderMsg"
                                                      ? _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ServiceChat-msg-order"
                                                              },
                                                              [
                                                                _c("h2", [
                                                                  _vm._v(
                                                                    "正在咨询的订单"
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "pad-info"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "r1"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "img",
                                                                          {
                                                                            staticStyle: {
                                                                              position:
                                                                                "relative",
                                                                              top:
                                                                                "-1px"
                                                                            },
                                                                            attrs: {
                                                                              src: require("./hc.png")
                                                                            }
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item
                                                                              .content
                                                                              .fromStation
                                                                          ) +
                                                                            "—" +
                                                                            _vm._s(
                                                                              item
                                                                                .content
                                                                                .toStation
                                                                            ) +
                                                                            "\n                                "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "r2"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                  " +
                                                                            _vm._s(
                                                                              item
                                                                                .content
                                                                                .departTime
                                                                            ) +
                                                                            " " +
                                                                            _vm._s(
                                                                              item
                                                                                .content
                                                                                .trainNo
                                                                            ) +
                                                                            "\n                                "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "row-1"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "订单号：" +
                                                                        _vm._s(
                                                                          item
                                                                            .content
                                                                            .orderNo
                                                                        )
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "row-1"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "创建时间：" +
                                                                        _vm._s(
                                                                          item
                                                                            .content
                                                                            .createdTime
                                                                        )
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "row-2"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        attrs: {
                                                                          round:
                                                                            "",
                                                                          size:
                                                                            "small"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.$router.push(
                                                                              {
                                                                                path:
                                                                                  "/orderPlat/ticketDetail",
                                                                                query: {
                                                                                  id:
                                                                                    item
                                                                                      .content
                                                                                      .id
                                                                                }
                                                                              }
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                  查看"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _vm.showMsgTooltip
                                                              ? _c(
                                                                  "el-tooltip",
                                                                  {
                                                                    staticClass:
                                                                      "item",
                                                                    attrs: {
                                                                      effect:
                                                                        "dark",
                                                                      content: JSON.stringify(
                                                                        item
                                                                      ),
                                                                      placement:
                                                                        "top-start"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "el-icon-info"
                                                                    })
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        )
                                                      : !_vm.getMsgType(
                                                          item.messageType
                                                        )
                                                      ? _c(
                                                          "div",
                                                          [
                                                            _vm._v(
                                                              "\n                            未知的消息\n                            "
                                                            ),
                                                            _c(
                                                              "el-tooltip",
                                                              {
                                                                staticClass:
                                                                  "item",
                                                                attrs: {
                                                                  effect:
                                                                    "dark",
                                                                  content: JSON.stringify(
                                                                    item
                                                                  ),
                                                                  placement:
                                                                    "top-start"
                                                                }
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "el-icon-info"
                                                                })
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ])
                                    ]
                                  })
                                ],
                                2
                              )
                            : _c(
                                "div",
                                {
                                  ref: "groupContainer",
                                  staticClass: "chat-msg-box",
                                  style: _vm.styleResize2
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-size": "20px",
                                        "text-align": "center",
                                        "margin-top": "30%",
                                        color: "#ccc"
                                      }
                                    },
                                    [_vm._v("暂无信息")]
                                  )
                                ]
                              ),
                          _vm._v(" "),
                          _c("div", { staticClass: "input-box" }, [
                            _vm.filterConversationList.length > 0
                              ? _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.messageText,
                                      expression: "messageText"
                                    }
                                  ],
                                  staticClass: "input",
                                  attrs: {
                                    id: "msg-input",
                                    placeholder: "请输入内容"
                                  },
                                  domProps: { value: _vm.messageText },
                                  on: {
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      if (!$event.ctrlKey) {
                                        return null
                                      }
                                      return _vm.sendTextMessage()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.messageText = $event.target.value
                                    }
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "add-picture-box" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "show-face-box",
                                    on: {
                                      mouseover: function($event) {
                                        _vm.emojiPopoverVisible = true
                                      }
                                    }
                                  },
                                  [
                                    _vm.filterConversationList.length > 0
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              effect: "dark",
                                              content: "表情",
                                              placement: "bottom-start"
                                            }
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("../../assets/service/add_emoji_btn.png"),
                                                alt: "",
                                                width: "25",
                                                height: "25"
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.emojiPopoverVisible
                                      ? _c(
                                          "div",
                                          { staticClass: "face-box" },
                                          _vm._l(_vm.emojiList, function(
                                            ltem,
                                            lndex
                                          ) {
                                            return _c(
                                              "div",
                                              {
                                                key: lndex,
                                                staticClass: "face-item",
                                                staticStyle: { margin: "5px" },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.addEmojiMessage(
                                                      ltem.emojiItem
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                          " +
                                                    _vm._s(ltem.emojiItem) +
                                                    "\n                        "
                                                )
                                              ]
                                            )
                                          }),
                                          0
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "add-picture",
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.imgFn()
                                      }
                                    }
                                  },
                                  [
                                    _vm.filterConversationList.length > 0
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              effect: "dark",
                                              content: "图片",
                                              placement: "bottom-end"
                                            }
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("../../assets/service/add_picture_btn.png"),
                                                alt: "",
                                                width: "25",
                                                height: "25"
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.filterConversationList.length > 0
                                  ? _c("uploadvideo", {
                                      attrs: {
                                        isCurrentSessionChat:
                                          _vm.isCurrentSessionChat,
                                        allowedSendMessage:
                                          _vm.allowedSendMessage
                                      },
                                      on: { sendmsg: _vm.sendVideomsg }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("input", {
                                  ref: "file",
                                  staticClass: "get_img",
                                  staticStyle: { display: "none" },
                                  attrs: {
                                    type: "file",
                                    accept: "image/*",
                                    value: ""
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.imgChange($event)
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "send" },
                              [
                                _vm.filterConversationList.length > 0
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "#ccc",
                                          "font-size": "12px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "Enter换行\n                      Ctrl+Enter发送"
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.filterConversationList.length > 0
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-right": "25px" },
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.sendTextMessage()
                                          }
                                        }
                                      },
                                      [_vm._v("\n                      发送")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _vm.filterConversationList.length > 0
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "mdragresize",
                                      rawName: "v-mdragresize",
                                      value: _vm.THIS,
                                      expression: "(THIS)"
                                    }
                                  ],
                                  staticClass: "drag-container"
                                },
                                [
                                  _c("img", {
                                    attrs: { src: require("./dragresize.png") }
                                  })
                                ]
                              )
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dialog",
                        {
                          staticClass: "chatRecordPad-container",
                          attrs: {
                            title: "聊天记录",
                            visible: _vm.chatRecordPadVisible,
                            width: "60%",
                            "append-to-body": ""
                          },
                          on: {
                            "update:visible": function($event) {
                              _vm.chatRecordPadVisible = $event
                            }
                          }
                        },
                        [
                          _c("chatRecord", {
                            attrs: { chatRecordPadList: _vm.chatRecordPadList },
                            on: {
                              goods: function($event) {
                                _vm.chatRecordPadVisible = false
                                _vm.closeChatBox()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dialog",
                    {
                      attrs: {
                        title: "确定要发送?",
                        visible: _vm.centerDialogVisible,
                        width: "30%",
                        center: "",
                        "append-to-body": ""
                      },
                      on: {
                        "update:visible": function($event) {
                          _vm.centerDialogVisible = $event
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { "text-align": "center" } },
                        [_c("el-image", { attrs: { src: _vm.dialogContent } })],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "dialog-footer",
                          attrs: { slot: "footer" },
                          slot: "footer"
                        },
                        [
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.centerDialogVisible = false
                                }
                              }
                            },
                            [_vm._v("取 消")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  ;(_vm.centerDialogVisible = false),
                                    _vm.sendImageMessage()
                                }
                              }
                            },
                            [_vm._v("发 送\n                ")]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.openBroadside &&
              _vm.identity != "PLATFORM" &&
              this.$parent.shopType == 1
                ? _c("SideToolbar", {
                    ref: "sideToolBox",
                    attrs: {
                      openBroadside: _vm.openBroadside,
                      isCurrentSessionChat: _vm.isCurrentSessionChat,
                      allowedSendMessage: _vm.allowedSendMessage,
                      shopId: this.$parent.shopId,
                      memberId: _vm.memberId,
                      dragResizeHeight: _vm.dragResizeHeight,
                      dragResizeWidth: _vm.dragResizeWidth
                    },
                    on: { addMsg: _vm.addMsg, hideService: _vm.closeChatBox }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.openBroadside &&
              _vm.identity != "PLATFORM" &&
              this.$parent.shopType == 2
                ? _c("SideToolbarStore", {
                    ref: "sideToolBox2",
                    attrs: {
                      openBroadside: _vm.openBroadside,
                      isCurrentSessionChat: _vm.isCurrentSessionChat,
                      allowedSendMessage: _vm.allowedSendMessage,
                      shopId: this.$parent.shopId,
                      memberId: _vm.memberId,
                      dragResizeHeight: _vm.dragResizeHeight,
                      dragResizeWidth: _vm.dragResizeWidth
                    },
                    on: { addMsg: _vm.addMsg, hideService: _vm.closeChatBox }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.openBroadside && _vm.identity == "PLATFORM"
                ? _c("SideToolbarPlat", {
                    ref: "sideToolBoxPlat",
                    attrs: {
                      openBroadside: _vm.openBroadside,
                      isCurrentSessionChat: _vm.isCurrentSessionChat,
                      allowedSendMessage: _vm.allowedSendMessage,
                      dragResizeHeight: _vm.dragResizeHeight,
                      dragResizeWidth: _vm.dragResizeWidth
                    },
                    on: { addMsg: _vm.addMsg, hideService: _vm.closeChatBox }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("ChangSession", {
            ref: "changSessionPad",
            attrs: {
              targetUserInfo: _vm.targetUserInfo,
              targetId: _vm.targetId,
              userId: _vm.userId,
              currentSessionId: _vm.currentSessionId,
              shopId: this.$parent.shopId,
              serviceUserId: this.$parent.serviceUserId,
              shopType: this.$parent.shopType
            },
            on: {
              transfer: function($event) {
                return _vm.checkCurrentSessionStatus()
              }
            }
          }),
          _vm._v(" "),
          _c("QueueList", {
            ref: "queueListPad",
            attrs: {
              userId: _vm.userId,
              shopId: this.$parent.shopId,
              shopType: this.$parent.shopType
            }
          }),
          _vm._v(" "),
          _c("mp4Player", { ref: "mp4Player" })
        ],
        1
      ),
      _vm._v(" "),
      _c("Contextmenu", { ref: "contextmenu", staticClass: "context-menu" }, [
        _c("li", { on: { click: _vm.RcCmdDo } }, [_vm._v("撤销")])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }