/** When your routing table is too long, you can split it into small modules **/
// 客服管理
import Layout from '@/layout';
var servicePlatRouter = {
  path: '/serviceManagementPlat',
  component: Layout,
  alwaysShow: true,
  redirect: '/serviceManagementPlat/serviceList',
  name: 'ServiceManagementPlat',
  meta: {
    title: '客服管理-平台',
    icon: 'peoples'
  },
  children: [{
    path: '/serviceManagementPlat/serviceList',
    component: function component() {
      return import('@/views/serviceManagementPlat/serviceList/serviceListPlat');
    },
    name: 'ServiceListPlat',
    meta: {
      title: '客服列表',
      icon: 'tree'
    }
  }, {
    path: 'chatRecord',
    component: function component() {
      return import('@/views/serviceManagement/chatRecord');
    },
    name: 'ChatRecordPlat',
    meta: {
      title: '会话记录',
      icon: 'tree',
      activeMenu: '/serviceManagementPlat/serviceList'
    },
    hidden: true
  }, {
    path: '/serviceManagementPlat/serviceSet',
    component: function component() {
      return import('@/views/serviceManagementPlat/serviceSet/index.vue');
    },
    name: 'ServiceSetPlat',
    meta: {
      title: '客服设置',
      icon: 'tree'
    }
  }, {
    path: '/serviceManagementPlat/robotServiceSet',
    component: function component() {
      return import('@/views/serviceManagement/robotServiceSet/index.vue');
    },
    name: 'RobotServiceSetPlat',
    meta: {
      title: '机器人设置',
      icon: 'tree'
    }
  }, {
    path: '/serviceManagementPlat/robotServiceSet/knowledgeBaseDetail',
    component: function component() {
      return import('@/views/serviceManagement/robotServiceSet/knowledgeBaseDetail.vue');
    },
    name: 'KnowledgeBaseQuestionDetailPlat',
    meta: {
      title: '知识库问题详情',
      icon: 'tree'
    },
    hidden: true
  }, {
    path: '/serviceManagementPlat/group',
    component: function component() {
      return import('@/views/serviceManagementPlat/group/group.vue');
    },
    name: 'ServiceGroupManagementPlat',
    meta: {
      title: '分组管理',
      icon: 'tree'
    }
  } // {
  //   path: '/serviceManagementPlat/ServiceStatistics',
  //   component: () => import('@/views/serviceManagementPlat/serviceStatistics/index.vue'),
  //   name: 'ServiceStatisticsPlat',
  //   meta: { title: '客服统计', icon: 'tree' }
  // },
  ]
};
export default servicePlatRouter;