// 站长管理-商铺列表
import Layout from '@/layout';
var replacemanageRouter = {
  path: '/replacemanage',
  component: Layout,
  redirect: '/replacemanage/shopslist',
  name: 'Replacemanage',
  meta: {
    title: '商铺列表',
    icon: 'role',
    noCache: true
  },
  children: [{
    path: 'shopslist',
    component: function component() {
      return import('@/views/replacemanage/agentShop.vue');
    },
    name: 'AgencyShopList',
    meta: {
      title: '商铺列表',
      icon: 'peoples'
    }
  }, {
    path: 'companyDetail',
    component: function component() {
      return import('@/views/companyPlat/companyDetail.vue');
    },
    name: 'AgencyCompanyDetail',
    meta: {
      title: '企业详情',
      noCache: true,
      activeMenu: '/replacemanage/shopslist'
    },
    hidden: true
  }, {
    path: 'shopDetail',
    component: function component() {
      return import('@/views/replacemanage/shopDetail');
    },
    name: 'AgencyShopDetail',
    meta: {
      title: '商铺详情',
      noCache: true,
      activeMenu: '/replacemanage/shopslist'
    },
    hidden: true
  }, // {
  //   path: 'storeList',
  //   component: () => import('@/views/replacemanage/storeList.vue'),
  //   name: 'AgencyStoreList',
  //   meta: { title: '门店列表', icon: 'peoples' },
  //   hidden: true
  // },
  {
    path: 'storeDetail',
    component: function component() {
      return import('@/views/replacemanage/storeDetail.vue');
    },
    name: 'AgencyStoreDetail',
    meta: {
      title: '门店详情',
      noCache: true,
      activeMenu: '/replacemanage/shopslist'
    },
    hidden: true
  }, {
    path: 'addshops',
    component: function component() {
      return import('@/views/replacemanage/addCompany.vue');
    },
    name: 'Replaceaddshops',
    meta: {
      title: '新增企业',
      noCache: true,
      activeMenu: '/replacemanage/shopslist'
    },
    hidden: true
  }]
};
export default replacemanageRouter;