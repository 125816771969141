// 招商专员-提现列表
import Layout from '@/layout';
var attractcollectRouter = {
  path: '/attractcollect',
  component: Layout,
  redirect: '/attractcollect/attractwithdrawdeposit',
  name: 'Attractcollect',
  meta: {
    title: '提现列表',
    icon: 'role'
  },
  children: [{
    path: 'attractwithdrawdeposit',
    component: function component() {
      return import('@/views/needwithdraw/withdrawdeposit.vue');
    },
    name: 'Attractwithdrawdeposit',
    meta: {
      title: '提现列表',
      icon: 'peoples'
    }
  }, {
    path: 'attractgatheringaccount',
    component: function component() {
      return import('@/views/needwithdraw/gatheringaccount.vue');
    },
    name: 'Attractgatheringaccount',
    meta: {
      title: '收款账号',
      noCache: true,
      activeMenu: '/attractcollect/attractwithdrawdeposit'
    },
    hidden: true
  }]
};
export default attractcollectRouter;