import "core-js/modules/es6.regexp.replace";
import _Element2 from "element-ui/lib/theme-chalk/index.css";
import _Element from "element-ui/lib";
import "/root/.jenkins/workspace/yuexin_vue/node_modules/core-js/modules/es6.array.iterator.js";
import "/root/.jenkins/workspace/yuexin_vue/node_modules/core-js/modules/es6.promise.js";
import "/root/.jenkins/workspace/yuexin_vue/node_modules/core-js/modules/es6.object.assign.js";
import "/root/.jenkins/workspace/yuexin_vue/node_modules/core-js/modules/es7.promise.finally.js";
// eslint-disable-next-line vue/max-attributes-per-line
console.log('v202204013');
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets

import "./styles/element-variables.scss";
import '@/styles/index.scss'; // global css
// 需要安装，引用省市区联动的插件，全局注册
// import VDistpicker from 'v-distpicker'
// Vue.component('v-distpicker', VDistpicker)

import App from "./App";
import store from "./store";
import router from "./router";
import transform from "./utils/transform.js";
import "./icons"; // icon

import "./permission"; // permission control

import "./utils/error-log"; // error log

import VueClipboard from 'vue-clipboard2';
import DatePicker from '@/components/datePicker/datePicker.js'; //日期查询组件

import VueQuillEditor from 'vue-quill-editor'; //富文本

import 'quill/dist/quill.core.css'; // 引入样式

import 'quill/dist/quill.snow.css'; // snow theme

import 'quill/dist/quill.bubble.css'; // bubble theme

Vue.use(VueClipboard);
Vue.use(_Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size

});
import myPlugin from "./plugins";
Vue.use(myPlugin); // console.log('APP_ENV:', APP_ENV)

import showImage from '@/components/imageView/imageView'; //全局查看大图

Vue.use(showImage);
Vue.use(DatePicker);
import thTips from '@/components/tableHeaderTips';
Vue.use(thTips); // 表头提示自定义提示信息组件

Vue.use(VueQuillEditor
/* { default global options } */
);
Vue.directive('number-input', transform.numberInput); //正整数

Vue.directive('float-input', transform.floatInput); //2位小数

Vue.directive('symbol-input', transform.symbolInput); //非中文

Vue.directive('chinese-input', transform.chineseInput); //中文

Vue.directive('cnanden-input', transform.cnenInput); //中文

Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  },
  data: {
    verificationShopOnOff: true,
    verificationStoreOnOff: true
  },
  methods: {
    // 判断是否是手机
    IsPhone: function IsPhone() {
      //获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
      var info = navigator.userAgent; //通过正则表达式的test方法判断是否包含“Mobile”字符串

      var isPhone = /mobile/i.test(info); //如果包含“Mobile”（是手机设备）则返回true

      return isPhone;
    },
    //商铺是否禁用
    verificationShopOnOffChek: function verificationShopOnOffChek() {
      // console.log("this.verificationShopOnOff:",this.verificationShopOnOff)
      if (!this.verificationShopOnOff) {
        this.$alert('商铺已停用，如有疑问请联系管理员', {
          confirmButtonText: '确定',
          callback: function callback(action) {}
        });
        return false;
      }

      return true;
    },
    //门店是否禁用
    verificationStoreOnOffChek: function verificationStoreOnOffChek() {
      // console.log("this.verificationShopOnOff:",this.verificationShopOnOff)
      if (!this.verificationStoreOnOff) {
        this.$alert('门店已停用，如有疑问请联系管理员', {
          confirmButtonText: '确定',
          callback: function callback(action) {}
        });
        return false;
      }

      return true;
    }
  }
}); //

/*
// 比对查找路由重名
console.log("--==比对查找路由重名==--")
import {constantRoutes,asyncRoutes} from "./router"
console.log("--==chekRouter==--")
let chekRouter=constantRoutes.concat(asyncRoutes)
// console.log(JSON.stringify(chekRouter))
let chekObj={}
function chekKeyName(key,strPath){
  if(chekObj[key]){
    chekObj[key].push(strPath)
  }else{
    chekObj[key]=[strPath]
  }
}
for(let i=0;i<chekRouter.length;i++){
  var item=chekRouter[i]
  var n=item.name
  if(n){
    chekKeyName(n,n)
    if(item.children){

      for(let j=0;j<item.children.length;j++){
        let item1=item.children[j]
        var n1=item1.name
        if(n1){
          chekKeyName(n1,n+" 下级 "+n1)

          if(item1.children){

            for(let k=0;k<item1.children.length;k++){
              let itme2=item1.children[j]
              var n2=itme2.name
              if(n2){
                chekKeyName(n2,n+" 下级 "+n1+" 下级 "+n2)
              }
            }

          }

        }
      }

    }

  }

}
for(let k in chekObj){
  if(chekObj[k].length>1){
    console.log(JSON.stringify(chekObj[k]))
  }
}
//*/

/*
 价格输入框参考：
  <el-input placeholder="请输入原价" v-model="ruleForm.normalPrice" onkeyup="clearNoNum(this)" maxlength="12">
*/

window.clearNoNum = function (obj) {
  obj.value = obj.value.replace(/[^\d.]/g, ''); //清除“数字”和“.”以外的字符

  obj.value = obj.value.replace(/\.{2,}/g, '.'); //只保留第一个. 清除多余的

  obj.value = obj.value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
  obj.value = obj.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); //只能输入两个小数

  if (obj.value.indexOf('.') < 0 && obj.value != '') {
    //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
    obj.value = parseFloat(obj.value);
  }
};