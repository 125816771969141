import { getServiceStatus, setServiceStatus } from '@/api/chat';
var state = {
  onlineState: 1,
  onlineStateId: null,
  unReadArr: [],
  unreadCount: 0,
  newIm: null
};
var mutations = {
  set_im: function set_im(state, val) {
    state.newIm = val;
  },
  set_read_lenth: function set_read_lenth(state, len) {
    state.unReadArr = new Array(len);
  },
  set_read_num: function set_read_num(state, val) {
    state.unReadArr.splice(val.index, 1, val.value);
  },
  set_unRead_count: function set_unRead_count(state, val) {
    state.unreadCount = val;
  },
  CHANG_OnlineStatus: function CHANG_OnlineStatus(state, val) {
    state.onlineState = val;
  },
  CHANG_OnlineStatusId: function CHANG_OnlineStatusId(state, val) {
    // console.log("CHANG_OnlineStatusId:",val)
    state.onlineStateId = val;
  }
};
var actions = {
  GetAllCount: function GetAllCount(_ref, arr) {
    var commit = _ref.commit;
    var list = state.unReadArr;
    var count = 0;
    list.map(function (item) {
      if (item) {
        count = count + item;
      }
    });
    commit('set_unRead_count', count);
  },
  GetOnlineStatus: function GetOnlineStatus(_ref2, storeType) {
    var commit = _ref2.commit;
    getServiceStatus(storeType).then(function (response) {
      // console.log('登陆获取token', response)
      if (response.code == '000000') {
        commit('CHANG_OnlineStatus', response.data.serviceStatus);
        commit('CHANG_OnlineStatusId', response.data.id);
      } else {}
    });
  },
  SetOnlineStatus: function SetOnlineStatus(_ref3, p) {
    var commit = _ref3.commit,
        dispatch = _ref3.dispatch;
    var id = p.id,
        serviceStatus = p.serviceStatus,
        storeType = p.storeType;
    var THIS = p._this; // console.log("SetOnlineStatus....",p)
    // commit('CHANG_OnlineStatus', val)

    setServiceStatus(id, serviceStatus, storeType).then(function (response) {
      // console.log('设置在线状态返回的结果：', response)
      if (THIS.$processingResData(response)) {
        dispatch("GetOnlineStatus", storeType);
      }
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};