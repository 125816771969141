// 平台   消息管理
import Layout from '@/layout';
var messageManagementRouter = {
  path: '/messageManagement',
  component: Layout,
  redirect: '/messageManagement/groupManagement',
  name: 'MessageManagement',
  meta: {
    title: '消息管理-平台',
    icon: 'component'
  },
  children: [{
    path: '/messageManagement/groupManagement',
    component: function component() {
      return import('@/views/messageManagement/groupManagement');
    },
    name: 'GroupManagement',
    meta: {
      title: '群组管理',
      icon: 'peoples'
    }
  }, // {
  //   path: '/messageManagement/groupManagementTwo',
  //   component: () => import('@/views/messageManagement/groupManagementTwo'),
  //   name: 'GroupManagementTwo',
  //   meta: {
  //     title: '群组管理',
  //     icon: 'peoples'
  //   }
  // },
  // //创建粉丝群
  // {
  //   path: '/messageManagement/CreateFanBase',
  //   component: () => import('@/views/messageManagement/CreateFanBase'),
  //   name: 'CreateFanBase',
  //   meta: {
  //     title: '创建粉丝群'},
  //   hidden:true
  // },
  {
    path: '/messageManagement/userManagement',
    component: function component() {
      return import('@/views/messageManagement/userManagement');
    },
    name: 'UserManagement',
    meta: {
      title: '用户管理',
      activeMenu: '/messageManagement/groupManagement'
    },
    hidden: true
  }, {
    path: '/messageManagement/complaint',
    component: function component() {
      return import('@/views/messageManagement/complaint');
    },
    name: 'Complaint',
    meta: {
      title: '投诉列表',
      icon: 'peoples'
    }
  }, {
    path: '/messageManagement/groupSet',
    component: function component() {
      return import('@/views/messageManagement/groupSet');
    },
    name: 'GroupSet',
    meta: {
      title: '群组设置',
      icon: 'peoples'
    }
  }, {
    path: '/messageManagement/noDisturbing',
    component: function component() {
      return import('@/views/messageManagement/noDisturbing');
    },
    name: 'NoDisturbing',
    meta: {
      title: '免打扰设置',
      icon: 'peoples'
    }
  }]
};
export default messageManagementRouter;