import request from '@/utils/request'; // 获取后台用户信息

export function getUserInfo(params) {
  return request({
    url: '/sysadmin/userInfo/getUserInfo',
    method: 'get',
    params: params
  });
} // 编辑(更新)后台用户信息

export function updateUserInfo(data) {
  return request({
    url: '/sysadmin/userInfo/updateUserInfo',
    method: 'post',
    data: data
  });
} // 更换后台用户手机号

export function updateMobile(data) {
  return request({
    url: '/sysadmin/userInfo/updateMobile',
    method: 'post',
    data: data
  });
} // 修改后台用户密码

export function updatePassword(data) {
  return request({
    url: '/sysadmin/userInfo/updatePassword',
    method: 'post',
    data: data
  });
}