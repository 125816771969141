import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.set";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.to-string";
import _typeof from "/root/.jenkins/workspace/yuexin_vue/node_modules/@babel/runtime-corejs2/helpers/esm/typeof";

/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }

  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;

  if (_typeof(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }

    date = new Date(time);
  }

  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, function (result, key) {
    var value = formatObj[key]; // Note: getDay() returns 0 on Sunday

    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }

    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }

    return value || 0;
  });
  return time_str;
}
/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */

export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }

  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;

  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }

  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}
/**
 * @param {string} url
 * @returns {Object}
 */

export function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  var search = url.substring(url.lastIndexOf('?') + 1);
  var obj = {};
  var reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, function (rs, $1, $2) {
    var name = decodeURIComponent($1);
    var val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}
/**
 * @param {string} input value
 * @returns {number} output value
 */

export function byteLength(str) {
  // returns the byte length of an utf8 string
  var s = str.length;

  for (var i = str.length - 1; i >= 0; i--) {
    var code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xdc00 && code <= 0xdfff) i--;
  }

  return s;
}
/**
 * @param {Array} actual
 * @returns {Array}
 */

export function cleanArray(actual) {
  var newArray = [];

  for (var i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }

  return newArray;
}
/**
 * @param {Object} json
 * @returns {Array}
 */

export function param(json) {
  if (!json) return '';
  return cleanArray(Object.keys(json).map(function (key) {
    if (json[key] === undefined) return '';
    return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
  })).join('&');
}
/**
 * @param {string} url
 * @returns {Object}
 */

export function param2Obj(url) {
  var search = url.split('?')[1];

  if (!search) {
    return {};
  }

  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}
/**
 * html转文本
 * @param {string} val
 * @returns {string}
 */

export function html2Text(val) {
  var div = document.createElement('div');
  div.innerHTML = val;
  return div.textContent || div.innerText;
}
/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */

export function objectMerge(target, source) {
  if (_typeof(target) !== 'object') {
    target = {};
  }

  if (Array.isArray(source)) {
    return source.slice();
  }

  Object.keys(source).forEach(function (property) {
    var sourceProperty = source[property];

    if (_typeof(sourceProperty) === 'object') {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}
/**
 * @param {HTMLElement} element
 * @param {string} className
 */

export function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }

  var classString = element.className;
  var nameIndex = classString.indexOf(className);

  if (nameIndex === -1) {
    classString += '' + className;
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
  }

  element.className = classString;
}
/**
 * @param {string} type
 * @returns {Date}
 */

export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}
/**
 * 防抖
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */

export function debounce(func, wait, immediate) {
  var timeout, args, context, timestamp, result;

  var later = function later() {
    // 据上一次触发时间间隔
    var last = +new Date() - timestamp; // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait

    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null; // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用

      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };

  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    context = this;
    timestamp = +new Date();
    var callNow = immediate && !timeout; // 如果延时不存在，重新设定延时

    if (!timeout) timeout = setTimeout(later, wait);

    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };
}
/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */

export function deepClone(source) {
  if (!source && _typeof(source) !== 'object') {
    throw new Error('error arguments', 'deepClone');
  } else {
    var targetObj = source.constructor === Array ? [] : {};
    Object.keys(source).forEach(function (keys) {
      if (source[keys] && _typeof(source[keys]) === 'object') {
        targetObj[keys] = deepClone(source[keys]);
      } else {
        targetObj[keys] = source[keys];
      }
    });
    return targetObj;
  }
}
/**
 * 数组去重
 * @param {Array} arr
 * @returns {Array}
 */

export function uniqueArr(arr) {
  return Array.from(new Set(arr));
}
/**
 * @returns {string}
 */

export function createUniqueString() {
  var timestamp = +new Date() + '';
  var randomNum = parseInt((1 + Math.random()) * 65536) + '';
  return (+(randomNum + timestamp)).toString(32);
}
/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */

export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}
/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */

export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls;
}
/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */

export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
}
/**
 * 获取高德地图promise
 * @return {promise}
 * @author Mario
 * @date 2020/2/20
 */

export function getAMAP() {
  return new Promise(function (resolve, reject) {
    var interval = null;

    function sleep() {
      if (!window.AMap) {
        if (interval) {
          clearTimeout(interval);
        }

        interval = setTimeout(sleep, 200);
      } else {
        resolve(window.AMap);
      }
    }

    if (window.AMap) {
      resolve(window.AMap);
    } else {
      var url = 'https://webapi.amap.com/maps?v=1.4.13&key=9000e5924b8863873e9059ced03430d3&plugin=AMap.MarkerClusterer,AMap.Autocomplete,AMap.PlaceSearch';
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = url;
      script.onerror = reject;
      document.head.appendChild(script);
      sleep();
    }
  });
}
/**
 * 获取高德地图UI组件
 * @return {promise}
 * @author Mario
 * @date 2020/2/20
 */

export function getAMapUI() {
  return new Promise(function (resolve, reject) {
    if (!window.AMapUI) {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://webapi.amap.com/ui/1.0/main.js';
      script.onerror = reject;
      document.head.appendChild(script);
      setTimeout(function () {
        resolve(window.AMapUI);
      }, 500);
    } else {
      resolve(window.AMapUI);
    }
  });
} // 计算时间差。两个时间之间的 S

export function intervalTime(startTime, endTime) {
  var newstartTime = startTime.replace(/\-/g, '/');
  var newendTime = endTime.replace(/\-/g, '/');
  var date1 = new Date(newstartTime); //开始时间

  var date2 = new Date(newendTime); //结束时间

  var date3 = date2.getTime() - date1.getTime(); //时间差的毫秒数
  //计算出相差天数

  var days = Math.floor(date3 / (24 * 3600 * 1000)); //计算出小时数

  var leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数

  var hours = Math.floor(leave1 / (3600 * 1000)); //计算相差分钟数

  var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数

  var minutes = Math.floor(leave2 / (60 * 1000)); //计算相差秒数

  var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数

  var seconds = Math.round(leave3 / 1000); // alert(" 用时： " + days + "天 " + hours + "小时 " + minutes + " 分钟" + seconds + " 秒")

  return days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds;
} // 获取当前时间 || 格式化时间

export function getFormatDate(date) {
  if (date) {
    var date = new Date(date);
  } else {
    var date = new Date();
  }

  var seperator1 = '-';
  var seperator2 = ':';
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds();

  if (month >= 1 && month <= 9) {
    month = '0' + month;
  }

  if (day >= 0 && day <= 9) {
    day = '0' + day;
  }

  if (hours >= 0 && hours <= 9) {
    hours = '0' + hours;
  }

  if (minutes >= 0 && minutes <= 9) {
    minutes = '0' + minutes;
  }

  if (seconds >= 0 && seconds <= 9) {
    seconds = '0' + seconds;
  }

  var currentdate = date.getFullYear() + seperator1 + month + seperator1 + day + ' ' + hours + seperator2 + minutes + seperator2 + seconds;
  return currentdate;
} // 获取当前时间 || 格式化时间 (时分秒)

export function getFormatHour(date) {
  if (date) {
    var date = new Date(date);
  } else {
    var date = new Date();
  } // var seperator1 = '-'


  var seperator2 = ':';
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds();

  if (month >= 1 && month <= 9) {
    month = '0' + month;
  }

  if (day >= 0 && day <= 9) {
    day = '0' + day;
  }

  if (hours >= 0 && hours <= 9) {
    hours = '0' + hours;
  }

  if (minutes >= 0 && minutes <= 9) {
    minutes = '0' + minutes;
  }

  if (seconds >= 0 && seconds <= 9) {
    seconds = '0' + seconds;
  }

  var currentdate = hours + seperator2 + minutes + seperator2 + seconds;
  return currentdate;
} // 删除数据空children

export function removeChildNull(data) {
  if (data) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].children && data[i].children.length < 1) {
        data[i].children = undefined;
      } else {
        removeChildNull(data[i].children);
      }
    }

    return data;
  }
} //日期计算

export function calculateDate(startDate, valueTime) {
  var date = new Date(startDate);
  var newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - valueTime);
  var year = newDate.getFullYear();
  var month = newDate.getMonth() + 1;
  var day = newDate.getDate();
  return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
}
/**
 *
 * @param {是否禁用往后日期 true 禁用} disabledStatus
 * @returns
 */
// 时间选择器 快捷周期

export function pickerOptions(disabledStatus) {
  var objectOptions = {
    shortcuts: [{
      text: '最近30天',
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '最近60天',
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 60);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '最近90天',
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit('pick', [start, end]);
      }
    }]
  }; // 判断是否开启禁用 往后日期选择 true 禁用

  if (disabledStatus) {
    objectOptions.disabledDate = function (time) {
      return time.getTime() > Date.now();
    };
  }

  return objectOptions;
} //限制输入框不能输入中文

export function forbidChinese(e) {
  e.target.value = e.target.value.toString().replace(/[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g, '');
} //限制输入框只能输入正整数

export function restrictedInteger(e) {
  e.target.value = e.target.value.replace(/[^\d]/g, '');
} //限制输入框只能输入小数点后2位

export function restrictedPrice(e) {
  e.target.value = e.target.value.replace(/(^\s*)|(\s*$)/g, '');
  var reg = /[^\d.]/g; // 只能是数字和小数点，不能是其他输入

  e.target.value = e.target.value.replace(reg, ''); // 保证第一位只能是数字，不能是点

  e.target.value = e.target.value.replace(/^\./g, ''); // 小数只能出现1位

  e.target.value = e.target.value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.'); // 小数点后面保留2位

  e.target.value = e.target.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // e.target.value = e.target.value.toString().match(/^\d*(\.?\d{0,2})/g)[0] || null
} // 限制输入框不能输入标点符号、特殊字符

export function forbidSpecialMark(e) {
  e.target.dispatchEvent(new Event('input'));
  e.target.value = e.target.value.replace(/[^\u4E00-\u9FA5]/g, '');
} //判断字符串是否存在数字

export function strIshaveNumber(str) {
  for (var i in str) {
    var asc = str.charCodeAt(i);

    if (asc >= 48 && asc <= 57) {
      return true;
    }
  }

  return false;
}
export function compactObj(obj, fn) {
  for (var i in obj) {
    if (_typeof(obj[i]) === 'object') {
      compactObj(obj[i], fn);
    }

    if (fn(obj[i])) {
      delete obj[i];
    }
  }
}
/*截取地址
string:字符串
str:找的字符 
*/

export function getAddressStr(string, str) {
  var subStr = '';

  if (string) {
    var index = string.indexOf(str);

    if (index != -1) {
      subStr = string.substring(0, index) + '***';
    } else {
      subStr = string.substring(0, 9) + '***';
    }
  }

  return subStr;
} // 删除空对象 删除'', null, undefined

export function isEmpty(foo) {
  if (_typeof(foo) === 'object') {
    for (var i in foo) {
      return false;
    }

    return true;
  } else {
    return foo == '' || foo == null || foo == undefined;
  }
}
/**
 * 获取当日 0 昨日1 近7天 等等 时间段
 * @param dateNow :Date类
 * @param intervalDays ：间隔天数
 * @param bolPastTime  ：Boolean,判断在参数date之前，还是之后，
 */

export function getDateRange(dateNow, intervalDays, bolPastTime) {
  var oneDayTime = 24 * 60 * 60 * 1000;
  var list = [];
  var lastDay;

  if (bolPastTime == true) {
    lastDay = new Date(dateNow.getTime() - intervalDays * oneDayTime);
    list.push(formateDate(lastDay));

    if (intervalDays == 1) {
      // 获取昨日日期
      list.push(formateDate(lastDay, 1));
    } else {
      list.push(formateDate(dateNow, 1));
    }
  } else {
    lastDay = new Date(dateNow.getTime() + intervalDays * oneDayTime);
    list.push(formateDate(dateNow));
    list.push(formateDate(lastDay));
  }

  return list;
}

function formateDate(time, intervalDays) {
  var year = time.getFullYear();
  var month = time.getMonth() + 1;
  var day = time.getDate();
  var hours = '';

  if (month < 10) {
    month = '0' + month;
  }

  if (day < 10) {
    day = '0' + day;
  }

  if (!intervalDays) {
    hours = '00:00:00';
  } else {
    hours = '23:59:59';
  }

  return year + '-' + month + '-' + day + ' ' + hours;
}