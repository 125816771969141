// 运营管理-商铺
import Layout from '@/layout';
var marketingactivityRouter = {
  path: '/marketingactivity',
  component: Layout,
  redirect: '/marketingactivity/activity',
  alwaysShow: true,
  name: 'Marketingactivity',
  meta: {
    title: '运营管理-商铺',
    icon: 'role'
  },
  children: [{
    path: 'activity',
    component: function component() {
      return import('@/views/marketingactivity/activitylist/activity.vue');
    },
    name: 'Activity',
    meta: {
      title: '活动列表',
      icon: 'peoples'
    }
  }, {
    path: 'onlineshoppingmall',
    component: function component() {
      return import('@/views/marketingactivity/activitylist/onlineshoppingmall.vue');
    },
    name: 'onlineshoppingmall',
    meta: {
      title: '活动列表-线上商城'
    }
  }, {
    path: 'reportdetail',
    component: function component() {
      return import('@/views/marketingactivity/activitylist/reportdetail.vue');
    },
    name: 'Reportdetail',
    meta: {
      title: '报名详情',
      noCache: true,
      activeMenu: '/marketingactivity/activity'
    },
    hidden: true
  }, // {
  //   path: 'unregistereddetails',
  //   component: () =>
  //     import('@/views/marketingactivity/activitylist/unregistereddetails.vue'),
  //   name: 'unregistereddetails',
  //   meta: {
  //     title: '未报名详情',
  //   },
  //   hidden: true
  // },
  {
    path: 'selectdiscountgoods',
    component: function component() {
      return import('@/views/marketingactivity/activitylist/selectdiscountgoods.vue');
    },
    name: 'Selectdiscountgoods',
    meta: {
      title: '折扣-选择活动商品',
      noCache: true,
      activeMenu: '/marketingactivity/activity'
    },
    hidden: true
  }, {
    path: 'selectseckillgoods',
    component: function component() {
      return import('@/views/marketingactivity/activitylist/selectseckillgoods.vue');
    },
    name: 'Selectseckillgoods',
    meta: {
      title: '秒杀-选择活动商品',
      noCache: true,
      activeMenu: '/marketingactivity/activity'
    },
    hidden: true
  }, {
    path: '/marketingactivity/reportrecord',
    component: function component() {
      return import('@/views/marketingactivity/applyrecord/reportrecord.vue');
    },
    name: 'Reportrecord',
    meta: {
      title: '报名记录',
      icon: 'peoples'
    }
  }, {
    path: 'recorddetail',
    component: function component() {
      return import('@/views/marketingactivity/applyrecord/recorddetail.vue');
    },
    name: 'Recorddetail',
    meta: {
      title: '记录详情',
      noCache: true,
      activeMenu: '/marketingactivity/reportrecord'
    },
    hidden: true
  }, {
    path: 'editseckill/:id/:eventid',
    component: function component() {
      return import('@/views/marketingactivity/applyrecord/editseckillactivity.vue');
    },
    name: 'Editseckill',
    meta: {
      title: '编辑秒杀活动',
      noCache: true,
      activeMenu: '/marketingactivity/reportrecord'
    },
    hidden: true
  }, {
    path: 'editdiscount/:id/:eventid',
    component: function component() {
      return import('@/views/marketingactivity/applyrecord/editdiscountactivity.vue');
    },
    name: 'Editdiscount',
    meta: {
      title: '编辑折扣活动',
      noCache: true,
      activeMenu: '/marketingactivity/reportrecord'
    },
    hidden: true
  }, {
    path: '/coupon/couponList',
    component: function component() {
      return import('@/views/coupon/couponList');
    },
    name: 'CouponList',
    meta: {
      title: '优惠券列表',
      noCache: true,
      icon: 'list'
    }
  }, {
    path: '/coupon/couponDetail',
    component: function component() {
      return import('@/views/coupon/couponDetail');
    },
    name: 'CouponDetail',
    hidden: true,
    meta: {
      title: '优惠券明细',
      icon: 'excel'
    }
  }, {
    path: '/coupon/addCoupon',
    component: function component() {
      return import('@/views/coupon/addCoupon');
    },
    name: 'AddCoupon',
    hidden: true,
    meta: {
      isCache: true,
      noCache: true,
      title: '添加优惠券',
      icon: 'edit'
    }
  }, // 门店优惠劵
  {
    path: '/coupon/storeList',
    component: function component() {
      return import('@/views/coupon/storeList');
    },
    name: 'StoreCoupoStoreList',
    hidden: false,
    meta: {
      title: '门店优惠劵',
      noCache: true,
      icon: 'list'
    }
  }, {
    path: '/coupon/addStoreCoupon',
    component: function component() {
      return import('@/views/coupon/addStoreCoupon');
    },
    name: 'AddStoreCoupon',
    hidden: true,
    meta: {
      isCache: true,
      noCache: true,
      title: '添加门店优惠券',
      icon: 'edit'
    }
  }, {
    path: '/coupon/storeListDetailed',
    component: function component() {
      return import('@/views/coupon/storeListDetailed');
    },
    name: 'StoreCouponStoreListDetailed',
    hidden: true,
    meta: {
      title: '领取明细',
      icon: 'edit'
    }
  }, {
    path: '/advertisingMarketing',
    component: function component() {
      return import('@/views/marketingactivity/advertisingMarketing/index.vue');
    },
    name: 'AdvertisingMarketing',
    meta: {
      title: '广告营销'
    }
  }, {
    path: '/advertisingMarketing/marketingDetail',
    component: function component() {
      return import('@/views/marketingactivity/advertisingMarketing/marketingDetail.vue');
    },
    name: 'AdvertisingMarketingDetail',
    meta: {
      title: '广告营销详情',
      noCache: true,
      activeMenu: '/advertisingMarketing'
    },
    hidden: true
  }, {
    path: '/advertisingMarketing/pay',
    component: function component() {
      return import('@/views/marketingactivity/advertisingMarketing/pay.vue');
    },
    name: 'AdvertisingPay',
    meta: {
      title: '支付页面',
      noCache: true,
      activeMenu: '/advertisingMarketing'
    },
    hidden: true
  }, {
    path: '/advertisingMarketing/wechatPay',
    component: function component() {
      return import('@/views/marketingactivity/advertisingMarketing/wechatPay.vue');
    },
    name: 'AdvertisingWechatPay',
    meta: {
      title: '微信支付',
      noCache: true,
      activeMenu: '/advertisingMarketing'
    },
    hidden: true
  }, {
    path: '/payResult',
    component: function component() {
      return import('@/views/marketingactivity/advertisingMarketing/payResult.vue');
    },
    name: 'AdvertisingPayResult',
    meta: {
      title: '支付成功',
      noCache: true,
      activeMenu: '/advertisingMarketing'
    },
    hidden: true
  }, {
    path: '/advertisingMarketing/orderList',
    component: function component() {
      return import('@/views/marketingactivity/advertisingMarketing/marketingOrderList.vue');
    },
    name: 'MarketingOrderList',
    meta: {
      title: '已订购广告'
    }
  }, {
    path: '/advertisingMarketing/orderDetail',
    component: function component() {
      return import('@/views/marketingactivity/advertisingMarketing/marketingOrderDetail.vue');
    },
    name: 'MarketingOrderDetail',
    meta: {
      title: '商铺订购详情',
      noCache: true,
      activeMenu: '/advertisingMarketing/orderList'
    },
    hidden: true
  }, {
    path: '/advertisingMarketing/orderEdit',
    component: function component() {
      return import('@/views/marketingactivity/advertisingMarketing/marketingOrderEdit.vue');
    },
    name: 'MarketingOrderEdit',
    meta: {
      title: '编辑资料',
      noCache: true,
      isCache: true,
      activeMenu: '/advertisingMarketing/orderList'
    },
    hidden: true
  }, {
    path: '/marketing/orderRecord',
    component: function component() {
      return import('@/views/marketingactivity/advertisingMarketing/orderRecord.vue');
    },
    name: 'AdvertisingMarketingOrderRecord',
    meta: {
      title: '订购记录',
      noCache: true
    }
  }, {
    path: '/marketing/statData',
    component: function component() {
      return import('@/views/operation/advertising/marketing/statistics.vue');
    },
    name: 'AdvertisingMarketingStat',
    meta: {
      title: '广告数据'
    }
  }]
};
export default marketingactivityRouter;