import _createForOfIteratorHelper from "/root/.jenkins/workspace/yuexin_vue/node_modules/@babel/runtime-corejs2/helpers/esm/createForOfIteratorHelper";
import "core-js/modules/web.dom.iterable";
import Cookies from 'js-cookie';
var TokenKey = 'Admin-Token';
export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token) {
  return Cookies.set(TokenKey, token);
}
export function removeToken() {
  return Cookies.remove(TokenKey);
} // 递归删除children中长度为空的数组

export function traverse(arr) {
  if (arr.length) {
    arr.forEach(function (item) {
      if (item.children.length) {
        traverse(item.children); // 递归遍历
      } else {
        delete item.children; // 如果是值就显示
      }
    });
  }

  return arr;
} // 递归查找特定ID的类别 

export function findCategoryById(categoryList, targetId) {
  if (Array.isArray(targetId)) {
    var result = [];

    var _iterator = _createForOfIteratorHelper(targetId),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var id = _step.value;
        var category = findCategoryById(categoryList, id);

        if (category) {
          result.push(category);
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    return result;
  }

  var _iterator2 = _createForOfIteratorHelper(categoryList),
      _step2;

  try {
    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
      var _category = _step2.value;

      if (_category.id === targetId) {
        return _category;
      } else if (_category.children) {
        var _result = findCategoryById(_category.children, targetId);

        if (_result) {
          return _result;
        }
      }
    }
  } catch (err) {
    _iterator2.e(err);
  } finally {
    _iterator2.f();
  }

  return null;
} // 每个首页获取数据

export function getAnimateNumFun() {
  // this.getAnimateNum=localStorage.getItem('freshNum') 
  setTimeout(function () {
    localStorage.setItem('freshNum', 2);
  }, 3000);
  return localStorage.getItem('freshNum');
}
/**
 * 存储localStorage
 */

export function setStore(name, content) {
  if (!name) return;

  if (typeof content !== 'string') {
    content = JSON.stringify(content);
  }

  window.localStorage.setItem(name, content);
}
/**
 * 获取localStorage
 */

export function getStore(name) {
  if (!name) return;
  return window.localStorage.getItem(name);
}
/**
 *删除localStorage
 */

export function removeStore(name) {
  if (!name) return;
  window.localStorage.removeItem(name);
} // 存储sessionstorage
// 存储session

export function SetSessionStorage(name, content) {
  if (!name) return;

  if (typeof content !== 'string') {
    content = JSON.stringify(content);
  }

  window.sessionStorage.setItem(name, content);
} // 获取session

export function GetSessionStorage(name) {
  if (!name) return;
  return window.sessionStorage.getItem(name);
} // 删除session

export function RemoveSessionStorage(name) {
  if (!name) return;
  window.sessionStorage.removeItem(name);
} //清除所有sessionStorage,localStorage,Cookies

export function clearall() {
  console.log("清除所有sessionStorage,localStorage,Cookies...");
  window.localStorage.clear(); //清除缓存

  window.sessionStorage.clear();
  Cookies.remove(TokenKey);
}