/** When your routing table is too long, you can split it into small modules **/
// 会员管理
import Layout from '@/layout';
var vipManagementRouter = {
  path: '/vipManagement',
  component: Layout,
  redirect: '/vipManagement/vipList',
  alwaysShow: true,
  name: 'Vipmanage',
  meta: {
    title: '会员管理-平台',
    icon: 'role'
  },
  children: [{
    path: 'bakReview',
    component: function component() {
      return import('@/views/vipManagement/bakReview/list');
    },
    name: 'bakReview',
    meta: {
      title: '背景图审核',
      icon: 'peoples',
      noCache: true,
      isCache: true
    }
  }, {
    path: 'bakReviewBat',
    component: function component() {
      return import('@/views/vipManagement/bakReview/bat');
    },
    name: 'bakReviewBat',
    meta: {
      title: '背景图批量审核',
      icon: 'peoples'
    },
    hidden: true
  }, {
    path: 'vipList',
    component: function component() {
      return import('@/views/vipManagement/vipList');
    },
    name: 'Viplist',
    meta: {
      title: '会员列表',
      icon: 'peoples'
    }
  }, {
    path: 'vipDetail/:id',
    component: function component() {
      return import('@/views/vipManagement/vipDetail');
    },
    name: 'Vipdetail',
    meta: {
      title: '会员详情',
      noCache: false,
      activeMenu: '/vipManagement/vipList'
    },
    hidden: true
  }, {
    path: 'vipGrade',
    component: function component() {
      return import('@/views/vipManagement/vipGrade');
    },
    name: 'Vipgragelist',
    meta: {
      title: '会员等级',
      icon: 'tree-table'
    }
  }, {
    path: 'yuexinBeanList',
    component: function component() {
      return import('@/views/vipManagement/yuexinBeanList');
    },
    name: 'BeanDetails',
    meta: {
      title: '悦鑫豆明细',
      icon: 'list'
    },
    hidden: false
  }, {
    path: 'vipBlack',
    component: function component() {
      return import('@/views/vipManagement/vipBlack');
    },
    name: 'Vipblack',
    meta: {
      title: '黑名单列表',
      noCache: true,
      icon: 'peoples'
    }
  }, {
    path: 'blackCause',
    component: function component() {
      return import('@/views/vipManagement/blacklistCause');
    },
    name: 'Blackcause',
    meta: {
      title: '黑名单原因',
      noCache: true,
      activeMenu: '/vipManagement/vipBlack'
    },
    hidden: true
  }, {
    path: 'reportTypes',
    component: function component() {
      return import('@/views/vipManagement/reportTypes');
    },
    name: 'Reporttypes',
    meta: {
      title: '举报类型',
      icon: 'peoples'
    }
  }, {
    path: 'userReport',
    component: function component() {
      return import('@/views/vipManagement/userReport');
    },
    name: 'Userreport',
    meta: {
      title: '用户举报',
      icon: 'peoples',
      noCache: true
    }
  }, {
    path: 'userReportDetail',
    component: function component() {
      return import('@/views/vipManagement/userReportDetail');
    },
    name: 'UserReportDetailPlat',
    meta: {
      title: '举报明细列表',
      icon: 'peoples',
      noCache: true,
      activeMenu: '/vipManagement/userReport'
    },
    hidden: true
  }, {
    path: 'vipManagementSignIn',
    component: function component() {
      return import('@/views/vipManagement/signIn');
    },
    name: 'vipManagementSignIn',
    meta: {
      title: '签到设置',
      icon: 'peoples'
    }
  }, {
    path: 'vipManagementSignInRecord',
    component: function component() {
      return import('@/views/vipManagement/signInRecord');
    },
    name: 'vipManagementSignInRecord',
    meta: {
      title: '签到记录',
      icon: 'peoples'
    }
  }, {
    path: 'vipManagementFansInquire',
    component: function component() {
      return import('@/views/vipManagement/fansInquire');
    },
    name: 'vipManagementFansInquire',
    meta: {
      title: '粉丝查询',
      icon: 'search'
    }
  }, {
    path: 'inviterEditLog',
    component: function component() {
      return import('@/views/vipManagement/inviterEditLog');
    },
    name: 'InviterEditLog',
    meta: {
      title: '邀请人修改日志',
      icon: 'search'
    },
    hidden: true
  }, {
    path: 'historyFans',
    component: function component() {
      return import('@/views/vipManagement/historyFans');
    },
    name: 'HistoryFans',
    meta: {
      title: '历史粉丝',
      icon: 'search'
    },
    hidden: true
  }, {
    path: 'vipManagementFansDetail',
    component: function component() {
      return import('@/views/vipManagement/fansDetail');
    },
    name: 'vipManagementFansDetail',
    meta: {
      title: '粉丝明细',
      icon: 'search',
      noCache: true,
      activeMenu: '/vipManagement/vipManagementFansInquire'
    },
    hidden: true
  }, {
    path: 'vipManagementFansAward',
    component: function component() {
      return import('@/views/vipManagement/fansAward');
    },
    name: 'vipManagementFansAward',
    meta: {
      title: '奖励明细',
      icon: 'search',
      activeMenu: '/vipManagement/vipManagementFansInquire'
    },
    hidden: true
  }, {
    path: 'MemberWithdrawal',
    component: function component() {
      return import('@/views/vipManagement/memberWithdrawal');
    },
    name: 'MemberWithdrawal',
    meta: {
      title: '会员提现',
      icon: 'money'
    }
  }, {
    path: 'WithdrawalSet',
    component: function component() {
      return import('@/views/vipManagement/withdrawalSet');
    },
    name: 'WithdrawalSet',
    meta: {
      title: '提现设置',
      icon: 'edit'
    }
  }, {
    path: 'taskCenter',
    component: function component() {
      return import('@/views/vipManagement/tasksCenter');
    },
    name: 'VipTaskCenter',
    meta: {
      title: '任务中心',
      icon: 'edit'
    }
  }, {
    path: 'vipWallet',
    component: function component() {
      return import('@/views/vipManagement/vipWallet');
    },
    name: 'VipWallet',
    meta: {
      title: '会员充值',
      icon: 'money'
    }
  }, //旧的积分管理(2021-12后可以删除 )
  {
    path: 'integral',
    component: function component() {
      return import('@/views/vipManagement/pointManagement');
    },
    name: 'VipPointManagement',
    meta: {
      title: '积分管理',
      icon: 'list'
    }
  }, {
    path: 'logs',
    component: function component() {
      return import('@/views/vipManagement/logs');
    },
    name: 'VipLogs',
    meta: {
      title: '登录日志',
      icon: 'list'
    }
  }, //新的积分管理
  {
    path: 'integralnew',
    component: function component() {
      return import('@/views/vipManagement/pointManagementNew');
    },
    name: 'VipPointManagementNew',
    meta: {
      title: '积分明细',
      icon: 'list'
    }
  }, {
    path: 'integralnew2',
    component: function component() {
      return import('@/views/vipManagement/pointManagementNew2');
    },
    name: 'VipPointManagementNew2',
    meta: {
      title: '待发流水',
      icon: 'list'
    },
    hidden: true
  }, {
    path: 'cancellationList',
    component: function component() {
      return import('@/views/vipManagement/cancellationList');
    },
    name: 'VipPointManagementCancel',
    meta: {
      title: '注销列表',
      icon: 'list'
    },
    hidden: false
  }]
};
export default vipManagementRouter;