import Layout from '@/layout'; //商家互动

var articleManagementPlatRouter = {
  path: '/articleManagementPlat',
  component: Layout,
  redirect: '/articleManagementPlat/list',
  alwaysShow: true,
  name: 'articleManagementPlat',
  meta: {
    title: '文章管理-企业端'
  },
  children: [{
    path: 'articleMessagePlat',
    component: function component() {
      return import('@/views/articleManagementPlat/articleMessagePlat.vue');
    },
    name: 'articleMessagePlat',
    meta: {
      title: '文章内容',
      icon: 'list'
    }
  }, {
    path: 'articleLikePlat',
    component: function component() {
      return import('@/views/articleManagementPlat/articleLikePlat.vue');
    },
    name: 'articleLikePlat',
    meta: {
      title: '文章点赞',
      icon: 'list'
    },
    hidden: true
  }, {
    path: 'articleReportPlat',
    component: function component() {
      return import('@/views/articleManagementPlat/reportComplaints/list.vue');
    },
    name: 'articleReportPlat',
    meta: {
      title: '文章举报',
      icon: 'list'
    }
  }, {
    path: 'articleReportDetailPlat',
    component: function component() {
      return import('@/views/articleManagementPlat/reportComplaints/detail.vue');
    },
    name: 'articleReportDetailPlat',
    meta: {
      title: '举报明细列表',
      icon: 'list'
    },
    hidden: true
  }]
};
export default articleManagementPlatRouter;