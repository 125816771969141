import "core-js/modules/es6.number.constructor";
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    AttC: {
      type: Number
    }
  },
  render: function render(h, context) {
    var _context$props = context.props,
        icon = _context$props.icon,
        title = _context$props.title,
        AttC = _context$props.AttC;
    var vnodes = [];

    var _this = this;

    if (icon) {
      vnodes.push(h("svg-icon", {
        "attrs": {
          "icon-class": icon
        }
      }));
    }

    if (title) {
      vnodes.push(h("span", {
        "slot": 'title'
      }, [title]));
    } // if(AttC>=0){
    //   vnodes.push(<span slot='title' style='margin-left:5px'>({(AttC)})</span>)
    // }


    return vnodes;
  }
};