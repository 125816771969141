// 招商专员-商铺列表
import Layout from '@/layout';
var attractCompanyRouter = {
  path: '/attachCompany',
  component: Layout,
  redirect: '/attachCompany/companyList',
  name: 'AttachCompany',
  meta: {
    title: '企业列表',
    icon: 'role'
  },
  children: [{
    path: 'companyList',
    component: function component() {
      return import('@/views/replacemanage/companyList.vue');
    },
    name: 'AttachCompanyList',
    meta: {
      title: '企业列表'
    }
  }, {
    path: 'addCompany',
    component: function component() {
      return import('@/views/replacemanage/createCompany.vue');
    },
    name: 'AttachCreateCompany',
    meta: {
      title: '新增企业',
      noCache: true,
      isCache: true,
      activeMenu: '/attachCompany/companyList'
    },
    hidden: true
  }, {
    path: 'companyInfo',
    component: function component() {
      return import('@/views/companyPlat/companyDetail.vue');
    },
    name: 'AttachCompanyInfo',
    meta: {
      title: '企业详情',
      noCache: true,
      activeMenu: '/attachCompany/companyList'
    },
    hidden: true
  }]
};
export default attractCompanyRouter;