// 商铺端-附近门店：商品列表
import request from '@/utils/request'; // 商品统计

export function commodityStatistics(params) {
  return request({
    url: "/nearby/admin/commodity/statistics",
    method: 'get',
    params: params
  });
} // 商品列表 (高级搜索)

export function commodityPage(current, size, data) {
  return request({
    url: "/nearby/admin/commodity/page?current=".concat(current, "&size=").concat(size),
    method: 'post',
    data: data
  });
} // 批发记录

export function commodityWholesaleAll(data) {
  return request({
    url: "/nearby/commodityWholesale/getCodeList",
    method: 'post',
    data: data
  });
} // 批发详情

export function WholesaleAllDel(data) {
  return request({
    url: "/nearby/commodityWholesale/all",
    method: 'post',
    data: data
  });
} // 代发审核

export function WholesaleCheck(id, data) {
  return request({
    url: "/nearby/commodityWholesaleRecord/".concat(id),
    method: 'put',
    data: data
  });
} // 解除代发关系

export function WholesaleUpdate(data) {
  return request({
    url: "/nearby/commodityWholesale/update",
    method: 'put',
    data: data
  });
} // 商品批发确认

export function commodityWholesale(data) {
  return request({
    url: "/nearby/commodityWholesale/adds",
    method: 'post',
    data: data
  });
} // 商品批发列表

export function getWholesaleList(current, size, data) {
  return request({
    url: "/nearby/admin/commodity/getWholesaleList?current=".concat(current, "&size=").concat(size),
    method: 'post',
    data: data
  });
}
export function adminGoodsAll(data) {
  return request({
    url: '/sysadmin/goods/adminGoods/all',
    method: 'post',
    data: data
  });
} // 新增商品

export function commodityCreate(data) {
  return request({
    url: "/nearby/admin/commodity",
    method: 'post',
    data: data
  });
} // 更新商品

export function commodityUpdate(data) {
  return request({
    url: "/nearby/admin/commodity",
    method: 'put',
    data: data
  });
} // 修改库存

export function commodityStock(id, number, openLimit) {
  return request({
    url: "/nearby/admin/commodity/".concat(id, "/stock/").concat(number, "/").concat(openLimit),
    method: 'put'
  });
} // 是否置顶

export function commodityIsTop(id, isTop) {
  return request({
    url: "/nearby/admin/commodity/".concat(id, "/isTop/").concat(isTop),
    method: 'put'
  });
} // 下架

export function commodityDecline(id) {
  return request({
    url: "/nearby/admin/commodity/".concat(id, "/decline"),
    method: 'put'
  });
} // 批量上架

export function commodityBatchShelvers(data) {
  return request({
    url: "/nearby/admin/commodity/batchShelvers",
    method: 'put',
    data: data
  });
} // 批量下架

export function commodityBatchDecline(data) {
  return request({
    url: "/nearby/admin/commodity/batchDecline",
    method: 'put',
    data: data
  });
} // 加入回收站,即商品中的删除

export function delCommodity(data) {
  return request({
    url: "/nearby/admin/commodity/recycleBin",
    method: 'put',
    data: data
  });
} // 商品详情

export function commodityInfo(id) {
  return request({
    url: "/nearby/admin/commodity/info/".concat(id),
    method: 'get'
  });
} // 商品详情/统计信息

export function statisticsInfo(id) {
  return request({
    url: "/nearby/admin/commodity/info/statistics/".concat(id),
    method: 'get'
  });
} // 商品排序

export function commodityList(id, sort) {
  return request({
    url: "/nearby/admin/commodity/".concat(id, "/sort/").concat(sort),
    method: 'put'
  });
} // 删除 (物理删除), 草稿箱中的删除

export function delDraftsCommodity(data) {
  return request({
    url: "/nearby/admin/commodity",
    method: 'delete',
    data: data
  });
} // 门店：库存预警数据列表

export function getStockWarningList(data) {
  return request({
    url: "/nearby/admin/commodity/StockWarning/stockWarningList",
    method: 'post',
    data: data
  });
} // 平台/门店：库存预警总数

export function getStockCount(params) {
  return request({
    url: '/nearby/admin/commodity/StockWarning/getCount',
    method: 'get',
    params: params
  });
}