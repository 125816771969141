import request from '@/utils/request'; // 后台用户未读消息列表(首页)

export function getHeaderMessageList(data) {
  return request({
    url: '/sysadmin/usersMessage/home/list',
    method: 'post',
    data: data
  });
} // 后台用户未读消息数量(首页)

export function unReadHeaderCount(data) {
  return request({
    url: '/sysadmin/usersMessage/home/count',
    method: 'post',
    data: data
  });
} // 后台用户消息列表分页 (包含 全部、未读、已读 数量)

export function getMessageList(data) {
  return request({
    url: '/sysadmin/usersMessage/page',
    method: 'post',
    data: data
  });
} // 批量标记后台用户消息为已读 可以传一个id 也可以不传 不传就更新全部

export function updateMessageRead(data) {
  return request({
    url: '/sysadmin/usersMessage/updateMessageRead',
    method: 'post',
    data: data
  });
} // 批量删除后台用户消息 参数消息id集合 不传就删除全部

export function deleteMessage(data) {
  return request({
    url: '/sysadmin/usersMessage/delete',
    method: 'delete',
    data: data
  });
} // 按状态全部删除后台对应状态的用户消息

export function deleteUserMessage(data) {
  return request({
    url: '/sysadmin/usersMessage/deleteUserMessage',
    method: 'post',
    data: data
  });
} // 获取企业有菜单code权限的userId集合

export function getAuthUserIds(data) {
  return request({
    url: '/sysadmin/user/getAuthUserIds',
    method: 'post',
    data: data
  });
} // 消息推送接口 推送给指定的后台用户

export function pushToWeb(data) {
  return request({
    url: '/sysadmin/usersMessage/pushToWeb',
    method: 'post',
    data: data
  });
}