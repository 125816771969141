var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "chat-container" }, [
      _c("div", { staticClass: "chat-background chat-background-new" }, [
        _c(
          "div",
          { staticClass: "chat-con" },
          [
            _c(
              "div",
              { staticClass: "chat-title" },
              _vm._l(_vm.listChat, function(item, i) {
                return _c(
                  "h1",
                  {
                    key: i,
                    class: [_vm.activeName == i ? "el-tab-zd" : ""],
                    on: {
                      click: function($event) {
                        return _vm.handleClick(
                          i,
                          item.id,
                          item.shopType,
                          item.serviceUserId
                        )
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(item.shopName) +
                        _vm._s(_vm.listArr[i] ? _vm.listArr[i] : "")
                    )
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _vm._l(_vm.listChat, function(item, i) {
              return _c("ChatItem", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeName == i,
                    expression: "activeName == i"
                  }
                ],
                key: i,
                ref: "serviceChat" + i,
                refInFor: true,
                attrs: {
                  chatUserId: item.shopType == 1 ? null : item.id,
                  activeName: _vm.activeName,
                  chatIndex: i
                },
                on: { close: _vm.close }
              })
            })
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }