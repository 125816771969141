//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Player from 'xgplayer';
import 'xgplayer-mp4';
export default {
  name: 'mp4Player',
  components: {},
  props: {
    modal: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      player: null,
      url: "",
      height: 645,
      width: "100%"
    };
  },
  methods: {
    closeCallback: function closeCallback() {
      // console.log("beforeClose"
      if (this.player) {
        //关闭销毁播放器
        this.player.destroy(true);
      }
    },
    open: function open(url) {
      // console.log("open#123123123asdf:", url)
      this.dialogVisible = true;
      this.url = url;
      /*this.$nextTick(() => {
        // 文档：https://v2.h5player.bytedance.com/examples/?type=vod&plugin=xg-mp4
        // https://v2.h5player.bytedance.com/plugins/#xgplayer-mp4
        // let player = 
          this.player=new Player({
            id: 'mp4player',
            url: url,
            playsinline: true,
            height: 645,
            width: "100%",
            autoplay: true,
            preloadTime: 15 // 默认预加载 15 秒
          })
      })*/
    },
    //点击关闭
    handleClose: function handleClose() {
      this.dialogVisible = false;
    }
  }
};