import "core-js/modules/es6.regexp.replace";

function numberFun(el, ele, binding, vnode) {
  function handle() {
    // 只保留数字
    ele.value = ele.value.replace(/[^\d]/g, '');

    if (vnode.componentInstance) {
      vnode.componentInstance.$emit('input', ele.value);
    } else {
      vnode.elm.dispatchEvent(new CustomEvent('input', ele.value));
    }
  }

  return handle;
}

var numberInput = {
  bind: function bind(el, binding, vnode) {
    var ele = el.tagName === 'INPUT' ? el : el.querySelector('input');
    ele.addEventListener('input', numberFun(el, ele, binding, vnode), false);
  }
}; //2位小数

function floatFun(el, ele, binding, vnode) {
  function handle() {
    ele.value = ele.value.replace(/(^\s*)|(\s*$)/g, "");
    var reg = /[^\d.]/g; // 只能是数字和小数点，不能是其他输入

    ele.value = ele.value.replace(reg, ""); // 保证第一位只能是数字，不能是点

    ele.value = ele.value.replace(/^\./g, ""); // 小数只能出现1位

    ele.value = ele.value.replace(".", "$#$").replace(/\./g, "").replace("$#$", "."); // 小数点后面保留2位

    ele.value = ele.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");

    if (vnode.componentInstance) {
      vnode.componentInstance.$emit('input', ele.value);
    } else {
      vnode.elm.dispatchEvent(new CustomEvent('input', ele.value));
    }
  }

  return handle;
}

var floatInput = {
  bind: function bind(el, binding, vnode) {
    var ele = el.tagName === 'INPUT' ? el : el.querySelector('input');
    ele.addEventListener('input', floatFun(el, ele, binding, vnode), false);
  }
}; //不能输入中文

function symbolFun(el, ele, binding, vnode) {
  function handle() {
    ele.value = ele.value.replace(/[^\w\.\/]/ig, '');

    if (vnode.componentInstance) {
      vnode.componentInstance.$emit('input', ele.value);
    } else {
      vnode.elm.dispatchEvent(new CustomEvent('input', ele.value));
    }
  }

  return handle;
}

var symbolInput = {
  bind: function bind(el, binding, vnode) {
    var ele = el.tagName === 'INPUT' ? el : el.querySelector('input');
    ele.addEventListener('input', symbolFun(el, ele, binding, vnode), false);
  }
};

function chineseFun(el, ele, binding, vnode) {
  function handle() {
    ele.value = ele.value.replace(/[^\u4E00-\u9FA5]/g, '');

    if (vnode.componentInstance) {
      vnode.componentInstance.$emit('input', ele.value);
    } else {
      vnode.elm.dispatchEvent(new CustomEvent('input', ele.value));
    }
  }

  return handle;
}

var chineseInput = {
  bind: function bind(el, binding, vnode) {
    var ele = el.tagName === 'INPUT' ? el : el.querySelector('input');
    ele.addEventListener('input', chineseFun(el, ele, binding, vnode), false);
  }
};

function cnAndEnFun(el, ele, binding, vnode) {
  function handle() {
    ele.value = ele.value.replace(/[^\u4e00-\u9fa5\w]/g, '').replace(/[\d]/g, '');

    if (vnode.componentInstance) {
      vnode.componentInstance.$emit('input', ele.value);
    } else {
      vnode.elm.dispatchEvent(new CustomEvent('input', ele.value));
    }
  }

  return handle;
}

var cnenInput = {
  bind: function bind(el, binding, vnode) {
    var ele = el.tagName === 'INPUT' ? el : el.querySelector('input');
    ele.addEventListener('input', cnAndEnFun(el, ele, binding, vnode), false);
  }
};
var transform = {
  numberInput: numberInput,
  floatInput: floatInput,
  symbolInput: symbolInput,
  chineseInput: chineseInput,
  cnenInput: cnenInput
};
export default transform;