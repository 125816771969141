import Layout from '@/layout'; //商家互动

var merchantInteractionRouter = {
  path: '/merchantInteraction',
  component: Layout,
  redirect: '/merchantInteraction/list',
  alwaysShow: true,
  name: 'merchantInteraction',
  meta: {
    title: '商家互动-平台端'
  },
  children: [{
    path: 'indexInt',
    component: function component() {
      return import('@/views/merchantInteraction/indexInt.vue');
    },
    name: 'indexInt',
    meta: {
      title: '商家互动',
      icon: 'list'
    }
  }, {
    path: 'recommendationList',
    component: function component() {
      return import('@/views/merchantInteraction/recommendationList.vue');
    },
    name: 'recommendationList',
    hidden: true,
    meta: {
      title: '推荐列表',
      icon: 'list',
      tabName: "recommendationList"
    }
  }, {
    path: 'activityList',
    component: function component() {
      return import('@/views/merchantInteraction/activityList.vue');
    },
    name: 'activityList',
    hidden: true,
    meta: {
      title: '活动列表',
      icon: 'list',
      tabName: 'activityList'
    }
  }, {
    path: 'activityListDetail/:id?',
    component: function component() {
      return import('@/views/merchantInteraction/activityListDetail.vue');
    },
    name: 'activityListDetail',
    hidden: true,
    meta: {
      title: '活动列表详情',
      activeMenu: '/merchantInteraction/activityList'
    }
  }, {
    path: 'productInteraction',
    component: function component() {
      return import('@/views/merchantInteraction/productInteraction.vue');
    },
    name: 'productInteraction',
    hidden: true,
    meta: {
      title: '商品互动',
      icon: 'list',
      tabName: 'productInteraction'
    }
  }, {
    path: 'activityInteraction',
    component: function component() {
      return import('@/views/merchantInteraction/activityInteraction.vue');
    },
    name: 'activityInteraction',
    hidden: true,
    meta: {
      title: '活动互动',
      icon: 'list',
      tabName: 'activityInteraction'
    }
  }, {
    path: 'activityInteractionDetail/:id',
    component: function component() {
      return import('@/views/merchantInteraction/activityInteractionDetail.vue');
    },
    name: 'activityInteractionDetail',
    hidden: true,
    meta: {
      title: '活动互动详情',
      activeMenu: '/merchantInteraction/activityInteraction'
    } // hidden: true

  }, {
    path: 'authorizationRecord',
    component: function component() {
      return import('@/views/merchantInteraction/authorizationRecord.vue');
    },
    name: 'authorizationRecord',
    hidden: true,
    meta: {
      title: '授权记录',
      icon: 'list',
      tabName: "authorizationRecord"
    }
  }, {
    path: 'message',
    component: function component() {
      return import('@/views/merchantInteraction/message.vue');
    },
    name: 'message',
    hidden: true,
    meta: {
      title: '摄像头留言管理',
      icon: 'list',
      tabName: 'message'
    }
  }, {
    path: 'bullet',
    component: function component() {
      return import('@/views/merchantInteraction/bullet.vue');
    },
    name: 'bullet',
    hidden: true,
    meta: {
      title: '摄像头弹幕管理',
      icon: 'list',
      tabName: 'bullet'
    }
  }, {
    path: 'merchantInteractionLike',
    component: function component() {
      return import('@/views/merchantInteraction/merchantInteractionLike.vue');
    },
    name: 'merchantInteractionLike',
    meta: {
      title: '留言点赞',
      icon: 'list',
      tabName: 'merchantInteractionLike'
    },
    hidden: true
  }, {
    path: 'messageBlackList',
    component: function component() {
      return import('@/views/merchantInteraction/messageBlackList.vue');
    },
    name: 'messageBlackList',
    meta: {
      title: '拉黑管理',
      icon: 'list',
      tabName: 'messageBlackList'
    },
    hidden: true
  }, {
    path: 'reportComplaintsDetail',
    component: function component() {
      return import('@/views/merchantInteraction/reportComplaints/detail.vue');
    },
    name: 'reportComplaintsDetail',
    meta: {
      title: '举报明细列表',
      icon: 'list',
      tabName: 'reportComplaintsDetail'
    },
    hidden: true
  }]
};
export default merchantInteractionRouter;