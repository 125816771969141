var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderlist-item-Train-container" },
    [
      _c("div", { staticClass: "row1" }, [
        _c("div", { staticClass: "label" }, [
          _vm._v("\n        订单编号: "),
          _c(
            "span",
            {
              staticClass: "sn",
              on: {
                click: function($event) {
                  return _vm.goDetails(_vm.data.id)
                }
              }
            },
            [_vm._v(_vm._s(_vm.data.companyOrderId))]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "v red" }, [
          _vm._v(_vm._s(_vm.data.orderStatusText))
        ])
      ]),
      _vm._v(" "),
      _c("ToPad", { attrs: { data: _vm.data.toData } }),
      _vm._v(" "),
      _c("div", { staticClass: "row1" }, [
        _c("div", { staticClass: "label" }, [
          _vm._v(
            "\n       总数量：" + _vm._s(_vm.data.ticketCount) + "张\n     "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "v" }, [
          _vm._v("¥" + _vm._s(_vm.data.orderAmount))
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row1 down-line" }, [
        _c("div", { staticClass: "downt" }, [
          _vm._v("下单时间：" + _vm._s(_vm.data.createdTime))
        ]),
        _vm._v(" "),
        _vm.data.lastPayTime && _vm.data.lastPayTime != ""
          ? _c("div", { staticClass: "downtip" }, [
              _vm._v("订单将于" + _vm._s(_vm.data.lastPayTime) + " 自动关闭")
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }