var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "changSession-container",
      attrs: {
        title: "排队列表",
        width: _vm.QlistPhone ? "100%" : "60%",
        "close-on-click-modal": true,
        visible: _vm.dialogVisible
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "top-container" },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-refresh-right" },
              on: {
                click: function($event) {
                  return _vm.list()
                }
              }
            },
            [_vm._v("刷新")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-chat-dot-round" },
              on: {
                click: function($event) {
                  return _vm.selectOk()
                }
              }
            },
            [_vm._v("接入")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { data: _vm.data, border: "", height: "350" },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", align: "center", width: "50" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { property: "num", label: "当前排队序号" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { property: "userNickname", label: "用户昵称" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { property: "time", label: "排队时间" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { property: "status", label: "排队超时" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status == 1
                      ? _c("span", [_vm._v("已超时")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 0
                      ? _c("span", [_vm._v("未超时")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.selectOk(scope.row)
                          }
                        }
                      },
                      [_vm._v("接入")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("el-pagination", {
            staticStyle: { "text-align": "right", "margin-top": "22px" },
            attrs: {
              "hide-on-single-page": true,
              "current-page": _vm.currentPage,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }