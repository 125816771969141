import Layout from '@/layout';
var storeManagementPlat = {
  path: '/storeManagementPlat',
  component: Layout,
  redirect: '/storeManagementPlat',
  alwaysShow: true,
  name: 'StoreManagementPlat',
  meta: {
    title: '门店管理',
    icon: 'role'
  },
  children: [{
    path: 'storeSetting',
    component: function component() {
      return import('@/views/storeManagementPlat/index');
    },
    name: 'StoreSetting',
    meta: {
      title: '门店设置',
      icon: 'peoples'
    }
  }, {
    path: 'storeHome',
    component: function component() {
      return import('@/views/storeManagementPlat/home/storeHome');
    },
    name: 'StoreHome',
    meta: {
      title: '门店首页',
      noCache: true
    }
  }, {
    path: 'specificationList',
    component: function component() {
      return import('@/views/storeManagementPlat/specification/index');
    },
    name: 'SpecificaList',
    meta: {
      title: '商品规格',
      icon: 'list'
    }
  }, {
    path: 'storeLevel',
    component: function component() {
      return import('@/views/storeManagementPlat/level/storeLevel');
    },
    name: 'StoreLevel',
    meta: {
      title: '门店等级',
      icon: 'peoples'
    }
  }, {
    path: 'storeClassification',
    component: function component() {
      return import('@/views/storeManagementPlat/classification/storeClassification');
    },
    name: 'StoreClassification',
    meta: {
      title: '门店分类',
      icon: 'peoples'
    }
  }, {
    path: 'storeAudit',
    component: function component() {
      return import('@/views/storeManagementPlat/storeAudit/storeAudit');
    },
    name: 'StoreAudit',
    meta: {
      title: '门店审核',
      icon: 'peoples'
    }
  }, {
    path: 'storeList',
    component: function component() {
      return import('@/views/storeManagementPlat/storeList/storeList');
    },
    name: 'StoreList',
    meta: {
      title: '门店列表',
      icon: 'peoples'
    }
  }, {
    path: 'storeRecord',
    component: function component() {
      return import('@/views/storeManagement/storeList/record');
    },
    name: 'StoreRecord',
    meta: {
      title: '门店流水',
      activeMenu: '/storeManagementPlat/storeList'
    },
    hidden: true
  }, {
    path: 'storeWithdraw',
    component: function component() {
      return import('@/views/storeManagement/storeList/record');
    },
    name: 'StoreWithdraw',
    meta: {
      title: '门店提现',
      activeMenu: '/storeManagementPlat/storeList'
    },
    hidden: true
  }, {
    path: 'editStore',
    component: function component() {
      return import('@/views/companyPlat/editStore');
    },
    name: 'EditStore',
    meta: {
      title: '门店编辑',
      activeMenu: '/storeManagementPlat/storeList'
    },
    hidden: true
  }, {
    path: 'storeInfo',
    component: function component() {
      return import('@/views/companyPlat/editStore');
    },
    name: 'StoreInfo',
    meta: {
      title: '门店详情',
      activeMenu: '/storeManagementPlat/storeList'
    },
    hidden: true
  }, {
    path: 'auditStore',
    component: function component() {
      return import('@/views/companyPlat/editStore');
    },
    name: 'AuditStore',
    meta: {
      title: '审核门店',
      noCache: true,
      isCache: true,
      activeMenu: '/storeManagementPlat/storeAudit'
    },
    hidden: true
  }, {
    path: 'auditDetail',
    component: function component() {
      return import('@/views/companyPlat/editStore');
    },
    name: 'StoreAuditDetail',
    meta: {
      title: '审核详情',
      activeMenu: '/storeManagementPlat/storeAudit'
    },
    hidden: true
  }, {
    path: 'serverChange',
    component: function component() {
      return import('@/views/storeManagementPlat/serverChange/list');
    },
    name: 'ServerChange',
    meta: {
      title: '手续费管理',
      icon: 'peoples'
    }
  }, {
    path: 'storeWithdrawalAudit',
    component: function component() {
      return import('@/views/storeManagementPlat/storeWithdrawal/storeWithdrawalAudit');
    },
    name: 'StoreWithdrawalAudit',
    meta: {
      title: '提现审核',
      icon: 'peoples'
    }
  }, {
    path: 'storeWithdrawSetting',
    component: function component() {
      return import('@/views/storeManagementPlat/storeWithdrawal/storeWithdrawSetting');
    },
    name: 'StoreWithdrawSetting',
    meta: {
      title: '提现设置',
      icon: 'peoples'
    }
  }, {
    path: 'serviceFacility',
    component: function component() {
      return import('@/views/storeManagementPlat/serviceFacility/serviceFacility');
    },
    name: 'ServiceFacility',
    meta: {
      title: '服务设施',
      icon: 'peoples'
    }
  }, // 门店订单跟商铺端共用同一个
  // {
  //   path: 'storesOrderPlat',
  //   component: () => import('@/views/storeManagement/storeOrder/list'),
  //   name: 'StoresOrder',
  //   meta: {
  //     title: '门店订单', icon: 'peoples'
  //     //,noCache: true
  //   }
  // },
  {
    path: 'storeOrderDetailPlat',
    component: function component() {
      return import('@/views/storeManagement/storeOrder/detail');
    },
    name: 'StoreOrderDetail',
    hidden: true,
    meta: {
      title: '订单详情',
      noCache: true,
      activeMenu: '/storeManagementPlat/storesOrderPlat'
    }
  }, {
    path: 'deliveryOrderPlat',
    component: function component() {
      return import('@/views/storeManagement/deliveryOrder/list');
    },
    name: 'DeliveryOrder',
    meta: {
      title: '配送订单',
      icon: 'peoples' //,noCache: true 

    }
  }, {
    path: 'storeDeliveryOrderDetailPlat',
    component: function component() {
      return import('@/views/storeManagement/deliveryOrder/detail');
    },
    name: 'StoreDeliveryOrderDetail',
    hidden: true,
    meta: {
      title: '订单详情',
      noCache: true,
      activeMenu: '/storeManagementPlat/deliveryOrderPlat'
    }
  }, {
    path: 'storePutawayAudit',
    component: function component() {
      return import('@/views/storeManagementPlat/storePutaway/storePutawayAudit');
    },
    name: 'StorePutawayAudit',
    meta: {
      title: '上架审核',
      icon: 'peoples'
    }
  }, {
    path: 'storeReviewDetails',
    component: function component() {
      return import('@/views/storeManagementPlat/storePutaway/storeReviewDetails');
    },
    name: 'StoreReviewDetails',
    hidden: true,
    meta: {
      title: '审核详情',
      icon: 'peoples'
    }
  }, {
    path: 'storeGoods',
    component: function component() {
      return import('@/views/storeManagementPlat/storeGoods/storeGoods');
    },
    name: 'StoreGoods',
    meta: {
      title: '门店商品',
      icon: 'peoples'
    }
  }, // views/storeManagementPlat/storeGoods/storeGoodsDetails
  {
    path: 'storeGoodsDetails',
    component: function component() {
      return import('@/views/storeManagement/goodsList/detail');
    },
    name: 'StoreGoodsDetails',
    hidden: true,
    meta: {
      title: '商品详情',
      icon: 'peoples',
      activeMenu: '/storeManagementPlat/storeGoods'
    }
  }, {
    path: 'StoreConmentListPlatPlat',
    component: function component() {
      return import('@/views/storeManagement/goodsComment/list');
    },
    name: 'StoreConmentListPlat',
    hidden: true,
    meta: {
      title: '商品评论',
      icon: 'list',
      activeMenu: '/storeManagementPlat/storeGoods'
    }
  }, {
    path: 'storeDeliveryGoodsDetailsPlat',
    component: function component() {
      return (// import  ('@/views/storeManagementPlat/storeGoods/storeDeliveryGoodsDetailsPlat'),
        import('@/views/storeManagementPlat/storeGoods/storeDeliveryGoodsDetails/details')
      );
    },
    name: 'StoreDeliveryGoodsDetailsPlat',
    hidden: true,
    meta: {
      title: '商品详情',
      icon: 'peoples',
      activeMenu: '/storeManagementPlat/storeGoods'
    }
  }, {
    path: 'storeOrderSnapshot',
    component: function component() {
      return import('@/views/storeManagementPlat/storeGoods/storeOrderSnapshot');
    },
    name: 'StoreOrderSnapshot',
    hidden: true,
    meta: {
      title: '交易快照',
      icon: 'peoples',
      activeMenu: '/storeManagementPlat/storesOrderPlat'
    }
  }, {
    path: 'storeDeliveryOrderSnapshot',
    component: function component() {
      return import('@/views/storeManagementPlat/storeGoods/storeDeliveryOrderSnapshot');
    },
    name: 'StoreDeliveryOrderSnapshot',
    hidden: true,
    meta: {
      title: '交易快照',
      icon: 'peoples',
      activeMenu: '/storeManagementPlat/deliveryOrderPlat'
    }
  }, {
    path: 'storeGoodsLog',
    component: function component() {
      return import('@/views/storeManagementPlat/storeGoods/storeGoodsLog');
    },
    name: 'storeGoodsLog',
    hidden: true,
    meta: {
      title: '商品日志',
      icon: 'peoples',
      activeMenu: '/storeManagementPlat/storeGoods'
    }
  }, {
    path: 'servePromise',
    component: function component() {
      return import('@/views/storeManagementPlat/servePromise/servePromise');
    },
    name: 'ServePromise',
    meta: {
      title: '服务承诺',
      icon: 'peoples'
    }
  }, {
    path: 'repealReason',
    component: function component() {
      return import('@/views/storeManagementPlat/repealReason/repealReason');
    },
    name: 'RepealReason',
    meta: {
      title: '撤销原因',
      icon: 'peoples'
    }
  }, {
    path: 'cancelCancel',
    component: function component() {
      return import('@/views/storeManagementPlat/cancelCancel/cancelCancel');
    },
    name: 'CancelCancel',
    meta: {
      title: '核销记录',
      icon: 'peoples'
    }
  }, {
    path: 'complaintReason',
    component: function component() {
      return import('@/views/storeManagementPlat/complaintReason');
    },
    name: 'ComplaintReason',
    meta: {
      title: '投诉原因',
      icon: 'peoples'
    }
  }, {
    path: 'merchantsComplaints',
    component: function component() {
      return import('@/views/storeManagementPlat/merchantsComplaints');
    },
    name: 'MerchantsComplaints',
    meta: {
      title: '商户投诉',
      icon: 'peoples'
    }
  }, {
    path: 'merchantsError',
    component: function component() {
      return import('@/views/storeManagementPlat/merchantsError');
    },
    name: 'MerchantsError',
    meta: {
      title: '商户报错',
      icon: 'peoples'
    }
  }, {
    path: 'hotCitySlot',
    component: function component() {
      return import('@/views/storeManagementPlat/hotCitySlot');
    },
    name: 'HotCitySlot',
    meta: {
      title: '热门城市',
      icon: 'peoples'
    }
  }, // 申诉原因 已创建新的至 评价管理-申诉类型  后续如不需要 可删除路由跟文件
  // {
  //     path: 'appealReason',
  //     component: () =>
  //         import ('@/views/storeManagementPlat/appealReason'),
  //     name: 'AppealReason',
  //     meta: { title: '申诉原因', icon: 'peoples' }
  // },
  // 回收站
  {
    path: 'storeRecycle',
    component: function component() {
      return import('@/views/storeManagementPlat/recycle/list');
    },
    name: 'StoreRecycle',
    meta: {
      title: '回收站',
      icon: 'peoples'
    }
  }]
};
export default storeManagementPlat;