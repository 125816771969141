// 客服列表
import request from '@/utils/request'; // 获取客服列表

export function getList(data) {
  return request({
    url: "/wechat/serviceInfo/queryListForCustomerService",
    method: 'post',
    data: data
  });
} // 门店列表

export function shopList(data) {
  return request({
    url: '/wechat/serviceInfo/shop/list',
    method: 'post',
    data: data
  });
} // 查询客服列表new

export function getServiceListNew(data) {
  return request({
    url: '/wechat/serviceInfo/listService',
    method: 'post',
    data: data
  });
} // 获取客服统计

function getServiceStat(params) {
  return request({
    url: '/wechat/recordSession/receptionCount',
    method: 'get',
    params: params
  });
} // 新增客服


function addService(data) {
  return request({
    url: '/wechat/serviceInfo/add',
    method: 'post',
    data: data
  });
} // 更新客服


function updateService(data) {
  return request({
    url: '/wechat/serviceInfo/update',
    method: 'post',
    data: data
  });
} // 删除


function deleteService(data) {
  return request({
    url: '/wechat/serviceInfo/' + data.id,
    method: 'delete',
    data: data
  });
} //获取客服设置


function getServiceSet(params) {
  return request({
    url: '/wechat/replyConfig/getReplyConfigList',
    method: 'get',
    params: params
  });
} //保存客服设置


function saveServiceSet(data) {
  return request({
    url: '/wechat/replyConfig/saveReplyConfig',
    method: 'post',
    data: data
  });
} //获取常用语列表数据


function getCommonSetData(params) {
  return request({
    url: '/wechat/chatFastReply/fastList',
    method: 'get',
    params: params
  });
} //获取常用语-分类下拉数据


function getCommonClassifyData(params) {
  return request({
    url: '/wechat/chatFastCatgory/getCatgoryList',
    method: 'get',
    params: params
  });
} //保存常用语数据


function saveCommonSet(data) {
  return request({
    url: '/wechat/chatFastReply/saveFastList',
    method: 'post',
    data: data
  });
} //保存常用语数据


function delCommonSet(data) {
  return request({
    url: '/wechat/chatFastReply/' + data.id,
    method: 'delete',
    data: data
  });
} //保存或者更新快速回复分类


function saveCatgory(data) {
  return request({
    url: '/wechat/chatFastCatgory/saveCatgory',
    method: 'post',
    data: data
  });
} //获取会话记录列表


function getSessionList(params) {
  return request({
    url: '/wechat/recordSession/recordList',
    method: 'get',
    params: params
  });
} //获取会话记录列表-会话详情


function getMessageData(params) {
  return request({
    url: '/wechat/message/getMsgList',
    method: 'get',
    params: params
  });
} //获取会话记录列表-会话详情


function getRecordStat(params) {
  return request({
    url: '/wechat/recordSession/receptionCount',
    method: 'get',
    params: params
  });
} //发送消息接口


function sendMsg(data) {
  return request({
    url: '/wechat/message/sendMsg',
    method: 'post',
    data: data
  });
} // 更新客服会话状态


function updateSession(data) {
  return request({
    url: '/wechat/recordSession/updateSession',
    method: 'post',
    data: data
  });
} // 根据商铺ID,查询客服信息


function getUserInfoByStoreId(params) {
  return request({
    url: '/wechat/serviceInfo/getUserInfoByStoreId',
    method: 'get',
    params: params
  });
} // 获取指定商户的分类和回复内容


function getCatContentList(params) {
  return request({
    url: '/wechat/chatFastCatgory/getCatContentList',
    method: 'get',
    params: params
  });
} // 更新客服列表中的在线状态


function updateOnline(params) {
  return request({
    url: '/wechat/serviceInfo/online/' + params.userId,
    method: 'get',
    params: params
  });
} // 根据用户获取商铺id


function getShopIdByUserId(params) {
  return request({
    url: '/wechat/serviceInfo/getShopId/' + params.userId,
    method: 'get',
    params: params
  });
} // 导出会话消息列表


function exportFastBySessionId(params) {
  return request({
    url: '/wechat/recordSession/exportFast/' + params.sessionId,
    method: 'get',
    params: params
  });
} // 客服重新开始会话


function startSession(data) {
  return request({
    url: '/wechat/recordSession/addSession',
    method: 'post',
    data: data
  });
} // 发送结束语消息


function sendEndMsg(data) {
  return request({
    url: '/wechat/message/sendEndMsg',
    method: 'post',
    data: data
  });
} // 删除快速回复分类


function deleteChatFastCatgory(data) {
  return request({
    url: '/wechat/chatFastCatgory/' + data.id,
    method: 'delete',
    data: data
  });
} // 置顶快速回复


function chatFastReplyTopping(id) {
  var data = {};
  return request({
    url: '/wechat/chatFastReply/topping?id=' + id,
    method: 'post',
    data: data
  });
} //置顶分类


function chatFastCatgoryTopping(id) {
  var data = {};
  return request({
    url: '/wechat/chatFastCatgory/topping?id=' + id,
    method: 'post',
    data: data
  });
}

var service = {
  shopList: shopList,
  chatFastReplyTopping: chatFastReplyTopping,
  chatFastCatgoryTopping: chatFastCatgoryTopping,
  // getServiceList: getServiceList,
  getServiceListNew: getServiceListNew,
  addService: addService,
  updateService: updateService,
  deleteService: deleteService,
  getServiceStat: getServiceStat,
  getServiceSet: getServiceSet,
  saveServiceSet: saveServiceSet,
  getCommonSetData: getCommonSetData,
  getCommonClassifyData: getCommonClassifyData,
  saveCommonSet: saveCommonSet,
  delCommonSet: delCommonSet,
  saveCatgory: saveCatgory,
  getSessionList: getSessionList,
  getMessageData: getMessageData,
  getRecordStat: getRecordStat,
  getUserInfoByStoreId: getUserInfoByStoreId,
  sendMsg: sendMsg,
  updateSession: updateSession,
  getCatContentList: getCatContentList,
  updateOnline: updateOnline,
  getShopIdByUserId: getShopIdByUserId,
  exportFastBySessionId: exportFastBySessionId,
  startSession: startSession,
  sendEndMsg: sendEndMsg,
  deleteChatFastCatgory: deleteChatFastCatgory
};
export default service;