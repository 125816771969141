//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'thTips',
  data: function data() {
    return {};
  },
  props: {
    messages: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  }
};