//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'toPad',
  components: {},
  props: {
    /*
    
    {
      left:{
        c1:'',
        c2:'',
        c3:'',
      },
      center:{
        c1:'',
        c2:'',
      },
      right:{
        c1:'',
        c2:'',
        c3:''
      }
    }
    
    */
    data: {
      type: Object,
      default: {
        left: {
          c1: '',
          c2: '',
          c3: ''
        },
        center: {
          c1: '',
          c2: ''
        },
        right: {
          c1: '',
          c2: '',
          c3: ''
        }
      }
    }
  },
  data: function data() {
    return {};
  },
  methods: {}
};