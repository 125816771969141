/** When your routing table is too long, you can split it into small modules **/
// 商品管理 - 商铺
import Layout from '@/layout';
var productRouter = {
  path: '/product',
  component: Layout,
  redirect: '/product/index',
  alwaysShow: true,
  name: 'Product',
  meta: {
    title: '商品管理',
    icon: 'monitor'
  },
  children: [{
    path: '/product/index',
    component: function component() {
      return import('@/views/product/list/index');
    },
    name: 'ProductList',
    meta: {
      title: '商品列表',
      icon: 'list',
      noCache: true,
      isCache: true
    }
  }, {
    path: '/product/addProduct',
    component: function component() {
      return import('@/views/product/list/addProduct');
    },
    name: 'AddProduct',
    hidden: true,
    meta: {
      title: '新增商品',
      activeMenu: '/product/index'
    }
  }, ///*
  {
    path: '/product/updateProduct',
    component: function component() {
      return import('@/views/product/list/updateProduct');
    },
    name: 'UpdateProduct',
    hidden: true,
    meta: {
      title: '编辑商品',
      activeMenu: '/product/index',
      noCache: true,
      isCache: true
    }
  }, //*/
  {
    path: '/product/detailsProduct',
    component: function component() {
      return import('@/views/product/list/detailsProduct');
    },
    name: 'DetailsProduct',
    hidden: true,
    meta: {
      title: '商品详情',
      activeMenu: '/product/index',
      noCache: true
    }
  }, // {
  //   path: '/product/detailsProduct',
  //   component: () =>
  //     import('@/views/product/list/component/details-product-props'),
  //   name: 'DetailsProduct',
  //   hidden: false,
  //   meta: {
  //     title: '（商品详情）'
  //   }
  // },
  {
    path: '/product/approvedList',
    component: function component() {
      return import('@/views/product/brand/approvedList');
    },
    name: 'ApprovedList',
    meta: {
      title: '品牌申请',
      icon: 'edit'
    }
  }, {
    path: '/product/brandApply',
    component: function component() {
      return import('@/views/product/brand/brandApply');
    },
    name: 'BrandApply',
    hidden: true,
    meta: {
      title: '（新增品牌）',
      activeMenu: '/product/approvedList'
    }
  }, {
    path: '/product/editBrand',
    component: function component() {
      return import('@/views/product/brand/brandApply');
    },
    name: 'ProductEditBrand',
    hidden: true,
    meta: {
      title: '编辑品牌',
      activeMenu: '/product/approvedList'
    }
  }, {
    path: '/product/recordList',
    component: function component() {
      return import('@/views/product/record/recordList');
    },
    name: 'ProductRecordList',
    meta: {
      title: '上架申请',
      icon: 'list'
    }
  }, {
    path: '/product/upProduct',
    component: function component() {
      return import('@/views/product/record/upProduct');
    },
    name: 'UpProduct',
    hidden: true,
    meta: {
      title: '（商品上架）',
      activeMenu: '/product/recordList',
      noCache: true,
      isCache: true
    }
  }, {
    path: '/product/reviewDetails',
    component: function component() {
      return import('@/views/product/record/reviewDetails');
    },
    name: 'ReviewDetails',
    hidden: true,
    meta: {
      title: '（审核详情）',
      activeMenu: '/product/recordList',
      noCache: true
    }
  }, {
    path: '/product/takeDown',
    component: function component() {
      return import('@/views/product/takeDown/index');
    },
    name: 'ProductTakeDown',
    meta: {
      title: '申请下架',
      icon: 'edit'
    }
  }, // 废弃
  // {
  //     path: '/product/appealList',
  //     component: () =>
  //         import ('@/views/product/appraise/appealList'),
  //     name: 'AppealList',
  //     hidden: true,
  //     meta: {
  //         title: '（评价申诉）',
  //         activeMenu: '/product/appraise'
  //     }
  // },
  {
    path: '/product/drafts',
    component: function component() {
      return import('@/views/product/drafts/index');
    },
    name: 'Drafts',
    meta: {
      title: '草稿箱',
      icon: 'tab'
    }
  }, {
    path: '/product/recycle',
    component: function component() {
      return import('@/views/product/recycle/index');
    },
    name: 'Recycle',
    meta: {
      title: '回收站',
      icon: 'email'
    }
  }, {
    path: '/product/log',
    component: function component() {
      return import('@/views/product/log/index');
    },
    name: 'Log',
    hidden: true,
    meta: {
      title: '（商品日志）'
    }
  }, {
    path: '/product/stock',
    component: function component() {
      return import('@/views/product/stock/stockList');
    },
    name: 'Stock',
    meta: {
      title: '库存预警',
      icon: 'list',
      AttC: 0
    }
  } //   // 预警设置
  //   {
  //     path: '/product/stockWarining',
  //     component: () =>
  //         import ('@/views/product/stock/setWarning'),
  //     name: 'productSetWarning',
  //     meta: {
  //         title: '预警设置',
  //         icon: 'peoples',
  //         activeMenu: '/product/stock'
  //     },
  //     hidden: true
  // }
  ]
};
export default productRouter;