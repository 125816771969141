// 站长管理-提现列表
import Layout from '@/layout';
var needwithdrawRouter = {
  path: '/needwithdraw',
  component: Layout,
  redirect: '/needwithdraw/collectwithdrawdeposit',
  name: 'Needwithdraw',
  meta: {
    title: '提现列表',
    icon: 'role'
  },
  children: [{
    path: 'collectwithdrawdeposit',
    component: function component() {
      return import('@/views/needwithdraw/withdrawdeposit.vue');
    },
    name: 'Collectwithdrawdeposit',
    meta: {
      title: '提现列表',
      icon: 'peoples'
    }
  }, {
    path: 'collectgatheringaccount',
    component: function component() {
      return import('@/views/needwithdraw/gatheringaccount.vue');
    },
    name: 'Collectgatheringaccount',
    meta: {
      title: '收款账号',
      noCache: true,
      activeMenu: '/needwithdraw/collectwithdrawdeposit'
    },
    hidden: true
  }]
};
export default needwithdrawRouter;