import _objectSpread from "/root/.jenkins/workspace/yuexin_vue/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import _variables from '@/styles/variables.scss';
import { getCount } from '@/api/product/stock';
import { getStockCount } from '@/api/storeManagement/goodsList/fetch';
import { getStore, getToken } from '@/utils/auth';
export default {
  components: {
    SidebarItem: SidebarItem,
    Logo: Logo
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['permission_routes', 'sidebar'])), {}, {
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
          path = route.path; // if set path, the sidebar will highlight the path you set

      if (meta.activeMenu) {
        return meta.activeMenu;
      }

      return path;
    },
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables: function variables() {
      return _variables;
    },
    isCollapse: function isCollapse() {
      console.log(this.sidebar.opened);
      return !this.sidebar.opened;
    }
  }),
  created: function created() {
    console.log(this.sidebar);
    this.handleSelect();
  },
  methods: {
    handleSelect: function handleSelect(e) {
      var _this = this;

      var userInfo = JSON.parse(getStore('userInfo'));
    }
  }
};