//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'itemPhone',
  components: {},
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    goDetails: function goDetails(id) {
      this.$router.push({
        path: '/orderPlat/phoneDetail',
        query: {
          id: id
        }
      });
    }
  },
  filters: {
    /* 时间转换 */
    timeTranHM: function timeTranHM(value) {
      return !value ? '-' : tool.date('Y-m-d H:i', value);
    }
  }
};