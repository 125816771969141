//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import ChatItem from "./chatItem";
import defaultSettings from '@/settings';
import { getList } from '@/api/serviceManagement/serviceList';
var appkey = defaultSettings.ServiceChatAppKey;
export default {
  name: 'Chat',
  components: {
    ChatItem: ChatItem
  },
  props: [],
  filters: {},
  directives: {},
  data: function data() {
    return {
      activeName: 0,
      shopId: null,
      shopType: null,
      serviceUserId: null,
      map: {},
      listChat: [] //null, '1438688139453874177'

    };
  },
  created: function created() {
    this.listFun();
  },
  mounted: function mounted() {},
  computed: {
    listArr: {
      get: function get() {
        // console.log("onlineState get:",this.$store.state)
        return this.$store.state.chat.unReadArr;
      },
      set: function set(value) {
        this.$store.commit('chat/set_read_num', value);
      }
    }
  },
  methods: {
    listFun: function listFun() {
      var _this = this;

      getList({
        enterpriseId: this.getShopId('enterpriseId')
      }).then(function (res) {
        if (res.code = '000000') {
          console.log(res.data);

          if (res.data.length > 0) {
            _this.listChat = res.data;
            _this.shopId = res.data[0].id;
            _this.shopType = res.data[0].shopType;
            _this.serviceUserId = res.data[0].serviceUserId;

            _this.$store.commit('chat/set_read_lenth', _this.listChat.length);

            for (var i = 0; i < _this.listChat.length; i++) {
              _this.map[i] = RongIMLib.init(appkey);
            }
          }
        }
      });
    },
    close: function close() {
      this.$parent.chatShow = false;
    },
    handleClick: function handleClick(i, id, shopType, serviceUserId) {
      this.shopId = id;
      this.shopType = shopType;
      this.activeName = i;
      this.serviceUserId = serviceUserId;
      var ref = eval("this.$refs.serviceChat" + i)[0];
      ref.openList();
    }
  }
};