/** When your routing table is too long, you can split it into small modules **/
// 发现
import Layout from '@/layout';
var findRouter = {
  path: '/find',
  component: Layout,
  redirect: '/find/set',
  name: 'Find',
  meta: {
    title: '发现管理',
    icon: 'tree'
  },
  children: [{
    path: '/find/set',
    component: function component() {
      return import('@/views/find');
    },
    name: 'FindSet',
    meta: {
      title: '发现设置',
      icon: 'tree'
    }
  }, {
    path: '/find/content',
    component: function component() {
      return import('@/views/find/classify/content');
    },
    name: 'Classify',
    meta: {
      title: '内容分类',
      icon: 'tree'
    }
  }, {
    path: '/find/contentList/index',
    component: function component() {
      return import('@/views/find/contentList/index');
    },
    name: 'ContentList',
    meta: {
      title: '内容列表'
    }
  }, {
    path: '/find/contentList/appealReviewList',
    component: function component() {
      return import('@/views/find/contentList/appealReviewList');
    },
    name: 'AppealReviewList',
    meta: {
      title: '申诉审核'
    }
  }, {
    path: '/find/contentList/AppealReviewDetails',
    component: function component() {
      return import('@/views/find/contentList/appealReviewDetails');
    },
    name: 'AppealReviewDetails',
    hidden: true,
    meta: {
      title: '申诉审核-详情'
    }
  }, {
    path: '/find/contentList/audit',
    component: function component() {
      return import('@/views/find/contentAuditList/index');
    },
    name: 'ContentAuditList',
    meta: {
      title: '内容审核'
    }
  }, {
    path: '/find/contentList/auditDetails',
    component: function component() {
      return import('@/views/find/contentAuditList/contentAudit');
    },
    name: 'ContentAudit',
    hidden: true,
    meta: {
      title: '审核详情',
      noCache: true
    }
  }, //鑫首列表
  {
    path: '/find/listRelease',
    component: function component() {
      return import('@/views/find/listRelease/index');
    },
    name: 'listRelease',
    meta: {
      title: '鑫首列表',
      icon: 'tree'
    }
  }, {
    path: '/find/violations/list',
    component: function component() {
      return import('@/views/find/violations/index');
    },
    name: 'Violations',
    meta: {
      title: '违规记录'
    }
  }, {
    path: '/find/violations/details',
    component: function component() {
      return import('@/views/find/violations/details');
    },
    name: 'ViolationsDetails',
    hidden: true,
    meta: {
      title: '违规详情',
      noCache: true
    }
  }, {
    path: '/find/like',
    component: function component() {
      return import('@/views/find/like');
    },
    name: 'Like',
    hidden: true,
    meta: {
      title: '点赞列表'
    }
  }, {
    path: '/find/comment',
    component: function component() {
      return import('@/views/find/comment/comment');
    },
    name: 'Comment',
    meta: {
      title: '内容评论',
      icon: 'tree'
    }
  }, {
    path: '/find/complaintsReport',
    component: function component() {
      return import('@/views/find/complaintsReport/list');
    },
    name: 'ComplaintsReport',
    meta: {
      title: '投诉举报',
      noCache: true,
      icon: 'tree'
    }
  }, {
    path: '/find/complaintsReportDetail',
    component: function component() {
      return import('@/views/find/complaintsReport/detail');
    },
    name: 'ComplaintsReportDetailPlat',
    meta: {
      title: '举报明细列表',
      icon: 'tree',
      noCache: true,
      activeMenu: '/find/complaintsReport/list'
    },
    hidden: true
  }, {
    path: '/find/report',
    component: function component() {
      return import('@/views/find/report/list');
    },
    name: 'Report',
    meta: {
      title: '举报类型',
      icon: 'tree'
    }
  }, {
    path: '/find/lexicon',
    component: function component() {
      return import('@/views/find/lexicon/list');
    },
    name: 'Lexicon',
    meta: {
      title: '违禁词库',
      icon: 'tree'
    }
  }, {
    path: '/find/blacklist',
    component: function component() {
      return import('@/views/find/blacklist/list');
    },
    name: 'Blacklist',
    meta: {
      title: '拉黑管理',
      icon: 'tree',
      noCache: true
    }
  }, {
    path: '/find/musicList',
    component: function component() {
      return import('@/views/find/musicList/list');
    },
    name: 'MusicList',
    meta: {
      title: '音乐库',
      icon: 'tree'
    }
  }, {
    path: '/find/addMusic',
    component: function component() {
      return import('@/views/find/musicList/addMusic');
    },
    name: 'AddMusic',
    hidden: true,
    meta: {
      title: '新增/修改',
      icon: 'tree',
      isCache: true,
      noCache: true
    }
  }, {
    path: '/find/musicType',
    component: function component() {
      return import('@/views/find/musicList/musicType');
    },
    name: 'MusicType',
    meta: {
      title: '音乐类型',
      icon: 'tree'
    }
  }, {
    path: '/find/musicLabel',
    component: function component() {
      return import('@/views/find/musicList/musicLabel');
    },
    name: 'MusicLabel',
    meta: {
      title: '音乐标签',
      icon: 'tree'
    }
  }]
};
export default findRouter;