import "core-js/modules/es6.regexp.replace";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
export default {
  name: 'm-audio',
  props: {
    src: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ""
    },
    showDuration: {
      type: Boolean,
      default: true
    },
    block: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      audio: null,
      animate: false,
      timer: null,
      duration: null
    };
  },
  mounted: function mounted() {
    var _this = this;

    if (!window.audioList) {
      window.audioList = [];
    }

    this.audio = new Audio();
    this.audio.src = this.src;
    this.audio.addEventListener('canplaythrough', function () {
      _this.duration = _this.format(_this.audio.duration);
    });

    this.audio.onplay = function () {
      _this.animate = true;
      _this.timer = setInterval(function () {
        _this.animate = false;
        setTimeout(function () {
          _this.animate = true;
        }, 50);
      }, 1250);
    };

    this.audio.onpause = function () {
      _this.animate = false;
      _this.timer && clearInterval(_this.timer);
    };

    this.audio.onended = function () {
      _this.animate = false;
      _this.timer && clearInterval(_this.timer);
    };

    window.audioList.push(this.audio); //所有实例加入全局变量
  },
  methods: {
    play: function play() {
      window.audioList.forEach(function (audio) {
        //开始前先关闭所有可能正在运行的实例
        audio.pause();
      });
      console.log(this.audio.paused, this.animate);

      if (!this.animate) {
        this.audio.play();
        this.animate = true;
        console.log("play");
      } else {
        this.animate = false;
        this.audio.pause();
        console.log("pause");
      }
    },
    format: function format(s) {
      var t = '';

      if (s > -1) {
        var min = Math.floor(s / 60) % 60;
        var sec = s % 60;

        if (min < 10) {
          t += "0";
        }

        t += min + "'";

        if (sec < 10) {
          t += "0";
        }

        t += sec.toFixed(2);
      }

      t = t.replace('.', '\"');
      return t;
    }
  }
};