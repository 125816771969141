// /** When your routing table is too long, you can split it into small modules**/
// // 直播数据列表 -平台
// import Layout from '@/layout'
// const mediaRouter = {
//   path: '/live',
//   component: Layout,
//   redirect: '/live/companylist',
//   name: 'Live',
//   meta: {
//     title: '直播数据列表-平台',
//     icon: 'component'
//   },
//   children: [
//     {
//       path: '/live/companylist',
//       component: () => import('@/views/live/companylist/companylist'),
//       name: 'Companylist',
//       meta: {
//         title: '企业列表',
//         icon: 'peoples'
//       }
//     },
//     {
//       path: '/live/cameralist',
//       component: () => import('@/views/live/cameralist/cameralist'),
//       name: 'Cameralist',
//       meta: {
//         title: '摄像头监控列表',
//         icon: 'role'
//       }
//     },
//     {
//       path: '/live/webPullUrl',
//       component: () => import('@/views/live/cameralist/webPullUrl'),
//       hidden: true,
//       name: 'WebPullUrl',
//       meta: {
//         title: '直播监控视频',
//         icon: 'role'
//       }
//     }
//   ]
// }
// export default mediaRouter