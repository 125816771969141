import _MessageBox2 from "element-ui/lib/message-box";
import _MessageBox from "element-ui/lib/message-box";
import _Message2 from "element-ui/lib/message";
import _Message from "element-ui/lib/message";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import axios from 'axios';
import store from '@/store'; // import { setToken, getToken, SetSessionStorage, GetSessionStorage } from '@/utils/auth'

import { getToken } from '@/utils/auth'; // import { compactObj, isEmpty, strIshaveNumber } from '@/utils/index.js'

import { strIshaveNumber } from '@/utils/index.js';
import aesEncrypt from '@/utils/aes.js';
import defualtSettings from '@/settings';
import qs from 'qs';

require("./project-base-data");

require("./extends"); // create an axios instance


var service = axios.create({
  baseURL: defualtSettings.axiosBaseURL,
  // url = base url + request url
  withCredentials: false // send cookies when cross-domain requests
  // timeout: 5000 // request timeout

}); // 请求拦截

service.interceptors.request.use(function (config) {
  // do something before request is sent
  if (store.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    var token = getToken();

    if (!token) {
      // token="Basic YXBwX2NsaWVudDp0ZXN0X3NlY3JldA==" //手机聊天界面使用
      token = "Basic dGVzdF9jbGllbnQ6dGVzdF9zZWNyZXQ=";
    } else {
      token = 'Bearer ' + token;
    }

    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    config.headers['Authorization'] = token;
    config.headers['Access-Control-Allow-Origin'] = '*';
  }

  config.headers['Content-Type'] = config.dataType || "application/json";

  if (config.method === 'get') {
    config.paramsSerializer = function (params) {
      return qs.stringify(params, {
        arrayFormat: 'comma'
      });
    };
  }

  return config;
}, function (error) {
  // do something with request error
  console.log(":{  ", error); // for debug

  return Promise.reject(error);
}); // 响应拦截

service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
 */

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data; // console.log("response.status:",response.status)
  // console.log("请求响应拦截", response)
  // console.log('响应时间', getFormatDate(response.data.time))
  // console.log('RefreshTime', GetSessionStorage('RefreshTime') || getFormatDate())
  // var differtime = intervalTime(GetSessionStorage('RefreshTime') || getFormatDate(), getFormatDate(response.data.time))
  // console.log(differtime)
  // 5400-30 7200-50
  // if (differtime > 5400 && differtime < 7200) {
  //   // console.log('刷新token');
  //   SetSessionStorage('RefreshTime', getFormatDate())
  //   RefreshToken(GetSessionStorage('refresh_token'))
  //     .then(response => {
  //       console.log('刷新token', response)
  //       if (
  //         response.data &&
  //         response.data.access_token &&
  //         response.data.refresh_token
  //       ) {
  //         setToken(response.data.access_token)
  //         SetSessionStorage('refresh_token', response.data.refresh_token)
  //       }
  //     })
  //     .catch(accident => {
  //       console.log(accident)
  //     })
  // } else if (differtime > 7200) {
  //   console.log('跳转登陆页面')
  //   MessageBox.confirm(
  //     'token令牌已经过期,您可以取消以继续停留在此页面,或重新登录.',
  //     '提示',
  //     {
  //       confirmButtonText: '去登录',
  //       cancelButtonText: '取消',
  //       type: 'warning'
  //     }
  //   ).then(() => {
  //       store.dispatch('user/resetToken').then(() => {
  //         location.reload()
  //       })
  //     })
  //     .catch(() => {
  //       store.dispatch('user/resetToken')
  //     })
  // } else {
  //   console.log('token正常', differtime + '秒')
  // }
  // if the custom code is not 20000, it is judged as an error.
  // if (response.status !== 200) {
  // console.log("kkkk:",response,response.status,response.data.code,response.data.mesg,response.status==200&&response.data.code==500&&response.data.mesg=="系统升级中")

  if (![200, 201].includes(response.status)) {
    _Message({
      message: response.mesg || 'Error',
      type: 'error',
      duration: 5 * 1000
    }); // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    // if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
    //   // to re-login
    //   MessageBox.confirm(
    //     'You have been logged out, you can cancel to stay on this page, or log in again',
    //     'Confirm logout',
    //     { 
    //       confirmButtonText: '去登录',
    //       cancelButtonText: '取消',
    //       type: 'warning'
    //     }
    //   ).then(() => {
    //     store.dispatch('user/resetToken').then(() => {
    //       location.reload()
    //     })
    //   })
    // }


    return Promise.reject(new Error(res.mesg || 'Error'));
  } else {
    if (response.status == 200 && response.data.code == 500 && response.data.mesg == "系统升级中") {
      // setTimeout(()=>{window.location.href="/#/login"},2000)
      if (APP_ENV != 'development') {
        setTimeout(function () {
          window.location.href = "/#/login";
          store.dispatch('user/logout');
        }, 2000);
      }
    } else {} // if(res.code!="000000"){
    //   let message='Error';
    //   if(res){
    //     message=res.mesg||res.message
    //   }
    //   Message({
    //     message,
    //     type: 'error',
    //     duration: 5 * 1000
    //   })
    //   return Promise.reject(new Error(message))
    // }else{
    //   return res
    // }


    return res;
  }
}, function (error) {
  // console.log("error.response.status:",error.response.status)
  if (!error.response) {
    return Promise.reject(new Error('服务器失联，请与管理员联系。'));
  }

  if (error.response.status === 500) {
    // console.log("500 error.response:",error.response)
    var message = "服务器发生错误[500]，请与管理联系";
    console.log(error.response);

    _Message({
      dangerouslyUseHTMLString: true,
      message: message,
      type: 'error',
      duration: 5 * 1000
    });
  }

  if (error.response.status === 401) {
    if (window.sessionStorage.getItem('tokenInvalid') == 0) {
      window.sessionStorage.setItem('tokenInvalid', 1);

      _MessageBox.confirm('token令牌已经过期,您可以取消以继续停留在此页面,或重新登录.', '提示', {
        confirmButtonText: '去登录',
        cancelButtonText: '取消',
        customClass: 'msgbox',
        type: 'warning'
      }).then(function () {
        /*store.dispatch('user/resetToken').then(() => {
          location.reload()
        })*/
        store.dispatch('user/logout'); // window.location.href="/#/login"

        window.ocation.reload();
      }).catch(function () {
        // store.dispatch('user/resetToken')
        window.location.href = "/#/login";
        store.dispatch('user/logout');
      });
    }
  }

  return Promise.reject(error);
});
export default service;