// 评价管理-平台
import Layout from '@/layout';
var appraiseManagePlat = {
  path: '',
  component: Layout,
  redirect: '/appraiseManage/CommodityEvaluation',
  alwaysShow: true,
  name: 'AppraiseManagePlat',
  meta: {
    title: '评价管理',
    icon: 'role'
  },
  children: [{
    path: '/appraiseManage/appealType',
    component: function component() {
      return import('@/views/apptaiseManage/appealType');
    },
    name: 'AppealType',
    meta: {
      title: '申诉类型',
      icon: 'peoples'
    }
  }, {
    path: '/appraiseManage/CommodityEvaluation',
    component: function component() {
      return import('@/views/storeManagementPlat/commodityEvaluation/commodityEvaluation');
    },
    name: 'CommodityEvaluation',
    meta: {
      title: '门店商品',
      icon: 'peoples'
    }
  }, {
    path: '/appraiseManage/tagsList',
    component: function component() {
      return import('@/views/storeManagementPlat/commodityEvaluation/tagsList');
    },
    name: 'TagsList',
    hidden: true,
    meta: {
      title: '点赞列表',
      icon: 'peoples'
    }
  }, {
    path: '/appraiseManage/commentDetails',
    component: function component() {
      return import('@/views/storeManagementPlat/commodityEvaluation/commentDetails');
    },
    name: 'CommentDetails',
    hidden: true,
    meta: {
      title: '评论详情',
      icon: 'peoples',
      activeMenu: '/appraiseManage/commodityEvaluation'
    }
  }, {
    path: '/productPlat/appraise',
    component: function component() {
      return import('@/views/productPlat/appraise/index');
    },
    name: 'ProductPlatAppraise',
    meta: {
      title: '商铺商品',
      icon: 'clipboard'
    }
  }, {
    path: '/productPlat/tagsList',
    component: function component() {
      return import('@/views/storeManagementPlat/commodityEvaluation/tagsList');
    },
    name: 'ProductPlatTagsList',
    hidden: true,
    meta: {
      title: '点赞列表',
      icon: 'peoples',
      activeMenu: '/productPlat/appraise'
    }
  }, {
    path: '/productPlat/commentDetails',
    component: function component() {
      return import('@/views/storeManagementPlat/commodityEvaluation/commentDetails');
    },
    name: 'PlatCommentDetails',
    hidden: true,
    meta: {
      title: '评论详情',
      noCache: true,
      icon: 'peoples',
      activeMenu: '/productPlat/appraise'
    }
  }, // 评价申诉 
  {
    path: '/storeManagementPlat/storeAppeal',
    component: function component() {
      return import('@/views/storeManagementPlat/storeAppeal/list');
    },
    name: 'StoreAppeal',
    meta: {
      title: '评价申诉',
      icon: 'peoples'
    }
  }, // 商铺售后评价
  {
    path: '/apptaiseManagePlat/storeAfterSale',
    component: function component() {
      return import('@/views/apptaiseManage/storeAfterSale');
    },
    name: 'StoreAfterSalePlat',
    meta: {
      title: '商铺售后',
      icon: 'peoples'
    }
  }]
};
export default appraiseManagePlat;