import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
Vue.use(Router);
/* Layout */

import Layout from '@/layout'; // 首页、交易数据中心

import platHomeRouter from "./modules/platHome";
import platStatisticsRouter from "./modules/platStatistics";
import shopHomeRouter from "./modules/shopHome";
import storeHomeRouter from "./modules/storeHome";
import shopStatisticsRouter from "./modules/shopStatistics";
import agencyHomeRouter from "./modules/agencyHome";
import attractHomeRouter from "./modules/attractHome";
/* Router Modules */

import organizationRouter from "./modules/organization";
import orderRouter from "./modules/order"; // import planeRouter from './modules/plane'
//企业管理-平台

import companyPlatRouter from "./modules/companyPlat"; //企业管理-商铺

import companyRouter from "./modules/company";
import orderServiceRouter from "./modules/orderService";
import orderPlatRouter from "./modules/orderPlat";
import orderServicePlatRouter from "./modules/orderServicePlat";
import mediaRouter from "./modules/media";
import medianewRouter from "./modules/medianew";
import mediaFirmRouter from "./modules/mediaBussinessMgt";
import liveRouter from "./modules/live"; // 页面路由

import vipManagementRouter from "./modules/vipManagement";
import shopmanageRouter from "./modules/shopmanage";
import agencyRouter from "./modules/agency";
import contentPlatRouter from "./modules/contentPlat";
import couponPlatRouter from "./modules/couponPlat";
import productRouter from "./modules/product";
import productPlatRouter from "./modules/productPlat";
import expressRouter from "./modules/express";
import findRouter from "./modules/find"; //站长管理

import find2Router from "./modules/find2";
import messageManagementRouter from "./modules/messageManagement";
import servicePlatRouter from "./modules/servicePlat";
import storeManagement from "./modules/storeManagement";
import storeManagementPlat from "./modules/storeManagementPlat"; // 企业客户池

import customerRouter from "./modules/custormer"; // 官网后台

import webAdminRouter from "./modules/custIn"; // 运营管理

import operationRouter from "./modules/operation";
import marketingactivityRouter from "./modules/marketingactivity";
import shopsmanageterraceRouter from "./modules/shopsmanageterrace"; //门店运营

import shopoperationRouter from "./modules/shopoperation";
import shopModulePlatformRouter from "./modules/shopModulePlatform";
import agentRouter from "./modules/agentPlat";
import agentUserRouter from "./modules/agentUserRouter"; //客服管理-商铺端

import serviceRouter from "./modules/service";
import agencymanagementRouter from "./modules/agencymanagement"; // 招商专员-提现列表

import attractcollectRouter from "./modules/attractcollect"; // 站长管理-提现列表

import needwithdrawRouter from "./modules/needwithdraw"; // 招商专员-商铺列表

import attractpersonnelRouter from "./modules/attractpersonnel"; //招商专员-企业列表

import attractCompanyRouter from "./modules/attractCompany"; //招商专员-企业审核

import attractEnterpriseAudit from "./modules/attractEnterpriseAudit"; // 站长管理-商铺列表

import replacemanageRouter from "./modules/replacemanage"; // 个人中心

import personalCenterRouter from "./modules/personalCenter"; // 钱包-平台端

import walletPlatRouter from "./modules/walletPlat"; //商家互动-平台端

import merchantInteractionRouter from "./modules/merchantInteraction"; //商家互动-企业端

import merchantInteractionPlatRouter from "./modules/merchantInteractionPlat"; // 批发管理

import WholesaleManagement from "./modules/wholesaleMan"; // import StoreAftermarket from './modules/walletPlat'
// 钱包-企业后台

import purseRouter from "./modules/purse"; // 站长后台-站长管理

import agencyMangerRouter from "./modules/agencyManger";
import attractWalletRouter from "./modules/attractWallet"; //平台和企业端 -溯源

import traceabilityPlatRouter from "./modules/traceabilityPlat";
import traceabilityRouter from "./modules/traceability"; // 平台端- 搜索

import searchPlatRouter from "./modules/search"; // 平台端 - 鑫头条

import headLineRouter from "./modules/headLine"; // 平台端-评价管理

import appraiseManagePlatRouter from "./modules/appraiseManagePlat"; // 企业端-评价管理

import appraiseManageRouter from "./modules/appraiseManage";
import articleManagementRouter from "./modules/articleManagement";
import articleManagementPlatRouter from "./modules/articleManagementPlat";
/**
*@description:官网后台路由
*/
//用户管理
// import userManagement from './modules/userManagement'
//文章管理
// import articleManagementWeb from '@/router/modules/articleManagement-web'
//轮播管理
// import rotationManagement from '@/router/modules/rotationManagement'
//留言管理
// import webMessageManagement from '@/router/modules/webMessageManagement'
//站点管理
// import sizeAdministration from '@/router/modules/siteAdministration'

/**
 * constantRoutes
 * 没有权限要求的基本页
 * 所有角色都可以访问
 * 不需要动态判断权限的路由
 */

/**
 * asyncRoutes
 * 需要根据用户角色动态加载的路由
 */

export var constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/msgList',
  component: function component() {
    return import('@/views/chatPage/index');
  },
  hidden: true
}, {
  path: '/webChat',
  component: function component() {
    return import('@/views/chatPage/chat');
  },
  hidden: true
}, {
  path: '/bigHome',
  component: function component() {
    return import('@/views/platHome/bigHome/bigHome.vue');
  },
  meta: {
    title: '首页大屏',
    noCache: true,
    isCache: true,
    icon: 'list',
    affix: true
  },
  hidden: true
}, {
  path: '/bigCameraList',
  component: function component() {
    return import('@/views/media/cameralist_new/cameraLiveList.vue');
  },
  meta: {
    title: '监控列表',
    noCache: true,
    isCache: true,
    icon: 'list',
    affix: true
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return import('@/views/login/auth-redirect');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/error-page/404');
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return import('@/views/error-page/401');
  },
  hidden: true
}, //
// 作用：可以打开，临时用来测试查看:一些组件的测试页面：project\yuexin-vue\src\views\components-demo\avatar-upload.vue
// {
// path: '/demo',
// component: Layout,
// hidden: true,
// children: [{
// path: 'DetailImgeditor2',
// component: () =>
//   import('@/views/components-demo/DetailImgeditor2'),
// hidden: true,
// name: 'DemoDetailImgeditor2',
// meta: { title: '例子列表', icon: 'dashboard', affix: true }
// }]
// },
{
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    name: 'Dashboard',
    meta: {
      title: '首页',
      icon: 'dashboard',
      affix: true
    }
  }],
  hidden: false // 如果角色勾选了其他首页，hidden会改成true

}, {
  path: '/profile',
  component: Layout,
  redirect: '/profile/index',
  hidden: true,
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/profile/index');
    },
    name: 'Profile',
    meta: {
      title: 'Profile',
      icon: 'user',
      noCache: true
    }
  }]
}];
/**
 * asyncRoutes
 * 需要根据用户角色动态加载的路由
 */

export var asyncRoutes = [platHomeRouter, platStatisticsRouter, shopHomeRouter, storeHomeRouter, shopStatisticsRouter, agencyHomeRouter, attractHomeRouter, organizationRouter, //系统管理
companyPlatRouter, companyRouter, contentPlatRouter, orderRouter, // planeRouter, // 飞机
couponPlatRouter, customerRouter, //客户池
webAdminRouter, //官网后台
productRouter, productPlatRouter, expressRouter, findRouter, find2Router, mediaRouter, medianewRouter, mediaFirmRouter, liveRouter, messageManagementRouter, servicePlatRouter, //平台客服路由
// 路由
vipManagementRouter, WholesaleManagement, //批发管理
walletPlatRouter, // 钱包-平台端
merchantInteractionRouter, //商家互动-平台端
merchantInteractionPlatRouter, //商家互动-企业端
purseRouter, //  钱包-企业后台
agencyMangerRouter, //站长后台-站长管理
attractWalletRouter, //   StoreAftermarket,
searchPlatRouter, headLineRouter, storeManagement, storeManagementPlat, shopmanageRouter, agencyRouter, orderServiceRouter, orderPlatRouter, orderServicePlatRouter, operationRouter, marketingactivityRouter, shopsmanageterraceRouter, shopoperationRouter, //门店运营
shopModulePlatformRouter, agentRouter, agentUserRouter, serviceRouter, //商铺客服路由
agencymanagementRouter, attractcollectRouter, needwithdrawRouter, attractpersonnelRouter, replacemanageRouter, attractCompanyRouter, attractEnterpriseAudit, traceabilityPlatRouter, traceabilityRouter, personalCenterRouter, appraiseManagePlatRouter, appraiseManageRouter, articleManagementRouter, articleManagementPlatRouter, {
  path: '/github',
  component: Layout,
  children: [{
    path: 'https://github.com/zhoutaoo/springcloud.git',
    meta: {
      title: 'Github地址',
      icon: 'link'
    }
  }]
}, {
  path: '/icon',
  component: Layout,
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/icons/index');
    },
    name: 'Icons',
    meta: {
      title: 'Icons',
      icon: 'icon',
      noCache: true
    }
  }]
}, // 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];

var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter();
router.beforeEach(function (to, from, next) {
  if (to.meta.isCache) {
    store.dispatch('tagsView/delCachedView', from).then(function () {});
    next();
  } else {
    next();
  }
}); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;