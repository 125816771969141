//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import ToPad from "./toPad";
export default {
  name: 'itemTrain',
  components: {
    ToPad: ToPad
  },
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    goDetails: function goDetails(orderSn) {
      this.$router.push({
        path: '/planeDetail',
        query: {
          orderSn: orderSn
        }
      });
    }
  },
  filters: {
    /* 时间转换 */
    timeTranHM: function timeTranHM(value) {
      return !value ? '-' : tool.date('Y-m-d H:i', value);
    }
  }
};