import _defineProperty from "/root/.jenkins/workspace/yuexin_vue/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty";

var _meta;

// 运营管理
import Layout from '@/layout';
var operationRouter = {
  path: '/operation',
  component: Layout,
  redirect: '/operation/systemMessage',
  alwaysShow: true,
  name: 'Operation',
  meta: {
    title: '运营管理-平台',
    icon: 'role'
  },
  children: [{
    path: 'systemMessage',
    component: function component() {
      return import('@/views/operation/systemMessage.vue');
    },
    name: 'Messagemanage',
    meta: {
      title: '系统消息管理',
      icon: 'peoples'
    }
  }, {
    path: 'addMessage',
    component: function component() {
      return import('@/views/operation/addMessage');
    },
    name: 'IssueSystemMessage',
    meta: (_meta = {
      title: '发布消息',
      isCache: true,
      noCache: true
    }, _defineProperty(_meta, "noCache", true), _defineProperty(_meta, "activeMenu", '/operation/systemMessage'), _meta),
    hidden: true
  }, {
    path: 'advertisingList',
    component: function component() {
      return import('@/views/operation/advertising/advertisingList.vue');
    },
    name: 'AdvertisingList',
    meta: {
      title: '广告列表',
      icon: 'peoples'
    }
  }, {
    path: 'advertisingmanage',
    component: function component() {
      return import('@/views/operation/advertising/advertisingmanage.vue');
    },
    name: 'Advertisingmanage',
    meta: {
      title: '广告明细',
      noCache: true,
      activeMenu: '/operation/advertisingList'
    },
    hidden: true
  }, {
    path: 'advertisingStat',
    component: function component() {
      return import('@/views/operation/advertising/statistics.vue');
    },
    name: 'AdvertisingStat',
    meta: {
      title: '数据统计',
      noCache: true,
      activeMenu: '/operation/advertisingList'
    },
    hidden: true
  }, {
    path: 'additionadvertising',
    component: function component() {
      return import('@/views/operation/advertising/additionadvertising.vue');
    },
    name: 'Additionadvertising',
    meta: {
      title: '添加广告',
      noCache: true,
      activeMenu: '/operation/advertisingList'
    },
    hidden: true
  }, {
    path: 'edititionadvertising',
    component: function component() {
      return import('@/views/operation/advertising/edititionadvertising.vue');
    },
    name: 'Edititionadvertising',
    meta: {
      title: '编辑广告',
      noCache: true,
      activeMenu: '/operation/advertisingList'
    },
    hidden: true
  }, {
    path: 'advertisingplace',
    component: function component() {
      return import('@/views/operation/advertising/advertisingplace.vue');
    },
    name: 'Advertisingplace',
    meta: {
      title: '广告位置',
      icon: 'peoples'
    }
  }, {
    path: '/operation/marketing',
    component: function component() {
      return import('@/views/operation/advertising/marketing/index.vue');
    },
    name: 'AdvertisingMarketingPlat',
    meta: {
      title: '广告营销',
      icon: 'peoples'
    }
  }, {
    path: '/operation/marketing/create',
    component: function component() {
      return import('@/views/operation/advertising/marketing/add.vue');
    },
    name: 'AddAdvertisingMarketing',
    meta: {
      title: '新建广告营销',
      noCache: true,
      isCache: true,
      activeMenu: '/operation/marketing'
    },
    hidden: true
  }, {
    path: '/operation/marketing/edit',
    component: function component() {
      return import('@/views/operation/advertising/marketing/add.vue');
    },
    name: 'EditAdvertisingMarketing',
    meta: {
      title: '编辑广告营销',
      noCache: true,
      isCache: true,
      activeMenu: '/operation/marketing'
    },
    hidden: true
  }, {
    path: '/operation/marketing/detail',
    component: function component() {
      return import('@/views/operation/advertising/marketing/detail.vue');
    },
    name: 'AdvertisingMarketingDetailPlat',
    meta: {
      title: '广告营销详情',
      noCache: true,
      activeMenu: '/operation/marketing'
    },
    hidden: true
  }, {
    path: '/operation/marketing/check',
    component: function component() {
      return import('@/views/operation/advertising/marketing/check.vue');
    },
    name: 'AdvertisingMarketingCheckPlat',
    meta: {
      title: '广告审核',
      noCache: true,
      icon: 'peoples'
    }
  }, {
    path: '/operation/marketing/audit',
    component: function component() {
      return import('@/views/operation/advertising/marketing/auditDetail.vue');
    },
    name: 'MarketingAudit',
    meta: {
      title: '广告营销审核',
      noCache: true,
      isCache: true,
      activeMenu: '/operation/marketing/check'
    },
    hidden: true
  }, {
    path: '/operation/marketing/auditDetail',
    component: function component() {
      return import('@/views/operation/advertising/marketing/auditDetail.vue');
    },
    name: 'MarketingAuditDetail',
    meta: {
      title: '广告营销审核详情',
      noCache: true,
      activeMenu: '/operation/marketing/check'
    },
    hidden: true
  }, {
    path: '/marketing/orderShop',
    component: function component() {
      return import('@/views/operation/advertising/marketing/shopList.vue');
    },
    name: 'AdvertisingMarketingOrderShop',
    meta: {
      title: '订购商铺'
    }
  }, {
    path: '/marketing/shopDetail',
    component: function component() {
      return import('@/views/operation/advertising/marketing/shopDetail.vue');
    },
    name: 'AdvertisingMarketingShopDetail',
    meta: {
      title: '订购详情',
      noCache: true,
      activeMenu: '/marketing/orderShop'
    },
    hidden: true
  }, {
    path: '/marketing/statPlat',
    component: function component() {
      return import('@/views/operation/advertising/marketing/statistics.vue');
    },
    name: 'AdvertisingMarketingStatPlat',
    meta: {
      title: '广告数据',
      noCache: true,
      activeMenu: '/marketing/orderShop'
    },
    hidden: true
  }, {
    path: 'activitycondition',
    component: function component() {
      return import('@/views/operation/activitycondition.vue');
    },
    name: 'Activitycondition',
    meta: {
      title: '活动条件',
      icon: 'peoples'
    }
  }, {
    path: 'timesetting',
    component: function component() {
      return import('@/views/operation/timesetting.vue');
    },
    name: 'Timesetting',
    meta: {
      title: '秒杀设置',
      icon: 'peoples'
    }
  }, {
    path: 'activitymanage',
    component: function component() {
      return import('@/views/operation/activity/recordtable/activitymanage.vue');
    },
    name: 'Activitymanage',
    meta: {
      title: '活动列表',
      noCache: true,
      icon: 'peoples'
    }
  }, {
    path: 'activitydetail/:id',
    component: function component() {
      return import('@/views/operation/activity/recordtable/activitydetail.vue');
    },
    name: 'Activitydetail',
    meta: {
      title: '活动详情',
      activeMenu: '/operation/activitymanage'
    },
    hidden: true
  }, {
    path: 'augmentseckillactivity',
    component: function component() {
      return import('@/views/operation/activity/recordtable/augmentactivity/augmentseckillactivity.vue');
    },
    name: 'Augmentseckillactivity',
    meta: {
      title: '新增秒杀活动',
      isCache: true,
      noCache: true,
      activeMenu: '/operation/activitymanage'
    },
    hidden: true
  }, {
    path: 'editseckillactivity/:id',
    component: function component() {
      return import('@/views/operation/activity/recordtable/augmentactivity/editseckillactivity.vue');
    },
    name: 'Editseckillactivity',
    meta: {
      title: '编辑秒杀活动',
      noCache: true,
      activeMenu: '/operation/activitymanage'
    },
    hidden: true
  }, {
    path: 'augmentgroupactivity',
    component: function component() {
      return import('@/views/operation/activity/recordtable/augmentactivity/augmentgroupactivity.vue');
    },
    name: 'Augmentgroupactivity',
    meta: {
      title: '新增团购活动',
      noCache: true,
      activeMenu: '/operation/activitymanage'
    },
    hidden: true
  }, {
    path: 'augmentdiscountactivity',
    component: function component() {
      return import('@/views/operation/activity/recordtable/augmentactivity/augmentdiscountactivity.vue');
    },
    name: 'Augmentdiscountactivity',
    meta: {
      title: '新增折扣活动',
      isCache: true,
      noCache: true,
      activeMenu: '/operation/activitymanage'
    },
    hidden: true
  }, {
    path: 'editdiscountactivity/:id',
    component: function component() {
      return import('@/views/operation/activity/recordtable/augmentactivity/editdiscountactivity.vue');
    },
    name: 'Editdiscountactivity',
    meta: {
      title: '编辑折扣活动',
      noCache: true,
      activeMenu: '/operation/activitymanage'
    },
    hidden: true
  }, {
    path: 'augmentbargainactivity',
    component: function component() {
      return import('@/views/operation/activity/recordtable/augmentactivity/augmentbargainactivity.vue');
    },
    name: 'Augmentbargainactivity',
    meta: {
      title: '新增砍价活动',
      noCache: true,
      activeMenu: '/operation/activitymanage'
    },
    hidden: true
  }, {
    path: 'activityexamine',
    component: function component() {
      return import('@/views/operation/activity/examine/activityexamine.vue');
    },
    name: 'Activityexamine',
    meta: {
      title: '活动审批',
      icon: 'peoples'
    }
  }, {
    path: 'examinedetail',
    component: function component() {
      return import('@/views/operation/activity/examine/examinedetail.vue');
    },
    name: 'Examinedetail',
    meta: {
      title: '审批详情',
      noCache: true,
      activeMenu: '/operation/activityexamine'
    },
    hidden: true
  }, {
    path: 'virtualgroup',
    component: function component() {
      return import('@/views/operation/virtualgroup.vue');
    },
    name: 'Virtualgroup',
    meta: {
      title: '虚拟成团列表',
      icon: 'peoples'
    },
    hidden: true
  }, {
    path: 'vipAreaPlatList',
    component: function component() {
      return import('@/views/vipAreaPlatform/list');
    },
    name: 'VipAreaPlatList',
    meta: {
      title: '会员专区',
      icon: 'list'
    }
  }, {
    path: 'vipAreaPlatDetail',
    component: function component() {
      return import('@/views/vipAreaPlatform/detail');
    },
    name: 'VipAreaPlatDetail',
    meta: {
      title: '详情',
      noCache: true,
      activeMenu: '/operation/vipAreaPlatList'
    },
    hidden: true
  }, {
    path: 'vipAreaPlatCreate',
    component: function component() {
      return import('@/views/vipAreaPlatform/create');
    },
    name: 'VipAreaPlatCreate',
    meta: {
      title: '新增',
      activeMenu: '/operation/vipAreaPlatList'
    },
    hidden: true
  }, {
    path: 'integralDetail',
    component: function component() {
      return import('@/views/vipAreaPlatform/integralDetail');
    },
    name: 'MemberAreaIntegralDetail',
    meta: {
      title: '商铺对账明细',
      icon: 'list'
    }
  }, {
    path: 'storeIntegralDetail',
    component: function component() {
      return import('@/views/vipAreaPlatform/storeIntegralDetail');
    },
    name: 'MemberAreaStoreIntegralDetail',
    meta: {
      title: '门店对账明细',
      icon: 'list'
    }
  }]
};
export default operationRouter;