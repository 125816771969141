import _objectSpread from "/root/.jenkins/workspace/yuexin_vue/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import service from '@/api/serviceManagement/serviceList';
import { getStore } from '@/utils/auth';
import orderlist from "./orderlist";

var isEmpty = function isEmpty(str) {
  if (str === '' || str === undefined || str === null) {
    return true;
  }

  return false;
};

export default {
  name: 'Chat1',
  model: {
    prop: 'value',
    event: 'change'
  },
  components: {
    orderlist: orderlist
  },
  props: {
    openBroadside: {
      type: Boolean,
      default: false
    },
    isCurrentSessionChat: {
      type: Boolean,
      default: true
    },
    allowedSendMessage: {
      type: Boolean,
      default: true
    },
    contentValue: {
      type: String,
      default: ''
    },
    row: {
      type: Number,
      default: 3
    },
    maxLength: {
      type: Number,
      default: 100
    },
    dragResizeHeight: {
      type: Number,
      default: 0
    },
    dragResizeWidth: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    var _this = this;

    var catgroyNameRules = function catgroyNameRules(rule, value, callback) {
      if (_this.editForm.classify == 1) {
        callback();
        return;
      } else {
        if (value === '') {
          callback(new Error('请输入分类名称'));
        } else if (value.length > 8) {
          callback(new Error('最多只能输入8个字!'));
        } else {
          callback();
        }
      }
    };

    var catgroyIdRules = function catgroyIdRules(rule, value, callback) {
      if (_this.editForm.classify == 2) {
        callback();
        return;
      } else {
        if (value === '') {
          callback(new Error('请选择'));
        } else {
          callback();
        }
      }
    };

    return {
      search: '',
      activeName: 'first',
      // 常用语
      total: 0,
      form: {},
      companyId: '',
      //企业id
      classifyOptions: [],
      //分类下拉数据
      dialogTitle: '',
      popupVisible: false,
      popupVisible_cyu: false,
      btnLoading: false,
      editForm: {
        classify: 1,
        status: 1
      },
      //新增或编辑表单
      editRules: {
        classify: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }],
        content: [{
          required: true,
          message: '请输入内容',
          trigger: 'blur'
        }],
        catgroyId: [{
          required: true,
          validator: catgroyIdRules,
          trigger: 'change'
        }],
        catgroyName: [{
          required: true,
          validator: catgroyNameRules,
          trigger: 'blur'
        }]
      },
      listData: [],
      //常用语列表
      submitBtnText: '',
      //常用语弹窗按钮上的文字
      orderOver: '',
      //订单设置的未付款订单自动关闭时间，单位：分钟
      orderOverTimer: null,
      //计时器
      runOrderOver: '',
      isEditC: false,
      userType: '' //用户角色类型：1为平台，3为商铺

    };
  },
  computed: {
    // 计算当前文本框中内容的字数
    valueLength: function valueLength() {
      if (isEmpty(this.editForm.content)) {
        return 0;
      }

      return this.editForm.content.length;
    } // styleResize1(){
    //   return {
    //     height:600+this.dragResizeHeight+"px"
    //   }
    // },
    // styleResize2(){
    //   return {
    //     height:550+this.dragResizeHeight+"px"
    //   }
    // }

  },
  mounted: function mounted() {
    this.getClassifyList();
    this.getCatContentList();
  },
  updated: function updated() {
    var that = this;
    clearInterval(that.orderOverTimer);
    that.orderOverTimer = setInterval(function () {
      // that.getRunTime(that.orderOver)
      if (that.orderOver > 0) {
        that.orderOver--;
      }
    }, 1000);
  },
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.orderOverTimer);
  },
  methods: {
    isHuiFu: function isHuiFu(title) {
      // console.log("#"+title+"#")
      title = title.trim();
      if (title == "常用回复") return true;
      return false;
    },
    // 点击订单编号跳转到非售后订单详情
    linkOrderDetail: function linkOrderDetail(orderSn) {
      this.chatShow = false;
      this.$emit('hideService');

      if (this.userType == 1) {
        this.$router.push({
          path: '/orderPlat/order-detail',
          query: {
            orderSn: orderSn
          }
        });
      } else {
        this.$router.push({
          path: '/order-detail',
          query: {
            orderSn: orderSn
          }
        });
      }
    },
    // 点击订单编号跳转到售后订单详情
    linkRefundOrderDetail: function linkRefundOrderDetail(id, type) {
      this.chatShow = false;
      this.$emit('hideService'); // 售后类型(1-退货，2-换货，3-退款)

      if (this.userType == 1) {
        if (type == 1) {
          this.$router.push({
            path: '/orderPlat/order-service/returned-goods-detail',
            query: {
              id: id
            }
          });
        } else if (type == 2) {
          this.$router.push({
            path: '/orderPlat/order-service/changed-goods-detail',
            query: {
              id: id
            }
          });
        } else if (type == 3) {
          this.$router.push({
            path: '/orderPlat/order-service/drawback-detail',
            query: {
              id: id
            }
          });
        }
      } else {
        if (type == 1) {
          this.$router.push({
            path: '/order-service/returned-goods-detail',
            query: {
              id: id
            }
          });
        } else if (type == 2) {
          this.$router.push({
            path: '/order-service/changed-goods-detail',
            query: {
              id: id
            }
          });
        } else if (type == 3) {
          this.$router.push({
            path: '/order-service/drawback-detail',
            query: {
              id: id
            }
          });
        }
      }
    },
    // 常用语内容输入
    contentInputChange: function contentInputChange() {
      this.editForm.content = this.editForm.content.substring(0, this.maxLength);
      this.$emit('change', this.editForm.content);
    },
    // 添加常用语用于发信息
    addMsg: function addMsg(obj) {
      // console.log('obj', obj)
      if (!this.isCurrentSessionChat) {
        if (!this.allowedSendMessage) {
          this.$message.error("当前会话已结束");
          return;
        }
      }

      this.$emit('addMsg', obj.content);
    },
    handleClick: function handleClick(tab) {
      // console.log('taby', tab)
      if (tab == 'first') {//  this.updateOrderList(this.memberId)
      } else if (tab == 'second') {
        this.getCatContentList();
      }

      this.$forceUpdate();
    },
    //搜索常用语
    handleSearch: function handleSearch() {
      this.current = 1;
      this.getCatContentList();
    },
    //获取分类数据
    getClassifyList: function getClassifyList() {
      var _this2 = this;

      service.getCommonClassifyData({
        storeId: '0'
      }).then(function (res) {
        if (res.code === '000000') {
          // console.log('获取分类数据res', res)
          _this2.classifyOptions = res.data;
        } else {
          _this2.$message.error(res.mesg);
        }
      });
    },
    //添加常用语
    addCommon: function addCommon(obj) {
      // console.log('objxxxxxxxxx', obj)
      this.isEditC = false;
      this.submitBtnText = '新增';
      this.getClassifyList();

      if (obj) {
        this.dialogTitle = '添加'; // this.editForm = { ...obj }

        this.editForm = {};
        this.editForm.classify = 1;
        this.editForm.catgroyId = obj.id || obj.catgroyId;
      } else {
        this.dialogTitle = '添加';
        this.editForm = {};
        this.editForm.classify = 1;
      }

      this.editForm.status = 1;
      this.popupVisible = true;
    },
    //置顶
    topCommon: function topCommon(id) {
      var _this3 = this;

      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      var methode = "";

      if (type == 2) {
        methode = "chatFastReplyTopping";
      } else {
        methode = "chatFastCatgoryTopping";
      }

      service[methode](id).then(function (res) {
        if (res.code === '000000') {
          _this3.getCatContentList();

          _this3.$message.success(res.mesg);
        } else {
          _this3.$message.error(res.mesg);
        }
      });
    },
    //编辑常用语
    editCommon: function editCommon(obj) {
      var isEditC = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      this.isEditC = isEditC;
      this.submitBtnText = '确认';
      this.getClassifyList();

      if (obj) {
        this.dialogTitle = '编辑';
        this.editForm = _objectSpread({}, obj);
        this.editForm.classify = 2;
      } // else {
      //   this.dialogTitle = '添加'
      //   this.editForm = {}
      //   this.editForm.classify = 1
      // }


      this.popupVisible = true;
    },
    editCommonS: function editCommonS(obj) {
      this.isEditC = false;
      this.submitBtnText = '确认'; // console.log('obj',obj)

      this.getClassifyList();

      if (obj) {
        this.dialogTitle = '编辑';
        this.editForm = _objectSpread({}, obj);
      } // else {
      //   this.dialogTitle = '添加'
      //   this.editForm = {}
      //   this.editForm.classify = 1
      // }


      this.popupVisible_cyu = true;
    },
    //删除
    handleDelete: function handleDelete(row) {
      var _this4 = this;

      this.$confirm('是否确认删除？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        service.delCommonSet({
          id: row.id
        }).then(function (res) {
          if (res.code === '000000') {
            _this4.getCatContentList();

            _this4.$message.success(res.mesg);
          } else {
            _this4.$message.error(res.mesg);
          }
        });
      }).then(function () {}).catch(function () {});
    },
    //删除常用回复的分类
    deleteFastCatgory: function deleteFastCatgory(row) {
      var _this5 = this;

      this.$confirm('是否确认删除这个分类？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        service.deleteChatFastCatgory({
          id: row.id
        }).then(function (res) {
          if (res.code === '000000') {
            _this5.getCatContentList();

            _this5.$message.success(res.mesg);
          } else {
            _this5.$message.error(res.mesg);
          }
        });
      }).then(function () {}).catch(function () {});
    },
    changeCatgory: function changeCatgory() {
      this.$forceUpdate(); // this.editForm.catgroyId = this.editForm.classify === 1 ? this.editForm.catgroyId : this.editForm.catgoryName
    },
    //关闭弹窗
    closeDialog: function closeDialog() {
      this.popupVisible = false;
      this.popupVisible_cyu = false;
    },
    //获取指定商户的分类和回复内容
    getCatContentList: function getCatContentList() {
      var _this6 = this;

      this.loading = true;
      service.getCatContentList({
        searchKey: this.form.content,
        storeId: 0
      }).then(function (res) {
        if (res.code === '000000') {
          console.log('获取指定商户的分类和回复内容res', res); // this.classifyOptions = res.data

          _this6.loading = false;
          var arr = res.data;
          var findIndex = 0;
          var findItem = null;

          for (var i = 0; i < arr.length; i++) {
            if (arr[i].catgroyName.trim() == "常用回复") {
              findItem = arr.splice(i, 1)[0];
              findIndex = i;
              break;
            }
          }

          if (findItem) arr.unshift(findItem);
          _this6.listData = arr;
        } else {
          _this6.$message.error(res.mesg);
        }
      });
    },
    //获取列表数据
    getData: function getData() {
      var _this7 = this;

      this.loading = true;

      var data = _objectSpread(_objectSpread({}, this.form), {}, {
        current: this.current,
        size: this.pageSize,
        storeId: this.companyId
      }); // console.log('data',data)


      service.getCommonSetData(data).then(function (res) {
        if (res.code === '000000') {
          // console.log('获取回复常用语列表数据res',res)
          _this7.loading = false;
          _this7.listData = res.data.records;
          _this7.total = res.data.total;
          _this7.pageSize = res.data.size;
          _this7.current = res.data.current;
        } else {
          _this7.$message.error(res.mesg);
        }
      });
    },
    submitForm: function submitForm() {
      var _this8 = this;

      this.$refs.editForm.validate(function (valid) {
        _this8.editForm.storeId = 0;

        if (valid) {
          _this8.btnLoading = true;

          if (_this8.isEditC) {
            //修改分类
            service.saveCatgory(_this8.editForm).then(function (res) {
              if (res.code === '000000') {
                _this8.$message.success(res.mesg);

                _this8.popupVisible = false;
                _this8.popupVisible_cyu = false;

                _this8.getCatContentList();

                _this8.btnLoading = false;
              } else {
                _this8.btnLoading = false;

                _this8.$message.error(res.mesg);
              }
            });
          } else {
            if (_this8.editForm.classify == 2) {
              _this8.editForm.catgroyId = null;
              delete _this8.editForm.catgroyId;
            }

            service.saveCommonSet(_this8.editForm).then(function (res) {
              if (res.code === '000000') {
                _this8.$message.success(res.mesg);

                _this8.popupVisible = false;
                _this8.popupVisible_cyu = false;

                _this8.getCatContentList();

                _this8.btnLoading = false;
              } else {
                _this8.btnLoading = false;

                _this8.$message.error(res.mesg);
              }
            });
          }
        } else {
          _this8.btnLoading = false;
        }
      });
    },
    updateOrderList: function updateOrderList(memberId) {
      this.memberId = memberId;
      this.$refs.orderlist.list(memberId);
    }
  },
  watch: {
    // 监听value的变化
    contentValue: {
      handler: function handler(val, oldVal) {
        this.editForm.content = val;
      },
      immediate: true
    }
  }
};