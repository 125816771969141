// 商铺管理
import Layout from '@/layout';
var shopModulePlatformRouter = {
  path: '/shopModulePlatform',
  component: Layout,
  redirect: '/shopModulePlatform/list',
  alwaysShow: true,
  name: 'ShopModulePlatform',
  meta: {
    title: '商铺管理-平台',
    icon: 'role'
  },
  children: [{
    path: 'shopList',
    component: function component() {
      return import('@/views/shopModulePlatform/shopList');
    },
    name: 'shopList',
    meta: {
      title: '商铺列表',
      icon: 'peoples',
      noCache: true
    }
  }, {
    path: 'newAddShop',
    component: function component() {
      return import('@/views/shopModulePlatform/newAddShop');
    },
    name: 'NewAddShop',
    meta: {
      title: '添加商铺',
      noCache: true,
      activeMenu: '/shopModulePlatform/shopList'
    },
    hidden: true
  }, {
    path: '/shopModulePlatform/shopDetail',
    component: function component() {
      return import('@/views/shopModulePlatform/editShop');
    },
    name: 'ShopDetail',
    meta: {
      title: '商铺详情',
      noCache: true,
      activeMenu: '/shopModulePlatform/shopList'
    },
    hidden: true
  }, {
    path: '/shopModulePlatform/editShop',
    component: function component() {
      return import('@/views/shopModulePlatform/editShop');
    },
    name: 'EditShopPlat',
    meta: {
      title: '编辑商铺',
      noCache: true,
      activeMenu: '/shopModulePlatform/shopList'
    },
    hidden: true
  }, {
    path: 'approveList',
    component: function component() {
      return import('@/views/shopModulePlatform/approveList');
    },
    name: 'ApproveList',
    meta: {
      title: '商铺申请',
      icon: 'tree'
    }
  }, {
    path: '/shopModulePlatform/shopApply',
    component: function component() {
      return import('@/views/shopModulePlatform/editShop');
    },
    name: 'ShopApply',
    meta: {
      title: '商铺审核',
      noCache: true,
      isCache: true,
      activeMenu: '/shopModulePlatform/approveList'
    },
    hidden: true
  }, {
    path: '/shopModulePlatform/auditDetail',
    component: function component() {
      return import('@/views/shopModulePlatform/editShop');
    },
    name: 'AuditDetail',
    meta: {
      title: '商铺审核详情',
      noCache: true,
      activeMenu: '/shopModulePlatform/approveList'
    },
    hidden: true
  }, {
    path: 'flowRecord',
    component: function component() {
      return import('@/views/shopModulePlatform/flowRecord');
    },
    name: 'ShopFlowRecordPlat',
    meta: {
      title: '流水记录',
      icon: 'peoples',
      activeMenu: '/shopModulePlatform/shopList'
    },
    hidden: true
  }, {
    path: 'withdrawsCash',
    component: function component() {
      return import('@/views/shopModulePlatform/withdrawsCash');
    },
    name: 'WithdrawsCash',
    meta: {
      title: '提现流水',
      icon: 'peoples'
    },
    hidden: true
  }, // {
  //   path: 'flowRecordDetail',
  //   component: () => import('@/views/shopModulePlatform/flowRecordDetail.vue'),
  //   name: 'FlowRecordDetail',
  //   meta: { title: '流水详情', icon: 'peoples' },
  //   hidden: true
  // },
  {
    path: 'shopTypeList',
    component: function component() {
      return import('@/views/shopModulePlatform/shopTypeList.vue');
    },
    name: 'ShopTypeList',
    meta: {
      title: '商铺分类',
      noCache: true,
      icon: 'peoples'
    }
  }, {
    path: 'shopCategorysList',
    component: function component() {
      return import('@/views/shopModulePlatform/shopCategorysList.vue');
    },
    name: 'shopCategorysList',
    meta: {
      title: '分类申请',
      icon: 'peoples'
    }
  }, {
    path: 'shopGrade',
    component: function component() {
      return import('@/views/shopGrade/shopGradeList.vue');
    },
    name: 'ShopGrade',
    meta: {
      title: '商铺等级',
      icon: 'peoples'
    }
  }, {
    path: 'shopWithdraw',
    component: function component() {
      return import('@/views/shopWithdraw/shopWithdraw.vue');
    },
    name: 'ShopWithdraw',
    meta: {
      title: '商铺提现',
      icon: 'peoples'
    }
  }, {
    path: 'withdrawCashSettings',
    component: function component() {
      return import('@/views/shopModulePlatform/withdrawCashSettings.vue');
    },
    name: 'WithdrawCashSettings',
    meta: {
      title: '提现设置',
      icon: 'peoples'
    }
  }]
};
export default shopModulePlatformRouter;