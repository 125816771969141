import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
import { verificationInfo, verificationShopOnOff, verificationStoreOnOff, openInfo4 } from '@/api/user';
import live from '@/api/media/live.js';
import { getStore } from '@/utils/auth';
export default {
  name: 'AppMain',
  computed: {
    cachedViews: function cachedViews() {
      var arr = this.$store.state.tagsView.cachedViews; // console.log("cachedViews:",arr)

      return arr;
    },
    key: function key() {
      // console.log(this.$route.path)
      return this.$route.path;
    }
  },
  mounted: function mounted() {
    this.getShopInfoState();
  },
  methods: {
    getShopInfoState: function getShopInfoState() {
      var _this = this;

      openInfo4().then(function (res) {
        // console.log("openInfo4:",res.mesg)
        if (res.code === '000000') {
          var shopInfo = res.data.shopInfo;

          if (shopInfo === false && _this.$route.path !== '/shop/editShop') {
            _this.$alert('欢迎使用悦鑫国际商城后台，请先完成商铺名称、商铺logo填写再进行其他操作', '提示', {
              confirmButtonText: '填写信息',
              callback: function callback(action) {
                _this.$router.push({
                  path: '/shop/editShop',
                  query: {
                    pageName: 'shopInfo',
                    activeTab: 'shop'
                  }
                });
              }
            });
          }

          var shopOpen = res.data.shopOpen;

          if (shopOpen && shopOpen === true) {
            // if (!res.data) {
            // console.log("this.$route:",this.$route.path)
            _this.$root.verificationShopOnOff = false; // 有一些页面调了刷新整个页面，所以这里不要弹出。

            var p = _this.$route.path;
            var isAlert = true;

            if (p == "/order-service/returned-goods-detail") {
              isAlert = false;
            }

            if (isAlert) {
              _this.$alert('商铺已停用，如有疑问请联系管理员', {
                confirmButtonText: '确定',
                callback: function callback(action) {}
              });
            }
          }

          if (typeof res.data.nearbyShopInfo != "undefined") {
            if (res.data.nearbyShopInfo.isEnable == 1) {
              _this.$root.verificationStoreOnOff = false;
              var msg = '门店【' + res.data.nearbyShopInfo.name + '】已停用，如有疑问请联系管理员';
              var p = _this.$route.path;
              var isAlert = true;

              if (p == "/order-service/storeSaleDetail") {
                isAlert = false;
              }

              if (isAlert) {
                _this.$alert(msg, {
                  confirmButtonText: '确定',
                  callback: function callback(action) {}
                });
              }
            }
          }
        } else {
          console.log(res.mesg); // this.$message.error(res.mesg)
        }
        /*shopInfo
        nearbyShopInfo
        shopOpen */

      });
      /*verificationInfo().then((res) => {
        if (res.code === '000000') {
          if (!res.data && this.$route.path !== '/shop/editShop') {
            this.$alert(
              '欢迎使用悦鑫国际商城后台，请先完成商铺名称、商铺logo填写再进行其他操作',
              '提示',
              {
                confirmButtonText: '填写信息',
                callback: (action) => {
                  this.$router.push({
                    path: '/shop/editShop',
                    query: { pageName: 'shopInfo', activeTab: 'shop' },
                  })
                },
              }
            )
          }
        }
      })*/

      /*verificationShopOnOff().then((res) => {
        if (res.code === '000000') {
          if (res.data && res.data === true) {
            // if (!res.data) {
            // console.log("this.$route:",this.$route.path)
            this.$root.verificationShopOnOff = false
            // 有一些页面调了刷新整个页面，所以这里不要弹出。
            var p = this.$route.path;
            var isAlert = true
            if (p == "/order-service/returned-goods-detail") {
              isAlert = false
            }
            if (isAlert) {
              this.$alert('商铺已停用，如有疑问请联系管理员', {
                confirmButtonText: '确定',
                callback: action => {
                }
              });
             }
           }
        } else {
          console.log(res.mesg)
          // this.$message.error(res.mesg)
        }
      })*/

      /*verificationStoreOnOff().then((res) => {
        if (res.code === '000000') {
          if (typeof res.data != "undefined") {
            // if (!res.data) {
            // console.log("this.$route:",this.$route.path)
            if (res.data.isEnable == 1) {
              this.$root.verificationStoreOnOff = false
              let msg = '门店【' + res.data.name + '】已停用，如有疑问请联系管理员'
              var p = this.$route.path;
              var isAlert = true
              if (p == "/order-service/storeSaleDetail") {
                isAlert = false
              }
              if (isAlert) {
                this.$alert(msg, {
                  confirmButtonText: '确定',
                  callback: action => {
                  }
                });
              }
            }
           }
        } else {
          console.log(res.mesg)
        }
      })*/
      // 平台端根据企业id获取

      if (getStore('identity') != "PLATFORM") {
        live.getEntStreamingText().then(function (res) {
          if (res.code === '000000') {
            if (!res.data) return;

            if (res.data.state == 0) {
              var p = _this.$route.path;
              var isAlert = false;
              if (p == '/shop/home') isAlert = true;

              if (isAlert) {
                _this.$alert('流媒体监控已停用，如有疑问请联系管理员', {
                  confirmButtonText: '确定',
                  callback: function callback(action) {}
                });
              }
            }
          }
        });
      }
    }
  }
};