var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "chatRecord-container",
      style: { height: _vm.height + "px" }
    },
    [
      _vm._l(_vm.chatRecordPadList, function(item, idx) {
        return _c(
          "div",
          { key: idx, staticClass: "you-messages" },
          [
            !_vm.isSysMsg(item) &&
            item.messageType != "RC:RcCmd" &&
            item.messageType != "RC:ReadNtf" &&
            item.messageType != "RC:TypSts"
              ? _c(
                  "div",
                  {
                    class: {
                      time: true,
                      timeright: !item.isLeft,
                      sysmsg: _vm.isSysMsg(item)
                    }
                  },
                  [_vm._v("\n      " + _vm._s(item.sentTime) + "\n    ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isSysMsg(item) &&
            item.messageType != "RC:RcCmd" &&
            item.messageType != "RC:ReadNtf" &&
            item.messageType != "RC:TypSts"
              ? _c(
                  "div",
                  {
                    class: { time: true },
                    staticStyle: { left: "43% !important" }
                  },
                  [_vm._v("\n      " + _vm._s(item.sentTime) + "\n    ")]
                )
              : _vm._e(),
            _vm._v(" "),
            item.isLeft &&
            !_vm.isSysMsg(item) &&
            item.messageType != "RC:RcCmd" &&
            item.messageType != "RC:ReadNtf" &&
            item.messageType != "RC:TypSts"
              ? _c("el-avatar", {
                  staticClass: "you-messages-usr-icon",
                  staticStyle: {
                    margin: "0 10px",
                    position: "relative",
                    bottom: "13px"
                  },
                  attrs: {
                    size: 30,
                    src:
                      (item.user &&
                        item.user.img +
                          "?x-oss-process=image/resize,m_fixed,h_50,w_50") ||
                      _vm.circleUrl
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { flex: "1" } },
              [
                !_vm.isSysMsg(item) &&
                item.messageType != "RC:RcCmd" &&
                item.messageType != "RC:ReadNtf" &&
                item.messageType != "RC:TypSts"
                  ? _c(
                      "div",
                      {
                        class: {
                          "you-name": true,
                          "you-name-right": !item.isLeft
                        }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              (item.user && item.user.nickName) ||
                                item.senderUserId
                            ) +
                            "\n      "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.getImageUri(item.content) && !_vm.isSysMsg(item)
                  ? _c("el-image", {
                      ref: "YourPreview",
                      refInFor: true,
                      staticClass: "chat-img",
                      attrs: {
                        src: _vm.getImageUri(item.content),
                        "preview-src-list": [_vm.getImageUri(item.content)]
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                item.messageType == "RC:RcCmd"
                  ? _c(
                      "div",
                      {
                        class: { message: true, sysmsg: true },
                        staticStyle: { background: "#fff" }
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(item.sentTime) + "\n        "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              (item.user && item.user.nickName) ||
                                item.senderUserId
                            ) +
                            " 撤回了一条消息\n      "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                item.content &&
                item.content.content &&
                item.messageType == "App:SimpleMsg"
                  ? _c("div", {
                      class: { message: true, "message-right": !item.isLeft },
                      domProps: {
                        innerHTML: _vm._s(_vm.TranslationMsgContent(item))
                      }
                    })
                  : (item.content &&
                      item.content.content &&
                      item.messageType == "RC:TxtMsg") ||
                    item.messageType == "Web:EndMsg"
                  ? _c("div", {
                      class: { message: true, "message-right": !item.isLeft },
                      domProps: {
                        innerHTML: _vm._s(_vm.TranslationMsgContent(item))
                      }
                    })
                  : item.content &&
                    item.content.content &&
                    item.messageType == "App:YXProblem"
                  ? _c(
                      "div",
                      {
                        class: { message: true, "message-right": !item.isLeft }
                      },
                      [
                        _c("div", { staticClass: "yxproblem-head" }, [
                          _vm._v(
                            "\n          点击下面的问题，您可以得到快速解答哦~\n        "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "yxproblem-body" },
                          _vm._l(
                            _vm.TranslationMsgContent_YXProblem(
                              item.content.content
                            ),
                            function(yxproblem_item, yxproblem_index) {
                              return _c(
                                "div",
                                {
                                  key: yxproblem_index,
                                  staticClass: "yxproblem-item"
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(yxproblem_item) +
                                      "\n          "
                                  )
                                ]
                              )
                            }
                          ),
                          0
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "yxproblem-foot" }, [
                          _vm._v("\n          没有我想要问的，换一批\n        ")
                        ])
                      ]
                    )
                  : (item.content && item.messageType == "RC:InfoNtf") ||
                    item.messageType == "RC:ReadNtf"
                  ? _c("div", {
                      class: { message: true, sysmsg: true },
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.TranslationMsgContent_InfoNtf(item)
                        )
                      }
                    })
                  : item.content && item.messageType == "WEB:TRANSFER"
                  ? _c(
                      "div",
                      {
                        class: { message: true, sysmsg: true },
                        staticStyle: { background: "#fff" }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.TranslationMsgContent_TRANSFER(item, idx)
                            ) +
                            "\n        "
                        ),
                        _vm._v(" "),
                        _vm.isShowShow(item) && _vm.isbtnonerecord == 100
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "#00f"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.liaoTianjilu(
                                      item.content.content,
                                      idx,
                                      1
                                    )
                                  }
                                }
                              },
                              [_vm._v("聊天记录")]
                            )
                          : _vm._e()
                      ]
                    )
                  : item.content &&
                    item.content.remoteUrl &&
                    item.messageType == "RC:HQVCMsg"
                  ? _c(
                      "div",
                      {
                        class: { message: true, "message-right": !item.isLeft },
                        attrs: { controls: "" }
                      },
                      [
                        _c("m-audio", {
                          attrs: { src: item.content.remoteUrl }
                        })
                      ],
                      1
                    )
                  : item.content.content &&
                    (item.messageType == "App:YXGoodsMsg" ||
                      item.messageType == "App:NearbyGoodsMsg")
                  ? _c(
                      "div",
                      {
                        staticClass: "message goods-msg-box tag6",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.goPage(
                              item.messageType,
                              item.content.content,
                              3,
                              "goodsMsgDetail"
                            )
                          }
                        }
                      },
                      [
                        _c(
                          "el-image",
                          {
                            staticClass: "goods-img",
                            attrs: {
                              src:
                                item.content.content.goodsCoverUrl ||
                                item.content.content.img
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "placeholder" },
                                slot: "placeholder"
                              },
                              [
                                _vm._v("\n            加载中"),
                                _c("span", { staticClass: "dot" }, [
                                  _vm._v("...")
                                ])
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "goods-text-box" }, [
                          _c("div", { staticClass: "goods-price-min" }, [
                            _vm._v("\n            ¥"),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  item.content.content.goodsPriceMin ||
                                    item.content.content.price
                                )
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "goods-name two-row-hide" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    item.content.content.goodsName ||
                                      item.content.content.title
                                  ) +
                                  "\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "type-tag-box flex" },
                            _vm._l(item.content.content.goodsTag, function(
                              it,
                              index
                            ) {
                              return _c("span", { key: index }, [
                                _vm._v(_vm._s(it))
                              ])
                            }),
                            0
                          ),
                          _vm._v(" "),
                          item.messageType == "App:YXGoodsMsg"
                            ? _c("div", { staticClass: "flex shop-logo-box" }, [
                                _c("img", {
                                  staticClass: "shop-logo",
                                  attrs: {
                                    src: item.content.content.shopLogo,
                                    alt: ""
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "shop-name" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.content.content.shopName) +
                                      "\n            "
                                  )
                                ])
                              ])
                            : _vm._e()
                        ])
                      ],
                      1
                    )
                  : (item.content && item.messageType == "RC:ImgMsg") ||
                    item.messageType == "RC:GIFMsg"
                  ? _c(
                      "div",
                      {
                        class: { message: true, "message-right": !item.isLeft }
                      },
                      [
                        _c(
                          "el-image",
                          {
                            ref: "YourPreview",
                            refInFor: true,
                            staticClass: "chat-img",
                            attrs: {
                              src:
                                item.content.content ||
                                item.content.remoteUrl ||
                                _vm.getImageUri(item.content),
                              "preview-src-list": [
                                item.content.content ||
                                  item.content.remoteUrl ||
                                  _vm.getImageUri(item.content)
                              ]
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error"
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-picture-outline",
                                  staticStyle: { "font-size": "110px" }
                                })
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : item.messageType == "RC:SightMsg"
                  ? _c(
                      "div",
                      {
                        class: {
                          "chat-v-container": true,
                          "message-right": !item.isLeft
                        },
                        style: {
                          "background-image":
                            "url(data:image/jpg;base64," +
                              item.content.content ==
                            ""
                              ? _vm.base64Black
                              : item.content.content + ")"
                        },
                        on: {
                          click: function($event) {
                            return _vm.playVideo(item)
                          }
                        }
                      },
                      [_c("img", { attrs: { src: require("./play.png") } })]
                    )
                  : item.messageType == "App:GoodsOrderMsg"
                  ? _c(
                      "div",
                      [
                        _c("div", { staticClass: "ServiceChat-msg-order" }, [
                          _c("h2", [_vm._v("正在咨询的订单")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "goods" }, [
                            _c("div", { staticClass: "img" }, [
                              _c("div", {
                                style: {
                                  "background-image":
                                    "url(" +
                                    item.content.content.goodsCover +
                                    ")"
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "t1" }, [
                                _vm._v(_vm._s(item.content.content.goodsName))
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "moreinfo" }, [
                                _c("div", { staticClass: "l1" }, [
                                  _vm._v(
                                    "共" +
                                      _vm._s(item.content.content.goodsNum) +
                                      "件商品"
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "r1" }, [
                                  _vm._v(
                                    "合计：¥" +
                                      _vm._s(item.content.content.payAmount)
                                  )
                                ])
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row-1" }, [
                            _vm._v(
                              "订单号：" + _vm._s(item.content.content.orderNo)
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row-1" }, [
                            _vm._v(
                              "创建时间：" +
                                _vm._s(item.content.content.createdTime)
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "row-2" },
                            [
                              _vm.isShopUser
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { round: "", size: "small" },
                                      on: {
                                        click: function($event) {
                                          return _vm.$router.push({
                                            path: "/order-detail",
                                            query: {
                                              orderSn:
                                                item.content.content.orderNo
                                            }
                                          })
                                        }
                                      }
                                    },
                                    [_vm._v("查看\n            ")]
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      attrs: { round: "", size: "small" },
                                      on: {
                                        click: function($event) {
                                          return _vm.$router.push({
                                            path: "/orderPlat/order-detail",
                                            query: {
                                              orderSn:
                                                item.content.content.orderNo
                                            }
                                          })
                                        }
                                      }
                                    },
                                    [_vm._v("\n              查看")]
                                  )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _vm.showMsgTooltip
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: JSON.stringify(item),
                                  placement: "top-start"
                                }
                              },
                              [_c("i", { staticClass: "el-icon-info" })]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : item.messageType == "App:NearbyGoodsOrderMsg"
                  ? _c(
                      "div",
                      [
                        _c("div", { staticClass: "ServiceChat-msg-order" }, [
                          _c("h2", [_vm._v("正在咨询的订单")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "goods" }, [
                            _c("div", { staticClass: "img" }, [
                              _c("div", {
                                style: {
                                  "background-image":
                                    "url(" +
                                    item.content.content.goodsCover +
                                    ")"
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "t1" }, [
                                _vm._v(_vm._s(item.content.content.goodsName))
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "moreinfo" }, [
                                _c("div", { staticClass: "l1" }, [
                                  _vm._v(
                                    "共" +
                                      _vm._s(item.content.content.goodsNum) +
                                      "件商品"
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "r1" }, [
                                  _vm._v(
                                    "合计：¥" +
                                      _vm._s(item.content.content.payAmount)
                                  )
                                ])
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row-1" }, [
                            _vm._v(
                              "订单号：" + _vm._s(item.content.content.orderNo)
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row-1" }, [
                            _vm._v(
                              "创建时间：" +
                                _vm._s(item.content.content.createdTime)
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row-2" }, [
                            item.content.content.serviceMode == 1
                              ? _c(
                                  "div",
                                  [
                                    _vm.isStoreUser
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { round: "", size: "small" },
                                            on: {
                                              click: function($event) {
                                                return _vm.$router.push({
                                                  path:
                                                    "/storeManagement/storeOrderDetail",
                                                  query: {
                                                    id:
                                                      item.content.content
                                                        .orderId
                                                  }
                                                })
                                              }
                                            }
                                          },
                                          [_vm._v("\n                查看")]
                                        )
                                      : _c(
                                          "el-button",
                                          {
                                            attrs: { round: "", size: "small" },
                                            on: {
                                              click: function($event) {
                                                return _vm.$router.push({
                                                  path:
                                                    "/storeManagementPlat/storeOrderDetailPlat",
                                                  query: {
                                                    id:
                                                      item.content.content
                                                        .orderId
                                                  }
                                                })
                                              }
                                            }
                                          },
                                          [_vm._v("\n                查看")]
                                        )
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  [
                                    _vm.isStoreUser
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { round: "", size: "small" },
                                            on: {
                                              click: function($event) {
                                                return _vm.$router.push({
                                                  path:
                                                    "/storeManagement/deliveyrDetail",
                                                  query: {
                                                    id:
                                                      item.content.content
                                                        .orderId,
                                                    orderSn:
                                                      item.content.content
                                                        .orderSn,
                                                    pageType: 2
                                                  }
                                                })
                                              }
                                            }
                                          },
                                          [_vm._v("\n                查看")]
                                        )
                                      : _c(
                                          "el-button",
                                          {
                                            attrs: { round: "", size: "small" },
                                            on: {
                                              click: function($event) {
                                                return _vm.$router.push({
                                                  path:
                                                    "/storeManagementPlat/storeDeliveryOrderDetailPlat",
                                                  query: {
                                                    id:
                                                      item.content.content
                                                        .orderId,
                                                    orderSn:
                                                      item.content.content
                                                        .orderSn,
                                                    pageType: 1
                                                  }
                                                })
                                              }
                                            }
                                          },
                                          [_vm._v("\n                查看")]
                                        )
                                  ],
                                  1
                                )
                          ])
                        ]),
                        _vm._v(" "),
                        _vm.showMsgTooltip
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: JSON.stringify(item),
                                  placement: "top-start"
                                }
                              },
                              [_c("i", { staticClass: "el-icon-info" })]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : item.messageType == "App:PhoneChargeOrderMsg"
                  ? _c(
                      "div",
                      [
                        _c("div", { staticClass: "ServiceChat-msg-order" }, [
                          _c("h2", [_vm._v("正在咨询的订单")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "pad-info" }, [
                            _c("div", { staticClass: "r1 r3" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.content.content.rechargeChannel) +
                                  " " +
                                  _vm._s(item.content.content.phoneNumber) +
                                  "\n            "
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "r2" }, [
                              _c("div", [
                                _vm._v(
                                  "面额：" +
                                    _vm._s(item.content.content.rechargeMoney) +
                                    "元"
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  "合计：" +
                                    _vm._s(item.content.content.payAmount)
                                )
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row-1" }, [
                            _vm._v(
                              "订单号：" + _vm._s(item.content.content.orderSn)
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row-1" }, [
                            _vm._v(
                              "创建时间：" +
                                _vm._s(item.content.content.createdTime)
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "row-2" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { round: "", size: "small" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push({
                                        path: "/orderPlat/phoneDetail",
                                        query: {
                                          id: item.content.content.orderId
                                        }
                                      })
                                    }
                                  }
                                },
                                [_vm._v("查看\n            ")]
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _vm.showMsgTooltip
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: JSON.stringify(item),
                                  placement: "top-start"
                                }
                              },
                              [_c("i", { staticClass: "el-icon-info" })]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : item.messageType == "App:AirplaneOrderMsg"
                  ? _c(
                      "div",
                      [
                        _c("div", { staticClass: "ServiceChat-msg-order" }, [
                          _c("h2", [_vm._v("正在咨询的订单")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "pad-info" }, [
                            _c("div", { staticClass: "r1" }, [
                              _c("img", {
                                staticStyle: {
                                  position: "relative",
                                  top: "-1px"
                                },
                                attrs: { src: require("./fj.png") }
                              }),
                              _vm._v(
                                _vm._s(item.content.content.startStation) +
                                  "—" +
                                  _vm._s(item.content.content.endStation) +
                                  "\n            "
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "r2" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.content.content.startTime) +
                                  " " +
                                  _vm._s(item.content.content.carrierName) +
                                  _vm._s(item.content.content.flightNo) +
                                  "\n            "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row-1" }, [
                            _vm._v(
                              "订单号：" + _vm._s(item.content.content.orderSn)
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row-1" }, [
                            _vm._v(
                              "创建时间：" +
                                _vm._s(item.content.content.createdTime)
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "row-2" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { round: "", size: "small" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push({
                                        path: "/planeDetail",
                                        query: {
                                          orderSn: item.content.content.orderSn
                                        }
                                      })
                                    }
                                  }
                                },
                                [_vm._v("查看\n            ")]
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _vm.showMsgTooltip
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: JSON.stringify(item),
                                  placement: "top-start"
                                }
                              },
                              [_c("i", { staticClass: "el-icon-info" })]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : item.messageType == "App:TrainOrderMsg"
                  ? _c(
                      "div",
                      [
                        _c("div", { staticClass: "ServiceChat-msg-order" }, [
                          _c("h2", [_vm._v("正在咨询的订单")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "pad-info" }, [
                            _c("div", { staticClass: "r1" }, [
                              _c("img", {
                                staticStyle: {
                                  position: "relative",
                                  top: "-1px"
                                },
                                attrs: { src: require("./hc.png") }
                              }),
                              _vm._v(
                                _vm._s(item.content.content.fromStation) +
                                  "—" +
                                  _vm._s(item.content.content.toStation) +
                                  "\n            "
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "r2" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.content.content.departTime) +
                                  " " +
                                  _vm._s(item.content.content.trainNo) +
                                  "\n            "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row-1" }, [
                            _vm._v(
                              "订单号：" + _vm._s(item.content.content.orderNo)
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row-1" }, [
                            _vm._v(
                              "创建时间：" +
                                _vm._s(item.content.content.createdTime)
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "row-2" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { round: "", size: "small" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push({
                                        path: "/orderPlat/ticketDetail",
                                        query: { id: item.content.content.id }
                                      })
                                    }
                                  }
                                },
                                [_vm._v("查看\n            ")]
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _vm.showMsgTooltip
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: JSON.stringify(item),
                                  placement: "top-start"
                                }
                              },
                              [_c("i", { staticClass: "el-icon-info" })]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        class: { message: true, sysmsg: _vm.isSysMsg(item) },
                        staticStyle: { background: "#fff" }
                      },
                      [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.remTip1(
                                _vm.msg_TRANSFER(item.content.content),
                                item.messageType
                              )
                            )
                          }
                        }),
                        _vm._v(" "),
                        _vm.isShowShow(item) && _vm.isbtnonerecord == 100
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "#00f"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.liaoTianjilu(
                                      item.content.content,
                                      idx
                                    )
                                  }
                                }
                              },
                              [_vm._v("聊天记录")]
                            )
                          : _vm._e()
                      ]
                    )
              ],
              1
            ),
            _vm._v(" "),
            !item.isLeft && !_vm.isSysMsg(item)
              ? _c("el-avatar", {
                  staticClass: "you-messages-usr-icon",
                  staticStyle: {
                    height: "30px",
                    width: "30px",
                    "line-height": "30px",
                    position: "relative",
                    right: "-8px",
                    top: "-11px",
                    background: "#fff"
                  },
                  attrs: {
                    size: 30,
                    src:
                      (item.user &&
                        item.user.img +
                          "?x-oss-process=image/resize,m_fixed,h_50,w_50") ||
                      _vm.circleUrl
                  }
                })
              : _vm._e()
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("mp4Player", { ref: "mp4Player", attrs: { modal: false } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }