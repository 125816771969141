import "core-js/modules/es6.regexp.split";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.number.constructor";
import Vue from 'vue';
import { getStore, setStore } from '@/utils/auth';
import Base64 from '@/utils/base64';
Vue.mixin({
  data: function data() {
    return {
      ytName: 'yuantong',
      rankArr: [{
        lable: '金牌',
        value: 1
      }, {
        lable: '银牌',
        value: 2
      }, {
        lable: '铜牌',
        value: 3
      }, {
        lable: '暂无排名',
        value: 0
      }],
      // 订单处理状态
      orderStatusList: [//订单类型可选项
      {
        dictValue: 1,
        dictLabel: '待付款'
      }, {
        dictValue: 2,
        dictLabel: '已付款'
      }, {
        dictValue: 5,
        dictLabel: '待评价'
      }, {
        dictValue: 6,
        dictLabel: '已评价'
      }, {
        dictValue: 7,
        dictLabel: '已关闭'
      }, {
        dictValue: 11,
        dictLabel: '取消退款中'
      }, {
        dictValue: 12,
        dictLabel: '已取消'
      }, {
        dictValue: 13,
        dictLabel: '自动评价完成'
      }, {
        dictValue: 14,
        dictLabel: '待配送'
      }, {
        dictValue: 15,
        dictLabel: '待取货'
      }, {
        dictValue: 16,
        dictLabel: '待收货'
      }, {
        dictValue: 17,
        dictLabel: '已完成'
      } // { dictValue: , dictLabel: '已关闭' },
      ]
    };
  },
  computed: {},
  methods: {
    returnCheckNum: function returnCheckNum(value) {
      if (!value) return 0;
      value = value.indexOf('.') != -1 ? value.slice(0, value.indexOf('.') + 6) : value;
      value = Number(value);

      if (value < 0) {
        value = Math.abs(value);
      }

      if (value > 1) {
        value = 1;
      }

      return value;
    },
    isPlat: function isPlat() {
      var identity = getStore('identity');

      if (identity == "PLATFORM") {
        return true;
      } else {
        return false;
      }
    },
    isPla: function isPla() {
      var identity = getStore('identity');

      if (identity == "PLATFORM") {
        return true;
      } else {
        return false;
      }
    },
    getRankType: function getRankType(type) {
      if (type == 0) {
        return '无';
      }

      if (type == 1) {
        return '金牌';
      }

      if (type == 2) {
        return '银牌';
      }

      if (type == 3) {
        return '铜牌';
      }
    },
    isProd: function isProd() {
      if (APP_ENV == 'prod' || APP_ENV == 'uat') {
        return true;
      }
    },
    //获取参数getMibileQuery() //是否==1
    getMibileQuery: function getMibileQuery() {
      var f = getStore('f');
      console.log(f);

      if (f == 1) {
        return true;
      } else {
        return false;
      }
    },
    //获取角色 getRoyType('store')
    getRoyType: function getRoyType(str) {
      var userInfo = JSON.parse(getStore('userInfo'));
      console.log(userInfo.roleCodes.includes(str));

      if (userInfo.roleCodes.includes(str)) {
        return true;
      } else {
        return false;
      }
    },
    // 企业信息 getCompanyInfo('isWholesale')
    getCompanyInfo: function getCompanyInfo(key) {
      var Info = JSON.parse(getStore('companyInfo'));

      if (key == 'isWholesale') {
        return Info.isWholesale;
      }

      return Info;
    },
    //获取门店id getShopId('enterpriseId')
    getShopId: function getShopId(key) {
      var userInfo = JSON.parse(getStore('userInfo')); // 返回企业id

      if (key == 'enterpriseId') {
        return userInfo.enterpriseId;
      } else if (key == 'userType') {
        return userInfo.userType;
      } else if (key == 'shopId') {
        return userInfo.shopId; // 返回商铺id getShopId('shopId')
      } else {
        // 返回门店idshopId
        var shopId = userInfo.nearbyShopIds ? userInfo.nearbyShopIds[0] : 1;
        return shopId;
      }
    },
    // 服务承诺、服务设施、售后原因处理
    getType: function getType(serviceMode) {
      serviceMode = serviceMode + '';
      var str = '';
      console.log('1111', serviceMode);

      if (serviceMode == 'null' || !serviceMode) {
        str = '到店服务';
      } else {
        var stArr = serviceMode.split(",");

        for (var i = 0; i < stArr.length; i++) {
          str = str + (stArr[i] == 1 ? '到店服务' : '外卖配送') + ',';
        }
      }

      return str;
    }
  }
});