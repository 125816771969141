var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      _vm._v(" "),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _c("search", {
                  staticClass: "right-menu-item",
                  attrs: { id: "header-search" }
                }),
                _vm._v(" "),
                _c("error-log", {
                  staticClass: "errLog-container right-menu-item hover-effect"
                }),
                _vm._v(" "),
                _c("screenfull", {
                  staticClass: "right-menu-item hover-effect",
                  attrs: { id: "screenfull" }
                }),
                _vm._v(" "),
                _c("message", {
                  staticClass: "right-menu-item",
                  attrs: { id: "header-message" }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "company-name right-menu-item one-row-hide" },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.organizationName) + "\n      "
                    )
                  ]
                )
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" }
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: _vm.userAvatar }
                }),
                _vm._v(" "),
                _c("i", { staticClass: "el-icon-caret-bottom" })
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _vm.userType == 5
                    ? _c(
                        "router-link",
                        { attrs: { to: "/personalCenter/userCenterSite" } },
                        [_c("el-dropdown-item", [_vm._v("个人信息")])],
                        1
                      )
                    : _c(
                        "router-link",
                        { attrs: { to: "/personalCenter/userCenter" } },
                        [_c("el-dropdown-item", [_vm._v("个人信息")])],
                        1
                      ),
                  _vm._v(" "),
                  _vm.getRoyType("platform")
                    ? _c(
                        "router-link",
                        { attrs: { to: "/plat/autoSign" } },
                        [_c("el-dropdown-item", [_vm._v("自动签署")])],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.logout }
                      },
                      [_vm._v("退出系统")]
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "phone-number right-menu-item" }, [
            _vm._v(_vm._s(_vm.username))
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }