import request from '@/utils/request'; // 商户端：库存预警数据列表

export function goodsStockWarningList(data) {
  return request({
    url: '/sysadmin/goods/StockWarning/shop/goodsStockWarningList',
    method: 'post',
    data: data
  });
} // 库存修改

export function putStock(data) {
  return request({
    url: '/sysadmin/goods/StockWarning/putStock',
    method: 'put',
    data: data
  });
} // 平台/商铺：库存预警总数

export function getCount(params) {
  return request({
    url: '/sysadmin/goods/StockWarning/getCount',
    method: 'get',
    params: params
  });
}