import "core-js/modules/es6.regexp.match";
import _typeof from "/root/.jenkins/workspace/yuexin_vue/node_modules/@babel/runtime-corejs2/helpers/esm/typeof";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.to-string";

/* 工具类 */
export default {
  /* 去掉两边空格 */
  trim: function trim(str) {
    str = str.toString() || '';
    return str.replace(/(^\s*)|(\s*$)/g, '');
  },

  /**
   * 类似php的日期转时间戳(date)
   * @param  format    格式
   * @param  timestamp 时间戳
   * @return
   */
  date: function (_date) {
    function date(_x, _x2) {
      return _date.apply(this, arguments);
    }

    date.toString = function () {
      return _date.toString();
    };

    return date;
  }(function (format, timestamp) {
    var timestamps = timestamp.toString().length === 10 ? Number(timestamp) * 1000 : timestamp;
    var jsdate = timestamps ? new Date(timestamps) : new Date();

    var pad = function pad(n, c) {
      if ((n = n + "").length < c) {
        return new Array(++c - n.length).join("0") + n;
      } else {
        return n;
      }
    };

    var txt_weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    var txt_ordin = {
      1: "st",
      2: "nd",
      3: "rd",
      21: "st",
      22: "nd",
      23: "rd",
      31: "st"
    };
    var txt_months = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var f = {
      // Day
      d: function d() {
        return pad(f.j(), 2);
      },
      D: function D() {
        t = f.l();
        return t.substr(0, 3);
      },
      j: function j() {
        return jsdate.getDate();
      },
      l: function l() {
        return txt_weekdays[f.w()];
      },
      N: function N() {
        return f.w() + 1;
      },
      S: function S() {
        return txt_ordin[f.j()] ? txt_ordin[f.j()] : 'th';
      },
      w: function w() {
        return jsdate.getDay();
      },
      z: function z() {
        return (jsdate - new Date(jsdate.getFullYear() + "/1/1")) / 864e5 >> 0;
      },
      // Week
      W: function W() {
        var a = f.z(),
            b = 364 + f.L() - a;
        var nd2,
            nd = (new Date(jsdate.getFullYear() + "/1/1").getDay() || 7) - 1;

        if (b <= 2 && (jsdate.getDay() || 7) - 1 <= 2 - b) {
          return 1;
        } else {
          if (a <= 2 && nd >= 4 && a >= 6 - nd) {
            nd2 = new Date(jsdate.getFullYear() - 1 + "/12/31");
            return date("W", Math.round(nd2.getTime() / 1000));
          } else {
            return 1 + (nd <= 3 ? (a + nd) / 7 : (a - (7 - nd)) / 7) >> 0;
          }
        }
      },
      // Month
      F: function F() {
        return txt_months[f.n()];
      },
      m: function m() {
        return pad(f.n(), 2);
      },
      M: function M() {
        t = f.F();
        return t.substr(0, 3);
      },
      n: function n() {
        return jsdate.getMonth() + 1;
      },
      t: function t() {
        var n;

        if ((n = jsdate.getMonth() + 1) == 2) {
          return 28 + f.L();
        } else {
          if (n & 1 && n < 8 || !(n & 1) && n > 7) {
            return 31;
          } else {
            return 30;
          }
        }
      },
      // Year
      L: function L() {
        var y = f.Y();
        return !(y & 3) && (y % 1e2 || !(y % 4e2)) ? 1 : 0;
      },
      //o not supported yet
      Y: function Y() {
        return jsdate.getFullYear();
      },
      y: function y() {
        return (jsdate.getFullYear() + "").slice(2);
      },
      // Time
      a: function a() {
        return jsdate.getHours() > 11 ? "pm" : "am";
      },
      A: function A() {
        return f.a().toUpperCase();
      },
      B: function B() {
        // peter paul koch:
        var off = (jsdate.getTimezoneOffset() + 60) * 60;
        var theSeconds = jsdate.getHours() * 3600 + jsdate.getMinutes() * 60 + jsdate.getSeconds() + off;
        var beat = Math.floor(theSeconds / 86.4);
        if (beat > 1000) beat -= 1000;
        if (beat < 0) beat += 1000;
        if (String(beat).length == 1) beat = "00" + beat;
        if (String(beat).length == 2) beat = "0" + beat;
        return beat;
      },
      g: function g() {
        return jsdate.getHours() % 12 || 12;
      },
      G: function G() {
        return jsdate.getHours();
      },
      h: function h() {
        return pad(f.g(), 2);
      },
      H: function H() {
        return pad(jsdate.getHours(), 2);
      },
      i: function i() {
        return pad(jsdate.getMinutes(), 2);
      },
      s: function s() {
        return pad(jsdate.getSeconds(), 2);
      },
      //u not supported yet 
      // Timezone
      //e not supported yet
      //I not supported yet
      O: function O() {
        var t = pad(Math.abs(jsdate.getTimezoneOffset() / 60 * 100), 4);
        if (jsdate.getTimezoneOffset() > 0) t = "-" + t;else t = "+" + t;
        return t;
      },
      P: function P() {
        var O = f.O();
        return O.substr(0, 3) + ":" + O.substr(3, 2);
      },
      //T not supported yet
      //Z not supported yet
      // Full Date/Time
      c: function c() {
        return f.Y() + "-" + f.m() + "-" + f.d() + "T" + f.h() + ":" + f.i() + ":" + f.s() + f.P();
      },
      //r not supported yet
      U: function U() {
        return Math.round(jsdate.getTime() / 1000);
      }
    };
    return format.replace(/[\\]?([a-zA-Z])/g, function (t, s) {
      var ret;

      if (t != s) {
        // escaped
        ret = s;
      } else if (f[s]) {
        // a date function exists
        ret = f[s]();
      } else {
        // nothing special
        ret = s;
      }

      return ret;
    });
  }),

  /**
   * 类似php的日期转时间戳(strtotime)
   * @param  datetime
   * @return
   */
  strtotime: function strtotime(datetime) {
    var p = {};
    p.datetime = datetime || '';
    datetime = undefined; // if(p.datetime.toString().indexOf(' ') == -1){
    //     if(p.datetime.toString().indexOf(':') == -1){
    //         p._ = [p.datetime, ''];
    //     }else{
    //         p._ = ['', p.datetime];
    //     }
    // }else{
    //      p._ = p[datetime].split(' ');
    // }

    p._ = p.datetime.toString().indexOf(' ') == -1 ? p.datetime.toString().indexOf(':') == -1 ? [p.datetime, ''] : ['', p.datetime] : p.datetime.split(' ');
    p.ymd = p._[0] || '';
    p.his = p._[1] || '';
    p.ymd = p.ymd.toString().indexOf('-') == -1 ? [p.ymd] : p.ymd.split('-');
    p.his = p.his.toString().indexOf(':') == -1 ? [p.his] : p.his.split(':');
    p.year = (p.ymd[0] || 0) - 0;
    p.month = (p.ymd[1] || 0) - 1;
    p.day = (p.ymd[2] || 0) - 0;
    p.hour = p.his[0] - 0;
    p.minute = p.his[1] - 0;
    p.second = p.his[2] - 0; //兼容无"时:分:秒"模式

    if (p.his[0] == undefined || isNaN(p.hour)) {
      p.hour = 0;
    }

    if (p.his[1] == undefined || isNaN(p.minute)) {
      p.minute = 0;
    }

    if (p.his[2] == undefined || isNaN(p.second)) {
      p.second = 0;
    }

    p.time = new Date(p.year, p.month, p.day, p.hour, p.minute, p.second).getTime();
    p = parseInt(p.time / 1000);
    return p;
  },

  /* 判断是否空 */
  isEmpty: function isEmpty(value) {
    if (value === null || value === undefined || value === '' || value === false) {
      return true;
    } //判断对象


    if (_typeof(value) === 'object' && Object.getOwnPropertyNames(value).length === 0) {
      return true;
    }

    return false;
  },

  /**
   1. 根据结束时间和当前时间的倒计时
   2. 
   3.
   4. @param endDateStr：结束时间
   */
  getAcquisitionCountdown: function getAcquisitionCountdown(endDateStr) {
    // 结束时间
    var endDate = new Date(endDateStr.replace(/-/g, "/"));
    endDate = new Date(endDate).getTime(); // 当前服务器时间

    var nowDate = new Date().getTime(); // 相差的总秒数

    var totalSeconds = parseInt((endDate - nowDate) / 1000); // 天数（转换成字符串类型，以防网不好的情况下，出现now）

    var day = totalSeconds / (60 * 60 * 24);
    var days = null;
    days = day.toString().split(".")[0]; // 取模（余数）

    var modulo = totalSeconds % (60 * 60 * 24); // 小时数

    var hours = Math.floor(modulo / (60 * 60));
    modulo = modulo % (60 * 60); // 分钟

    var minutes = Math.floor(modulo / 60); // 秒

    var seconds = modulo % 60; // 输出到页面

    if (days != "0" && days > 0) {
      return days + "天" + hours + "时" + minutes + "分" + seconds + "秒";
    } else if (days == 0 && hours != 0 && hours > 0) {
      return hours + "时" + minutes + "分" + seconds + "秒";
    } else if (hours == 0 && minutes != 0 && minutes > 0) {
      return minutes + "分" + seconds + "秒";
    } else if (minutes == 0 && seconds != 0 && seconds > 0) {
      return seconds + "秒";
    } else {
      return "";
    }
  },

  /* url操作 */
  url: {
    /* 地址栏get参数对象 */
    query: function query() {
      var __url = location.href.substring(location.href.indexOf('?'));

      var str = __url.substr(1);

      if (str.indexOf('#') >= 0) {
        str = str.split('#')[0];
      }

      var UrlObj = {},
          urlArr = str.split('&');
      if (str === "") return UrlObj;

      for (var i = 0, len = urlArr.length; i < len; i++) {
        var get_Url = urlArr[i].split('='),
            url = '';

        for (var j = 0; j < get_Url.length; j++) {
          if (j !== 0) {
            get_Url[j] = get_Url[j] === "" ? '=' : get_Url[j];
            url += get_Url[j];
          }
        }

        UrlObj[get_Url[0]] = url;
      }

      return UrlObj;
    },

    /* 获取地址栏get参数 */
    params: function params(query) {
      return this.query()[query];
    },

    /* 替换地址栏中的参数 */
    replaceParamVal: function replaceParamVal(arg, arg_val, url) {
      if (url === undefined) {
        url = window.location.href.toString();
      }

      var pattern = arg + '=([^&]*)';
      var replaceText = arg + '=' + arg_val;

      if (url.match(pattern)) {
        var tmp = '/(' + arg + '=)([^&]*)/gi';
        tmp = url.replace(eval(tmp), replaceText);
        return tmp;
      } else {
        if (url.match('[\?]')) {
          return url + '&' + replaceText;
        } else {
          return url + '?' + replaceText;
        }
      }
    },

    /* 批量替换地址栏中的参数 */
    replaceParamVals: function replaceParamVals(params, url) {
      if (tools.isEmpty(params) && tools.isEmpty(url)) {
        url = window.location.href.toString();
      } else {
        for (var key in params) {
          url = this.replaceParamVal(key, params[key], url);
        }
      }

      return url;
    },

    /* 改变地址栏url */
    changeUrl: function changeUrl(url, data) {
      var history = data || {};

      if (navigator.appName == "Microsoft Internet Explorer" && navigator.appVersion.split(";")[1].replace(/[ ]/g, "") == "MSIE9.0") {} else {
        window.history.pushState(history, 0, url);
      }
    },

    /* 改变地址栏url */
    replaceUrl: function replaceUrl(url, data) {
      var history = data || {};

      if (navigator.appName == "Microsoft Internet Explorer" && navigator.appVersion.split(";")[1].replace(/[ ]/g, "") == "MSIE9.0") {} else {
        window.history.replaceState(history, 0, url);
      }
    }
  },
  //获得北京时间
  getBeijingTime: function getBeijingTime() {
    var timezone = 8; //目标时区时间，东八区

    var offset_GMT = new Date().getTimezoneOffset(); // 本地时间和格林威治的时间差，单位为分钟

    var nowDate = new Date().getTime(); // 本地时间距 1970 年 1 月 1 日午夜（GMT 时间）之间的毫秒数

    var mms = nowDate.toString().substring(nowDate.toString().length - 3);
    var currentDate = new Date(nowDate + offset_GMT * 60 * 1000 + timezone * 60 * 60 * 1000);
    var Y = currentDate.getFullYear(); //当前客户端对应的北京时间的年

    var M = currentDate.getMonth(); //北京时间的月

    var D = currentDate.getDate();
    var h = currentDate.getHours();
    var m = currentDate.getMinutes();
    var s = currentDate.getSeconds();
    var beijingTime = Date.UTC(Y, M, D, h, m, s, mms) - 8 * 60 * 60 * 1000;
    return beijingTime;
  },

  /* 删除对象中为空的属性 */
  deleteObj: function deleteObj(obj) {
    for (var v in obj) {
      if (obj.hasOwnProperty(v)) {
        if (obj[v] === "" || obj[v] === undefined || obj[v] === null) {
          delete obj[v];
        }
      }
    }

    return obj;
  }
};