/** When your routing table is too long, you can split it into small modules **/
// 站长端-站长管理
import Layout from '@/layout';
var agencyMangerRouter = {
  path: '/agencyManger',
  component: Layout,
  redirect: '/agencyManger/walletList',
  name: 'AgencyManger',
  meta: {
    title: '站长端-站长管理',
    icon: 'dashboard'
  },
  children: [{
    path: 'walletList',
    component: function component() {
      return import('@/views/agencyManger/walletList.vue');
    },
    name: 'WalletList',
    meta: {
      title: '钱包',
      icon: 'money'
    }
  }, //   {
  //     path:'shopBills',
  //     component:()=>
  //     import('@/views/agencyManger/shopBills.vue'),
  //     name:'ShopBills',
  //     meta: { title:'店铺流水',icon:'list' }
  // },
  // {
  //     path:'storeBills',
  //     component:()=>
  //     import('@/views/agencyManger/storeBills.vue'),
  //     name:'StoreBills',
  //     meta: { title:'门店流水',icon:'list' }
  // },
  {
    path: 'transactionPassword',
    component: function component() {
      return import('@/views/agencyManger/transactionPassword.vue');
    },
    name: 'AgencyTransactionPassword',
    meta: {
      title: '支付密码',
      icon: 'list',
      isCache: true,
      noCache: true
    },
    hidden: true
  }, {
    path: 'editPassword',
    component: function component() {
      return import('@/views/agencyManger/editPassword.vue');
    },
    name: 'AgencyEditPassword',
    meta: {
      title: '修改支付密码',
      icon: 'list',
      isCache: true,
      noCache: true
    },
    hidden: true
  }, {
    path: 'agencyMangerGatheringaccount',
    component: function component() {
      return import('@/views/agencyManger/gatheringaccount.vue');
    },
    name: 'AgencyGatheringaccount',
    meta: {
      title: '收款账号',
      icon: 'list',
      isCache: true,
      noCache: true
    },
    hidden: true
  }]
};
export default agencyMangerRouter;