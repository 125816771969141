import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getQueueList, serviceJoinUp } from '@/api/chat';
export default {
  name: 'queueList',
  props: {
    userId: {
      type: String,
      default: ''
    },
    QlistPhone: {
      type: Boolean,
      default: false
    },
    shopId: {
      type: String,
      default: ''
    },
    shopType: {
      type: Number,
      default: null
    }
  },
  data: function data() {
    return {
      loading: false,
      dialogVisible: false,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      data: [],
      selectionRow: [] //表格选择框 

    };
  },
  methods: {
    open: function open() {
      this.dialogVisible = true;
      this.list();
    },
    close: function close() {
      this.dialogVisible = false;
    },
    selectOk: function selectOk() {
      var _this = this;

      var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var userIds = [];

      if (!row) {
        // console.log("接入所选的")
        for (var i = 0; i < this.selectionRow.length; i++) {
          var item = this.selectionRow[i];
          userIds.push('' + item.id);
        }
      } else {
        // console.log("接入当前选") 
        userIds.push('' + row.id);
      }

      if (this.userId == "") {
        this.$message.error("当前用户id为空");
        return;
        s;
      }

      if (userIds.length == 0) {
        this.$message.error("请选择接入用户");
        return;
      }

      var serviceJoinUpForm = {
        serviceUserId: this.userId,
        ids: userIds
      }; // console.log("提交的数据:",{serviceJoinUpForm})
      ///*

      this.loading = true;
      serviceJoinUp(serviceJoinUpForm).then(function (response) {
        // console.log("排队列表:",response)
        _this.loading = false;

        if (_this.$processingResData(response)) {
          _this.$message.success('操作成功');

          _this.close();
        }
      }).catch(function (e) {
        _this.loading = false;
        console.log(e);
      }); //*/
    },
    list: function list() {
      var _this2 = this;

      this.loading = true;
      var parameter = {
        current: this.currentPage,
        size: this.pageSize,
        shopType: this.shopType,
        shopId: this.shopId
      };
      getQueueList(parameter).then(function (response) {
        // console.log("排队列表:",response)
        _this2.loading = false;

        if (_this2.$processingResData(response)) {
          _this2.data = response.data.records;
          _this2.total = response.data.total;
        }
      }).catch(function (e) {
        _this2.loading = false;
        console.log(e);
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      // console.log("复选框:",val)
      this.selectionRow = val;
    },
    handleSizeChange: function handleSizeChange(value) {
      this.pageSize = value;
      this.currentPage = 1;
      this.list();
    },
    handleCurrentChange: function handleCurrentChange(value) {
      this.currentPage = value;
      this.list();
    }
  }
};