/** When your routing table is too long, you can split it into small modules **/
// 快递管理
import Layout from '@/layout';
var expressRouter = {
  path: '/express',
  component: Layout,
  redirect: '/express/address',
  name: 'express',
  meta: {
    title: '快递管理',
    icon: 'tree'
  },
  children: [// {
  //   path: '/express/address',
  //   component: () => import('@/views/express/address/addressList'),
  //   name: 'AddressList',
  //   meta: {
  //     title: '地址管理',
  //     icon: 'tree'
  //   }
  // },
  {
    path: '/express/address',
    component: function component() {
      return import('@/views/order/order-out');
    },
    name: 'OrderOut',
    meta: {
      title: '地址管理',
      icon: 'tree'
    }
  }, // {
  //   path: '/express/addAaddress',
  //   component: () => import('@/views/express/address/addAaddress'),
  //   name: 'AddAaddress',
  //   hidden: false,
  //   meta: {
  //     title: '（添加发货地址）'
  //   }
  // },
  {
    path: '/express/freight',
    component: function component() {
      return import('@/views/express/freight/freightList');
    },
    name: 'FreightList',
    meta: {
      title: '运费模板',
      icon: 'nested',
      noCache: true
    }
  }, {
    path: '/express/addFreight',
    component: function component() {
      return import('@/views/express/freight/addFreight');
    },
    name: 'AddFreight',
    hidden: true,
    meta: {
      title: '(新增运费模板)',
      noCache: true // isCache:true

    }
  }]
};
export default expressRouter;