import _objectSpread from "/root/.jenkins/workspace/yuexin_vue/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2";
import _createForOfIteratorHelper from "/root/.jenkins/workspace/yuexin_vue/node_modules/@babel/runtime-corejs2/helpers/esm/createForOfIteratorHelper";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getAppOrderList, serviceCensusSum, getUserRefundOrderList } from '@/api/order/list/fetch';
import service from '@/api/serviceManagement/serviceList';
import { getOrderSetting as _getOrderSetting } from '@/api/order/set/set';
import { getStore } from '@/utils/auth';
import { parse } from 'path-to-regexp';

var isEmpty = function isEmpty(str) {
  if (str === '' || str === undefined || str === null) {
    return true;
  }

  return false;
};

export default {
  name: 'Chat',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    openBroadside: {
      type: Boolean,
      default: false
    },
    isCurrentSessionChat: {
      type: Boolean,
      default: true
    },
    allowedSendMessage: {
      type: Boolean,
      default: true
    },
    memberId: String,
    shopId: String,
    contentValue: {
      type: String,
      default: ''
    },
    row: {
      type: Number,
      default: 3
    },
    maxLength: {
      type: Number,
      default: 100
    },
    dragResizeHeight: {
      type: Number,
      default: 0
    },
    dragResizeWidth: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    var _this = this;

    var catgroyNameRules = function catgroyNameRules(rule, value, callback) {
      if (_this.editForm.classify == 1) {
        callback();
        return;
      } else {
        if (value === '') {
          callback(new Error('请输入分类名称'));
        } else if (value.length > 8) {
          callback(new Error('最多只能输入8个字!'));
        } else {
          callback();
        }
      }
    };

    var catgroyIdRules = function catgroyIdRules(rule, value, callback) {
      if (_this.editForm.classify == 2) {
        callback();
        return;
      } else {
        if (value === '') {
          callback(new Error('请选择'));
        } else {
          callback();
        }
      }
    };

    return {
      search: '',
      activeName: 'first',
      orderOptions: [],
      //返回状态
      // 已付款待收货申请售后
      orderStatusOptions: [{
        value: 0,
        label: '全部',
        nums: 0
      }, {
        value: 1,
        label: '待付款',
        nums: 0
      }, {
        value: 3,
        label: '待发货',
        nums: 0
      }, // { value: 4, label: '取消中', nums: 0 },
      {
        value: 5,
        label: '已发货',
        nums: 0
      }, {
        value: 6,
        label: '已完成',
        nums: 0
      }, // { value: 7, label: '已关闭', nums: 0 },
      {
        value: 99,
        label: '售后',
        nums: 0
      }],
      orderActiveName: 0,
      // 查询非售后订单参数
      listQuery: {
        memberId: '',
        orderStatus: 0,
        current: 1,
        size: 10
      },
      // memberId: '1344256702529544193',
      dataForm: {},
      list: [],
      //非售后订单列表
      refundOrder: false,
      //是否为售后订单
      // 查询售后订单参数
      arrQuery: {
        userId: '',
        type: 3,
        current: 1,
        size: 10
      },
      arr: [],
      //售后订单列表
      total: 0,
      input: '',
      form: {},
      companyId: '',
      //企业id
      classifyOptions: [],
      //分类下拉数据
      dialogTitle: '',
      popupVisible: false,
      popupVisible_cyu: false,
      btnLoading: false,
      editForm: {
        classify: 1,
        status: 1
      },
      //新增或编辑表单
      editRules: {
        classify: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }],
        content: [{
          required: true,
          message: '请输入内容',
          trigger: 'blur'
        }],
        catgroyId: [{
          required: true,
          validator: catgroyIdRules,
          trigger: 'change'
        }],
        catgroyName: [{
          required: true,
          validator: catgroyNameRules,
          trigger: 'blur'
        }]
      },
      listData: [],
      //常用语列表
      submitBtnText: '',
      //常用语弹窗按钮上的文字
      orderOver: '',
      //订单设置的未付款订单自动关闭时间，单位：分钟
      orderOverTimer: null,
      //计时器
      runOrderOver: '',
      isEditC: false,
      //是否是编辑分类
      userType: '' //用户角色类型：1为平台，3为商铺

    };
  },
  computed: {
    // 计算当前文本框中内容的字数
    valueLength: function valueLength() {
      if (isEmpty(this.editForm.content)) {
        return 0;
      }

      return this.editForm.content.length;
    } // styleResize1(){
    //   return {
    //     height:600+this.dragResizeHeight+"px"
    //   }
    // },
    // styleResize2(){
    //   return {
    //     height:550+this.dragResizeHeight+"px"
    //   }
    // }

  },
  created: function created() {
    this.getOrderSetting();
    var userInfo = JSON.parse(getStore('userInfo'));
    this.userType = userInfo.userType; // console.log('userInfo', userInfo)

    this.companyId = userInfo.shopId ? userInfo.shopId : 1; // this.memberId = userInfo.id ? userInfo.id : 1
    // this.listQuery.memberId = userInfo.id ? userInfo.id : 1

    this.listQuery.memberId = this.memberId;
    this.arrQuery.userId = this.memberId;
  },
  mounted: function mounted() {
    // 初始跳转特定状态
    // this.orderActiveName = this.$route.query.type || 0
    // if (this.orderActiveName != 0) {
    //   this.handleTabClick(this.orderActiveName)
    //   // this.handleOrderClick(this.orderActiveName)
    //   // console.log('this.orderActiveName', this.orderActiveName)
    // } else {
    if (this.listQuery.memberId) {
      this.queryUser(); // this.getUserRefundOrderData()
    } // }


    this.getClassifyList();
    this.getCatContentList();
  },
  updated: function updated() {
    var that = this;
    clearInterval(that.orderOverTimer);
    that.orderOverTimer = setInterval(function () {
      // that.getRunTime(that.orderOver)
      if (that.orderOver > 0) {
        that.orderOver--;
      }
    }, 1000);
  },
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.orderOverTimer);
  },
  methods: {
    isHuiFu: function isHuiFu(title) {
      if (title == "常用回复") return true;
      return false;
    },
    // 点击订单编号跳转到非售后订单详情
    linkOrderDetail: function linkOrderDetail(orderSn) {
      this.chatShow = false;
      this.$emit('hideService');

      if (this.userType == 1) {
        this.$router.push({
          path: '/orderPlat/order-detail',
          query: {
            orderSn: orderSn
          }
        });
      } else {
        this.$router.push({
          path: '/order-detail',
          query: {
            orderSn: orderSn
          }
        });
      }
    },
    // 点击订单编号跳转到售后订单详情
    linkRefundOrderDetail: function linkRefundOrderDetail(id, type) {
      this.chatShow = false;
      this.$emit('hideService'); // 售后类型(1-退货，2-换货，3-退款)

      if (this.userType == 1) {
        if (type == 1) {
          this.$router.push({
            path: '/orderPlat/order-service/returned-goods-detail',
            query: {
              id: id
            }
          });
        } else if (type == 2) {
          this.$router.push({
            path: '/orderPlat/order-service/changed-goods-detail',
            query: {
              id: id
            }
          });
        } else if (type == 3) {
          this.$router.push({
            path: '/orderPlat/order-service/drawback-detail',
            query: {
              id: id
            }
          });
        }
      } else {
        if (type == 1) {
          this.$router.push({
            path: '/order-service/returned-goods-detail',
            query: {
              id: id
            }
          });
        } else if (type == 2) {
          this.$router.push({
            path: '/order-service/changed-goods-detail',
            query: {
              id: id
            }
          });
        } else if (type == 3) {
          this.$router.push({
            path: '/order-service/drawback-detail',
            query: {
              id: id
            }
          });
        }
      }
    },
    // 常用语内容输入
    contentInputChange: function contentInputChange() {
      this.editForm.content = this.editForm.content.substring(0, this.maxLength);
      this.$emit('change', this.editForm.content);
    },
    // 添加常用语用于发信息
    addMsg: function addMsg(obj) {
      // console.log('obj', obj)
      if (!this.isCurrentSessionChat) {
        if (!this.allowedSendMessage) {
          this.$message.error("当前会话已结束");
          return;
        }
      }

      this.$emit('addMsg', obj.content);
    },
    handleClick: function handleClick(tab) {
      // console.log('taby', tab)
      if (tab == 'first') {
        this.queryUser();
      } else if (tab == 'second') {
        this.getCatContentList();
      }

      this.$forceUpdate();
    },
    //切换订单状态
    handleTabClick: function handleTabClick(tab) {
      this.btnLoading = true; // console.log('this.listQuery', this.listQuery, tab)

      if (this.orderStatusOptions[tab].value == '99') {
        this.refundOrder = true;
        this.getAllStatue();
        this.getUserRefundOrderData();
      } else {
        this.refundOrder = false;
        this.listQuery.orderStatus = this.orderStatusOptions[tab].value;
        this.dataForm.orderStatus = this.orderStatusOptions[tab].value;
        this.listQuery.current = 1;
        this.getOrderSetting();
        this.queryUser();
      }
    },
    // 获取订单设置的未付款订单自动关闭时间
    getOrderSetting: function getOrderSetting() {
      var _this2 = this;

      this.loading = true;

      _getOrderSetting().then(function (res) {
        if (res.code == '000000') {
          _this2.loading = false; // console.log('订单自动关闭时间res', res)

          for (var i = 0; i < res.data.length; i++) {
            if (res.data[i].name == 'commonOrderTimeout') {
              // this.orderOver = res.data[i].value * 60
              _this2.orderOver = res.data[i].value;
            }
          } // console.log('订单自动关闭时间this.orderOver', this.orderOver)

        } else {
          _this2.$message.error(res.mesg);
        }
      });
    },
    // 未支付订单关闭计时器
    getRunTime: function getRunTime(t) {
      var m = Math.floor(t / 60 % 60); //分 var m=Math.floor(t/1000/60%60)

      var s = Math.floor(t % 60); //秒 var s=Math.floor(t/1000%60)

      if (parseInt(m) < 10) {
        m = '0' + m;
      }

      if (parseInt(s) < 10) {
        s = '0' + s;
      }

      this.runOrderOver = m + ':' + s; // console.log('this.runOrderOver', this.runOrderOver)
    },
    //  订单列表
    queryUser: function queryUser() {
      var _this3 = this;

      // console.log('this.memberId订单列表', this.memberId)
      this.listLoading = true;
      var listQuery = {
        memberId: this.memberId,
        orderStatus: this.listQuery.orderStatus,
        current: this.listQuery.current,
        size: this.listQuery.size
      };
      getAppOrderList(listQuery).then(function (res) {
        if (res.code == '000000') {
          // console.log('订单列表res', res)
          _this3.getAllStatue();

          var arr = res.data.records;

          for (var i = 0; i < arr.length; i++) {
            if (arr[i].orderStatus == '4' || arr[i].orderStatus == '7') {
              arr.splice(i, 1);
            }
          }

          _this3.list = arr; // console.log('this.list', this.list)

          var _iterator = _createForOfIteratorHelper(_this3.list),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var _i = _step.value;

              if (parseInt(Number(_i.createdTime.substr(14, 2)) + 1 * _this3.orderOver) == 60) {
                _i.orderOverTime = _i.createdTime.substr(0, 11) + parseInt(Number(_i.createdTime.substr(11, 2)) + 1 * _this3.orderOver) + ':00' + _i.createdTime.substr(16, 3);
              } else {
                _i.orderOverTime = _i.createdTime.substr(0, 14) + parseInt(Number(_i.createdTime.substr(14, 2)) + 1 * _this3.orderOver) + _i.createdTime.substr(16, 3);
              }
            } // console.log('订单列表this.list', this.list)

          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }

          _this3.total = res.data.total;
          _this3.listQuery.current = res.data.current;
          _this3.listQuery.size = res.data.size;
          _this3.listLoading = _this3.btnLoading = false;

          _this3.$forceUpdate();
        } else {
          _this3.$message.error(res.mesg);
        }
      });
    },
    // 获取售后订单列表
    getUserRefundOrderData: function getUserRefundOrderData() {
      var _this4 = this;

      this.listLoading = true;
      getUserRefundOrderList(this.arrQuery).then(function (res) {
        if (res.code == '000000') {
          // console.log('售后订单列表res', res)
          _this4.arr = res.data.records; // console.log('售后订单列表this.arr', this.arr)

          _this4.total = res.data.total;
          _this4.arrQuery.current = res.data.current;
          _this4.arrQuery.size = res.data.size;
          _this4.listLoading = _this4.btnLoading = false;

          _this4.$forceUpdate();
        } else {
          _this4.$message.error(res.mesg);
        }
      });
    },
    // 状态数量统计
    getAllStatue: function getAllStatue() {
      var _this5 = this;

      // console.log('this.memberId状态', this.memberId)
      serviceCensusSum({
        memberId: this.memberId
      }).then(function (res) {
        if (res.code == '000000') {
          // console.log('状态数量统计res', res)
          var list = res.data;

          for (var i = 0; i < list.length; i++) {
            if (list[i].orderStatus == '4' || list[i].orderStatus == '7') {
              list.splice(i, 1);
            }
          }

          var _iterator2 = _createForOfIteratorHelper(_this5.orderStatusOptions),
              _step2;

          try {
            for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
              var _i3 = _step2.value;
              _i3.nums = 0;
            }
          } catch (err) {
            _iterator2.e(err);
          } finally {
            _iterator2.f();
          }

          for (var _i2 = 0; _i2 < _this5.orderStatusOptions.length; _i2++) {
            var ele = _this5.orderStatusOptions[_i2];

            for (var j = 0; j < list.length; j++) {
              var arr = list[j];

              if (arr.orderStatus == ele.value) {
                ele.nums = arr.nums;
              }
            }
          }

          _this5.orderOptions = _this5.orderStatusOptions; // console.log('状态数量统计this.orderOptions', this.orderOptions)

          _this5.$forceUpdate();
        } else {
          _this5.$message.error(res.mesg);
        }
      });
    },
    // 非售后订单的分页
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.size = val;
      this.queryUser();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.current = val;
      this.queryUser();
    },
    // 售后订单的分页
    handleSizeReturn: function handleSizeReturn(val) {
      this.arrQuery.size = val;
      this.getUserRefundOrderData();
    },
    handleCurrentReturn: function handleCurrentReturn(val) {
      this.arrQuery.current = val;
      this.getUserRefundOrderData();
    },
    //搜索常用语
    handleSearch: function handleSearch() {
      this.current = 1;
      this.getCatContentList();
    },
    //获取分类数据
    getClassifyList: function getClassifyList() {
      var _this6 = this;

      service.getCommonClassifyData({
        storeId: this.shopId
      }).then(function (res) {
        if (res.code === '000000') {
          // console.log('获取分类数据res', res)
          _this6.classifyOptions = res.data;
        } else {
          _this6.$message.error(res.mesg);
        }
      });
    },
    //添加常用语
    addCommon: function addCommon(obj) {
      // console.log('obj', obj)
      this.isEditC = false;
      this.submitBtnText = '新增';
      this.getClassifyList();

      if (obj) {
        this.dialogTitle = '添加'; // this.editForm = { ...obj }

        this.editForm = {};
        this.editForm.classify = 1;
        this.editForm.catgroyId = obj.id || obj.catgroyId;
      } else {
        this.dialogTitle = '添加';
        this.editForm = {};
        this.editForm.classify = 1;
      }

      this.editForm.status = 1;
      this.popupVisible = true;
    },
    //置顶
    topCommon: function topCommon(id) {
      var _this7 = this;

      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      var methode = "";

      if (type == 2) {
        methode = "chatFastReplyTopping";
      } else {
        methode = "chatFastCatgoryTopping";
      }

      service[methode](id).then(function (res) {
        if (res.code === '000000') {
          _this7.getCatContentList();

          _this7.$message.success(res.mesg);
        } else {
          _this7.$message.error(res.mesg);
        }
      });
    },
    //编辑常用语
    editCommon: function editCommon(obj) {
      var isEditC = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      this.isEditC = isEditC;
      this.submitBtnText = '确认';
      console.log('editCommon:', obj);
      this.getClassifyList();

      if (obj) {
        this.dialogTitle = '编辑';
        this.editForm = _objectSpread({}, obj);
        this.editForm.classify = 2;
      } // else {
      //   this.dialogTitle = '添加'
      //   this.editForm = {}
      //   this.editForm.classify = 1
      // }


      this.popupVisible = true;
    },
    editCommonS: function editCommonS(obj) {
      this.isEditC = false;
      this.submitBtnText = '确认'; // console.log('obj',obj)

      this.getClassifyList();

      if (obj) {
        this.dialogTitle = '编辑';
        this.editForm = _objectSpread({}, obj);
        this.editForm.classify = 1;
      } // else {
      //   this.dialogTitle = '添加'
      //   this.editForm = {}
      //   this.editForm.classify = 1
      // }


      this.popupVisible_cyu = true;
    },
    //删除
    handleDelete: function handleDelete(row) {
      var _this8 = this;

      this.$confirm('是否确认删除？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        service.delCommonSet({
          id: row.id
        }).then(function (res) {
          if (res.code === '000000') {
            _this8.getCatContentList();

            _this8.$message.success(res.mesg);
          } else {
            _this8.$message.error(res.mesg);
          }
        });
      }).then(function () {}).catch(function () {});
    },
    //删除常用回复的分类
    deleteFastCatgory: function deleteFastCatgory(row) {
      var _this9 = this;

      this.$confirm('是否确认删除这个分类？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        service.deleteChatFastCatgory({
          id: row.id
        }).then(function (res) {
          if (res.code === '000000') {
            _this9.getCatContentList();

            _this9.$message.success(res.mesg);
          } else {
            _this9.$message.error(res.mesg);
          }
        });
      }).then(function () {}).catch(function () {});
    },
    changeCatgory: function changeCatgory() {
      this.$forceUpdate(); // this.editForm.catgroyId = this.editForm.classify === 1 ? this.editForm.catgroyId : this.editForm.catgoryName
    },
    //关闭弹窗
    closeDialog: function closeDialog() {
      this.popupVisible = false;
      this.popupVisible_cyu = false;
    },
    //获取指定商户的分类和回复内容
    getCatContentList: function getCatContentList() {
      var _this10 = this;

      this.loading = true;
      service.getCatContentList({
        searchKey: this.form.content,
        storeId: this.shopId
      }).then(function (res) {
        if (res.code === '000000') {
          // console.log('获取指定商户的分类和回复内容res', res)
          // this.classifyOptions = res.data
          _this10.loading = false;
          var arr = res.data;
          var findIndex = 0;
          var findItem = null;

          for (var i = 0; i < arr.length; i++) {
            if (arr[i].catgroyName.trim() == "常用回复") {
              findItem = arr.splice(i, 1)[0];
              findIndex = i;
              break;
            }
          }

          if (findItem) arr.unshift(findItem);
          _this10.listData = arr;
        } else {
          _this10.$message.error(res.mesg);
        }
      });
    },
    //获取列表数据
    getData: function getData() {
      var _this11 = this;

      this.loading = true;

      var data = _objectSpread(_objectSpread({}, this.form), {}, {
        current: this.current,
        size: this.pageSize,
        storeId: this.shopId
      }); // console.log('data',data)


      service.getCommonSetData(data).then(function (res) {
        if (res.code === '000000') {
          // console.log('获取回复常用语列表数据res',res)
          _this11.loading = false;
          _this11.listData = res.data.records;
          _this11.total = res.data.total;
          _this11.pageSize = res.data.size;
          _this11.current = res.data.current;
        } else {
          _this11.$message.error(res.mesg);
        }
      });
    },
    submitForm: function submitForm() {
      var _this12 = this;

      this.$refs.editForm.validate(function (valid) {
        _this12.editForm.storeId = _this12.shopId;

        if (valid) {
          _this12.btnLoading = true;

          if (_this12.isEditC) {
            //修改分类
            service.saveCatgory(_this12.editForm).then(function (res) {
              if (res.code === '000000') {
                _this12.$message.success(res.mesg);

                _this12.popupVisible = false;
                _this12.popupVisible_cyu = false;

                _this12.getCatContentList();

                _this12.btnLoading = false;
              } else {
                _this12.btnLoading = false;

                _this12.$message.error(res.mesg);
              }
            });
          } else {
            if (_this12.editForm.classify == 2) {
              _this12.editForm.catgroyId = null;
              delete _this12.editForm.catgroyId;
            }

            service.saveCommonSet(_this12.editForm).then(function (res) {
              if (res.code === '000000') {
                _this12.$message.success(res.mesg);

                _this12.popupVisible = false;
                _this12.popupVisible_cyu = false;

                _this12.getCatContentList();

                _this12.btnLoading = false;
              } else {
                _this12.btnLoading = false;

                _this12.$message.error(res.mesg);
              }
            });
          }
        } else {
          _this12.btnLoading = false;
        }
      });
    },
    updateOrderList: function updateOrderList() {
      var _this13 = this;

      this.listQuery.memberId = this.memberId;
      this.arrQuery.userId = this.memberId;

      if (!this.refundOrder) {
        setTimeout(function () {
          _this13.queryUser();
        }, 200);
      } else {
        this.getAllStatue();
        this.getUserRefundOrderData();
      }

      this.$forceUpdate();
    }
  },
  watch: {
    // memberId(val) {
    //   // console.log('val', val)
    //   if (val) {
    //     this.listQuery.memberId = val
    //     this.arrQuery.userId = val
    //     if (!this.refundOrder) {
    //       this.queryUser()
    //     } else {
    //       this.getAllStatue()
    //       this.getUserRefundOrderData()
    //     }
    //   }
    //   this.$forceUpdate()
    // },
    // 监听value的变化
    contentValue: {
      handler: function handler(val, oldVal) {
        this.editForm.content = val;
      },
      immediate: true
    }
  }
};