/** When your routing table is too long, you can split it into small modules **/
// 商品管理 - 平台
import Layout from '@/layout';
var productPlatRouter = {
  path: '/productPlat',
  component: Layout,
  redirect: '/productPlat/recordList',
  alwaysShow: true,
  name: 'ProductPlat',
  meta: {
    title: '商品管理-平台',
    icon: 'monitor'
  },
  children: [{
    path: '/productPlat/recordList',
    component: function component() {
      return import('@/views/productPlat/record/recordList');
    },
    name: 'ProductPlatUpRecord',
    meta: {
      title: '上架审核',
      icon: 'education'
    }
  }, {
    path: '/productPlat/reviewDetails',
    component: function component() {
      return import('@/views/productPlat/record/reviewDetails');
    },
    name: 'ProductPlatReviewDetails',
    hidden: true,
    meta: {
      title: '（审核详情）',
      activeMenu: '/productPlat/recordList',
      noCache: true
    }
  }, {
    path: '/productPlat/category',
    component: function component() {
      return import('@/views/productPlat/category/index');
    },
    name: 'ProductPlatCategory',
    meta: {
      title: '商品分类',
      icon: 'tree-table'
    }
  }, {
    path: '/productPlat/serviceType',
    component: function component() {
      return import('@/views/productPlat/serviceType/index');
    },
    name: 'ProductPlatServiceType',
    meta: {
      title: '服务类型',
      icon: 'peoples'
    }
  }, {
    path: '/productPlat/specification',
    component: function component() {
      return import('@/views/productPlat/specification/index');
    },
    name: 'Specification',
    meta: {
      title: '商品规格',
      icon: 'guide'
    }
  }, {
    path: '/productPlat/list',
    component: function component() {
      return import('@/views/productPlat/list/index');
    },
    name: 'ProductPlatList',
    meta: {
      title: '商品列表',
      icon: 'list'
    }
  }, {
    path: '/productPlat/addProduct',
    component: function component() {
      return import('@/views/productPlat/list/addProduct');
    },
    name: 'AddproductPlat',
    hidden: true,
    meta: {
      title: '新增商品',
      activeMenu: '/productPlat/list'
    }
  }, // {
  //   path: '/productPlat/detailsProduct',
  //   component: () =>
  //     import('@/views/productPlat/list/component/details-productPlat-props'),
  //   name: 'ProductPlatDetailsProduct',
  //   hidden: false,
  //   meta: {
  //     title: '（商品详情）'
  //   }
  // },
  {
    path: '/productPlat/detailsProduct',
    component: function component() {
      return import('@/views/productPlat/list/detailsProduct');
    },
    name: 'DetailsProductPlat',
    hidden: true,
    meta: {
      title: '商品详情',
      noCache: true,
      activeMenu: '/productPlat/list'
    }
  }, {
    path: 'detailsProductOnline',
    component: function component() {
      return import('@/views/productPlat/list/detailsProductOnline');
    },
    name: 'detailsProductOnline',
    // hidden: true,
    meta: {
      title: '配送商品详情' // noCache: true,

    }
  }, {
    path: '/productPlat/Evaluate',
    component: function component() {
      return import('@/views/productPlat/list/Evaluate');
    },
    name: 'Evaluate',
    // hidden: true,
    meta: {
      title: '商品评价',
      icon: 'email'
    }
  }, {
    path: '/productPlat/recycle',
    component: function component() {
      return import('@/views/productPlat/recycle/index');
    },
    name: 'ProductPlatRecycle',
    meta: {
      title: '回收站',
      icon: 'email'
    }
  }, // 待删除 废弃
  // {
  //     path: '/productPlat/appealList',
  //     component: () =>
  //         import ('@/views/productPlat/appraise/appealList'),
  //     name: 'ProductPlatAppealList',
  //     meta: {
  //         title: '评价申诉',
  //         icon: 'list'
  //     }
  // },
  {
    path: '/productPlat/brand',
    component: function component() {
      return import('@/views/productPlat/brand/index');
    },
    name: 'ProductPlatBrand',
    meta: {
      title: '商品品牌',
      icon: 'example'
    }
  }, {
    path: '/productPlat/brandApplyList',
    component: function component() {
      return import('@/views/productPlat/brand/brandApplyList');
    },
    name: 'ProductPlatBrandApplyList',
    hidden: true,
    meta: {
      title: '审核品牌',
      activeMenu: '/productPlat/brand'
    }
  }, {
    path: '/productPlat/takeDown',
    component: function component() {
      return import('@/views/productPlat/takeDown/index');
    },
    name: 'ProductPlatTakeDown',
    meta: {
      title: '申请下架',
      icon: 'edit'
    }
  }, {
    path: '/productPlat/logList',
    component: function component() {
      return import('@/views/productPlat/takeDown/logList');
    },
    name: 'ProductPlatLogList',
    hidden: true,
    meta: {
      title: '（商品日志）'
    }
  } // {
  //   path: '/productPlat/drafts',
  //   component: () => import('@/views/productPlat/drafts/index'),
  //   name: 'Drafts',
  //   meta: {
  //     title: '草稿箱',
  //     icon: 'list'
  //   }
  // },
  // {
  //   path: '/productPlat/recycle',
  //   component: () => import('@/views/productPlat/recycle/index'),
  //   name: 'Recycle',
  //   meta: {
  //     title: '回收站',
  //     icon: 'list'
  //   }
  // },
  // {
  //   path: '/productPlat/log',
  //   component: () => import('@/views/productPlat/log/index'),
  //   name: 'Log',
  //   hidden: true,
  //   meta: {
  //     title: '商品日志',
  //     icon: 'list'
  //   }
  // },
  // {
  //   path: '/productPlat/brandApply',
  //   component: () => import('@/views/productPlat/brand/brandApply'),
  //   name: 'BrandApply',
  //   hidden: true,
  //   meta: {
  //     title: '品牌申请',
  //     icon: 'list'
  //   }
  // },
  // {
  //   path: '/productPlat/approvedList',
  //   component: () => import('@/views/productPlat/brand/approvedList'),
  //   name: 'ApprovedList',
  //   hidden: true,
  //   meta: {
  //     title: '审核记录',
  //     icon: 'list'
  //   }
  // }
  ]
};
export default productPlatRouter;