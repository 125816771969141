var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "wrap",
      staticClass: "x-audio-wrap",
      class: { inline: !_vm.block },
      on: { click: _vm.play }
    },
    [
      _c("div", { staticClass: "x-sector", class: { play: _vm.animate } }, [
        _c("div", { staticClass: "x-dot" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "x-time" }, [
        _vm._v(
          _vm._s(_vm.duration && _vm.showDuration ? _vm.duration : _vm.text)
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }