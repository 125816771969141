var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tool-container" }, [
    _c(
      "div",
      { staticClass: "tool-box" },
      [
        _c(
          "div",
          { staticClass: "title" },
          [
            _c(
              "el-tabs",
              {
                attrs: { type: "card" },
                on: {
                  "tab-click": function($event) {
                    return _vm.handleClick(_vm.activeName)
                  }
                },
                model: {
                  value: _vm.activeName,
                  callback: function($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName"
                }
              },
              [
                _c("el-tab-pane", {
                  attrs: { label: "买家订单", name: "first" }
                }),
                _vm._v(" "),
                _c("el-tab-pane", {
                  attrs: { label: "常用回复", name: "second" }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("orderlist", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeName === "first",
              expression: "activeName === 'first'"
            }
          ],
          ref: "orderlist"
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeName === "second",
                expression: "activeName === 'second'"
              }
            ],
            staticClass: "common-reply"
          },
          [
            _c(
              "div",
              { staticClass: "search-input-box" },
              [
                _c("i", {
                  staticClass: "el-icon-search",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.handleSearch($event)
                    }
                  }
                }),
                _vm._v(" "),
                _c("el-input", {
                  attrs: { clearable: "", placeholder: "请输入关键字" },
                  on: { change: _vm.handleSearch },
                  model: {
                    value: _vm.form.content,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "content", $$v)
                    },
                    expression: "form.content"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm._l(_vm.listData, function(item, idx) {
              return _c(
                "div",
                { key: idx },
                [
                  _c("div", { staticClass: "common-title flex" }, [
                    _c("div", { staticClass: "flex common-title-tag-box" }, [
                      _c("div", { staticClass: "common-title-tag" }),
                      _vm._v(
                        "\n            " +
                          _vm._s(item.catgroyName) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    !_vm.isHuiFu(item.catgroyName)
                      ? _c(
                          "div",
                          {
                            staticClass: "delete",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.topCommon(item.id, 1)
                              }
                            }
                          },
                          [_c("img", { attrs: { src: require("./top.svg") } })]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "delete",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.addCommon(item)
                          }
                        }
                      },
                      [_c("i", { staticClass: "el-icon-circle-plus-outline" })]
                    ),
                    _vm._v(" "),
                    !_vm.isHuiFu(item.catgroyName)
                      ? _c(
                          "div",
                          {
                            staticClass: "delete",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.editCommon(item, true)
                              }
                            }
                          },
                          [_c("i", { staticClass: "el-icon-edit-outline" })]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isHuiFu(item.catgroyName)
                      ? _c(
                          "div",
                          {
                            staticClass: "delete",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.deleteFastCatgory(item)
                              }
                            }
                          },
                          [_c("i", { staticClass: "el-icon-delete" })]
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm._l(item.replys, function(jtem) {
                    return _c(
                      "div",
                      {
                        key: jtem.content,
                        staticClass: "respond",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.addMsg(jtem)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "respond-content" }, [
                          _c("div", { staticClass: "respond-item" }, [
                            _vm._v(_vm._s(jtem.content))
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "edit",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.topCommon(jtem.id, 2)
                              }
                            }
                          },
                          [
                            _c("img", {
                              staticStyle: { cursor: "pointer" },
                              attrs: { src: require("./top.svg") }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "edit",
                            staticStyle: {
                              margin: "0 15px",
                              "font-size": "14px"
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.editCommonS(jtem, true)
                              }
                            }
                          },
                          [_c("i", { staticClass: "el-icon-edit-outline" })]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "delete",
                            staticStyle: { "font-size": "14px" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.handleDelete(jtem)
                              }
                            }
                          },
                          [_c("i", { staticClass: "el-icon-delete" })]
                        )
                      ]
                    )
                  })
                ],
                2
              )
            })
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              title: _vm.dialogTitle,
              visible: _vm.popupVisible,
              width: "500px",
              "before-close": _vm.closeDialog,
              "append-to-body": ""
            },
            on: {
              "update:visible": function($event) {
                _vm.popupVisible = $event
              }
            }
          },
          [
            _c(
              "el-form",
              {
                ref: "editForm",
                staticClass: "edit-form",
                attrs: {
                  model: _vm.editForm,
                  rules: _vm.editRules,
                  "label-width": "120px",
                  size: "small"
                }
              },
              [
                !_vm.isEditC
                  ? _c(
                      "el-form-item",
                      { attrs: { label: " 所属分类：", prop: "classify" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            on: {
                              change: function($event) {
                                return _vm.changeCatgory()
                              }
                            },
                            model: {
                              value: _vm.editForm.classify,
                              callback: function($$v) {
                                _vm.$set(_vm.editForm, "classify", $$v)
                              },
                              expression: "editForm.classify"
                            }
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("已有分类")
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("新增分类")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isEditC
                  ? _c(
                      "el-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editForm.classify === 1,
                            expression: "editForm.classify === 1"
                          }
                        ],
                        attrs: { label: "分类名称：", prop: "catgroyId" }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择",
                              clearable: "",
                              size: "small"
                            },
                            on: {
                              change: function($event) {
                                return _vm.$forceUpdate()
                              }
                            },
                            model: {
                              value: _vm.editForm.catgroyId,
                              callback: function($$v) {
                                _vm.$set(_vm.editForm, "catgroyId", $$v)
                              },
                              expression: "editForm.catgroyId"
                            }
                          },
                          _vm._l(_vm.classifyOptions, function(dict) {
                            return _c("el-option", {
                              key: dict.id,
                              attrs: { label: dict.catgroyName, value: dict.id }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editForm.classify === 2,
                        expression: "editForm.classify === 2"
                      }
                    ],
                    attrs: { label: "分类名称：", prop: "catgroyName" }
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        clearable: "",
                        placeholder: "请输入分类名称",
                        maxlength: "8"
                      },
                      on: {
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      },
                      model: {
                        value: _vm.editForm.catgroyName,
                        callback: function($$v) {
                          _vm.$set(_vm.editForm, "catgroyName", $$v)
                        },
                        expression: "editForm.catgroyName"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                !_vm.isEditC
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "常用语内容：", prop: "content" } },
                      [
                        _c("el-input", {
                          staticClass: "text-area",
                          attrs: {
                            clearable: "",
                            type: "textarea",
                            rows: _vm.row,
                            placeholder: "请输入内容"
                          },
                          on: {
                            input: _vm.contentInputChange,
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          },
                          model: {
                            value: _vm.editForm.content,
                            callback: function($$v) {
                              _vm.$set(_vm.editForm, "content", $$v)
                            },
                            expression: "editForm.content"
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "remain-word" }, [
                          _vm._v(
                            _vm._s(_vm.valueLength) +
                              "/" +
                              _vm._s(_vm.maxLength)
                          )
                        ])
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "btn-list",
                staticStyle: { display: "flex", "justify-content": "center" }
              },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-right": "40px" },
                    attrs: { size: "small" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.closeDialog($event)
                      }
                    }
                  },
                  [_vm._v("取消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      loading: _vm.btnLoading,
                      size: "small"
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.submitForm($event)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.submitBtnText))]
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              title: _vm.dialogTitle,
              visible: _vm.popupVisible_cyu,
              width: "500px",
              "before-close": _vm.closeDialog,
              "append-to-body": ""
            },
            on: {
              "update:visible": function($event) {
                _vm.popupVisible_cyu = $event
              }
            }
          },
          [
            _c(
              "el-form",
              {
                ref: "editForm",
                staticClass: "edit-form",
                attrs: {
                  model: _vm.editForm,
                  rules: _vm.editRules,
                  "label-width": "120px",
                  size: "small"
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "分类名称：", prop: "catgroyId" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          clearable: "",
                          size: "small"
                        },
                        on: {
                          change: function($event) {
                            return _vm.$forceUpdate()
                          }
                        },
                        model: {
                          value: _vm.editForm.catgroyId,
                          callback: function($$v) {
                            _vm.$set(_vm.editForm, "catgroyId", $$v)
                          },
                          expression: "editForm.catgroyId"
                        }
                      },
                      _vm._l(_vm.classifyOptions, function(dict) {
                        return _c("el-option", {
                          key: dict.id,
                          attrs: { label: dict.catgroyName, value: dict.id }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "常用语内容：", prop: "content" } },
                  [
                    _c("el-input", {
                      staticClass: "text-area",
                      attrs: {
                        clearable: "",
                        type: "textarea",
                        rows: _vm.row,
                        placeholder: "请输入内容"
                      },
                      on: {
                        input: _vm.contentInputChange,
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      },
                      model: {
                        value: _vm.editForm.content,
                        callback: function($$v) {
                          _vm.$set(_vm.editForm, "content", $$v)
                        },
                        expression: "editForm.content"
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "remain-word" }, [
                      _vm._v(
                        _vm._s(_vm.valueLength) + "/" + _vm._s(_vm.maxLength)
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "btn-list",
                staticStyle: { display: "flex", "justify-content": "center" }
              },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-right": "40px" },
                    attrs: { size: "small" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.closeDialog($event)
                      }
                    }
                  },
                  [_vm._v("取消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      loading: _vm.btnLoading,
                      size: "small"
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.submitForm($event)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.submitBtnText))]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }