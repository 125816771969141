var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-message" },
    [
      _c("router-link", { attrs: { to: "/personalCenter/messageCenter" } }, [
        _c("div", { staticClass: "el-icon-message-solid" }),
        _vm._v(" "),
        _vm.unreadNum > 0
          ? _c("div", [
              _vm.unreadNum <= 99
                ? _c("span", [_vm._v(_vm._s(_vm.unreadNum))])
                : _c(
                    "span",
                    {
                      staticStyle: {
                        width: "25px",
                        height: "25px",
                        "padding-top": "3px"
                      }
                    },
                    [_vm._v("99+")]
                  )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.messageList.length > 0
        ? _c("div", { staticClass: "message-list-box" }, [
            _c(
              "ul",
              _vm._l(_vm.messageList, function(item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    on: {
                      click: function($event) {
                        return _vm.linkDetail(item)
                      }
                    }
                  },
                  [
                    index < 5
                      ? _c("div", [
                          _c(
                            "div",
                            { staticClass: "message-title one-row-hide" },
                            [_vm._v(_vm._s(item.titleName))]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "message-time" }, [
                            _vm._v(_vm._s(item.createdTime))
                          ])
                        ])
                      : _vm._e()
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "show-more", on: { click: _vm.linkList } },
              [_vm._v("查看更多")]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }