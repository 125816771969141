import "core-js/modules/es6.string.starts-with";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getCategoryList, getMemberListForChangeSession, serviceTransfer } from '@/api/serviceManagement/group';
import { getStore } from '@/utils/auth';
export default {
  name: 'changSession',
  props: {
    targetId: {
      type: String,
      default: ''
    },
    userId: {
      type: String,
      default: ''
    },
    currentSessionId: {
      type: String,
      default: ''
    },
    targetUserInfo: {
      type: Object
    },
    shopId: {
      type: String,
      default: ''
    },
    serviceUserId: {
      type: String,
      default: ''
    },
    shopType: {
      type: Number,
      default: null
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      loading: false,
      form: {},
      groupList: [],
      group: null,
      remarks: "",
      //备注
      memberList: [],
      member: null
    };
  },
  methods: {
    open: function open() {
      var _this = this;

      this.form = {};
      this.groupList = [];
      this.group = null;
      this.remarks = "";
      this.memberList = [];
      this.member = null;
      this.dialogVisible = true;
      this.loading = true;
      getCategoryList({
        shopType: this.shopType,
        shopId: this.shopId
      }).then(function (res) {
        // console.log("groupList:",res)
        _this.loading = false;

        if (_this.$processingResData(res)) {
          _this.groupList = res.data;
        }
      }).catch(function (e) {
        _this.loading = false;
        console.log(e);
      });
    },
    selectGroup: function selectGroup(val) {
      // console.log("选择分组:",val)
      this.member = null;
      this.getGroupMember(val);
    },
    getGroupMember: function getGroupMember(id) {
      var _this2 = this;

      getMemberListForChangeSession(id, this.shopId).then(function (res) {
        if (_this2.$processingResData(res)) {
          var userInfo = JSON.parse(getStore('userInfo'));
          var userId = userInfo.id; // console.log("userId:",userId)

          var arr = [];

          if (res.data) {
            arr = res.data.filter(function (item) {
              return item.status != 2 && !item.userId.startsWith(userId);
            });
          }

          _this2.memberList = arr;

          _this2.$nextTick(function () {
            if (_this2.memberList && _this2.memberList.length > 0) {
              _this2.member = _this2.memberList[0].userId;
            } else {
              _this2.member = null;
            }
          }); //  console.log("getGroupMember:",this.memberList)

        }
      }).catch(function (e) {
        console.log(e);
      });
    },
    close: function close() {
      this.dialogVisible = false;
    },
    selectOk: function selectOk() {
      var _this3 = this;

      var data = {
        "curServiceUserId": this.serviceUserId,
        //当前客服id(自已的userid)
        "sessionId": this.currentSessionId,
        //会话id
        "targetServiceUserId": this.member,
        //目标客服id(转接过去)
        "userId": this.targetUserInfo.user.userId,
        //用户id（当前聊天对象）
        "remarks": this.remarks // 转接备注

      }; // console.log("提交的数据:",this.group,this.member,data)

      if (this.currentSessionId == "") {
        this.$message.error("当前会话id为空");
        return;
      }

      if (!this.targetUserInfo || !this.targetUserInfo.user || !this.targetUserInfo.user.userId) {
        this.$message.error("当前会话用户信息id为空");
        return;
      }

      if (!this.member) {
        this.$message.error("客服人员id不能为空");
        return;
      } // 客服转接
      ///*


      this.loading = true;
      serviceTransfer(data).then(function (res) {
        _this3.loading = false;

        if (_this3.$processingResData(res)) {
          _this3.$message.success('操作成功');

          _this3.$emit('transfer');

          _this3.close();
        }
      }).catch(function (e) {
        _this3.loading = false;
        console.log(e);
      }); //*/
    }
  }
};